import React, { Component } from "react";
import fp from "lodash/fp";
import Message from "../component/Message";
import WithMessage from "../data/WithMessage";
import WithAnalytics from "../data/WithAnalytics";
import WithConversation from "../data/WithConversation";
import { withRouter } from "react-router";

class MessageContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      previousMessage: props.previousMessage,
      conversation: props.conversation,
      message: props.message,
      messageId: props.message.uid,
      messageUser: props.messageUser,
      user: props.user,
      isEmbed: false,
      embeddedMessage: null,
      isHovered: false,
    };
  }

  mouseEnter = () => {
    this.setState({ isHovered: true });
  };

  mouseLeave = () => {
    this.setState({ isHovered: false });
  };

  componentWillMount() {
    const {
      messageListener,
      activityListener,
      message,
      conversation,
      trusts,
      conversationListener,
    } = this.props;

    conversationListener(message.conversation, (conversation) => {
      this.setState({
        conversationStatus: conversation.status,
      });
    });

    messageListener(message.uid, (message) => {
      this.setState({ message });
    });

    activityListener(message.uid, (activity) => {
      this.setState({ activity: Object.values(activity || {}) });
    });

    const trustsIds = trusts.map((t) => t.uid);

    const trustedLikes = Object.values(message.likes || {})
      .filter((like) => trustsIds.includes(like.user))
      .map((like) => {
        like.user = trusts.filter((t) => t.uid === like.user)[0];
        return like;
      })
      .slice(0, 3)
      .sort((a, b) => a.timestamp > b.timestamp);

    this.setState({ trustedLikes });

    const links =
      process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test"
        ? process.env.REACT_APP_BASEURLS_DEV.trim().split(/\s*,\s*/)
        : process.env.REACT_APP_BASEURLS_PROD.trim().split(/\s*,\s*/);

    // const containsLink = links.reduce((acc, l) => message.body.includes(l), false);

    const containsLink = (body) => links.filter((l) => body.includes(l));

    if (containsLink(message.body).length >= 1) {
      this.renderMessageEmbed(message, conversation);
    }
    return null;
  }
  decodeQueryString = (queryString) => {
    // console.log('QZ ', queryString);
    if (queryString.includes("+message=")) {
      return queryString.split("=")[1].split("&")[0];
    }
    if (queryString.includes("message=")) {
      return queryString.split("=")[1].split("&")[0];
    }
    return queryString;
  };

  async renderMessageEmbed() {
    const { message } = this.props;

    const { conversationListener, messageListener } = this.props;

    const queryString = message.body.trim().split("/").pop();

    const messageId = this.decodeQueryString(queryString);

    messageListener(messageId, (m) => {
      if (m != null) {
        conversationListener(m.conversation, (c) => {
          this.setState({
            message: m,
            isEmbed: c.status !== "private",
            embeddedMessage: c.status === "private" ? null : m,
          });
        });
      }
    });
  }

  clickMessage = (message) => {
    this.props.history.replace(
      `/${
        this.state.conversationStatus ? "public" : this.state.conversationStatus
      }/conversations/${message.conversation}`
    );
  };

  handleHighlightClick = (e, messageId) => {
    const { message } = this.state;
    const { likeMessage, handleHighlight, user } = this.props;

    const highlighted = message.likes
      ? message.likes.hasOwnProperty(user.uid)
      : false;

    e.stopPropagation();

    likeMessage(message);
    handleHighlight(messageId, user, highlighted);
  };

  renderEmbedded = (message, user, embeddedMessage) => (
    <Message
      appUser={this.props.user}
      isEmbed
      message={embeddedMessage}
      handleClick={this.props.handleClick}
    />
  );

  renderMessage = (message, user, embeddedMessage) => (
    <Message
      appUser={user}
      disableActions={this.props.disableActions}
      isHovered={this.state.isHovered}
      onMouseEnter={this.mouseEnter}
      onMouseLeave={this.mouseLeave}
      handleHighlightClick={(e, messageId) =>
        this.handleHighlightClick(e, messageId)
      }
      isEmbed={false}
      handleClick={this.props.handleClick}
      isHighlighted={this.props.isHighlighted}
      message={message}
      embeddedMessage={embeddedMessage}
      handleAvatarClick={this.props.handleAvatarClick}
    />
  );

  render() {
    const { embeddedMessage, trustedLikes, previousMessage } = this.state;
    const { isHighlighted, handleAvatarClick, disableActions } = this.props;

    const embed = embeddedMessage ? (
      <Message
        appUser={this.props.user}
        disableActions={disableActions}
        previousMessage={previousMessage}
        isHovered
        isEmbed
        conversationStatus={this.state.conversationStatus}
        handleHighlightClick={(e, messageId) =>
          this.handleHighlightClick(e, messageId)
        }
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
        message={embeddedMessage}
        isHighlighted={isHighlighted}
        showActivity={false}
        handleClick={(message) => this.clickMessage(message)}
        handleAvatarClick={handleAvatarClick}
        showMetadata
        trustedLikes={trustedLikes}
      />
    ) : null;

    return (
      <div>
        <Message
          appUser={this.props.user}
          previousMessage={this.state.previousMessage}
          featured={this.state.conversationStatus}
          disableActions={this.props.disableActions}
          isHovered={this.state.isHovered}
          onMouseEnter={this.mouseEnter}
          onMouseLeave={this.mouseLeave}
          handleHighlightClick={(e, messageId) =>
            this.handleHighlightClick(e, messageId)
          }
          handleClick={this.props.handleClick}
          isEmbed={false}
          isHighlighted={this.props.isHighlighted}
          message={this.props.message}
          embeddedMessage={this.state.embeddedMessage}
          handleAvatarClick={this.props.handleAvatarClick}
          showMetadata={this.props.showMetadata}
          trustedLikes={trustedLikes}
        />
        {embed}
      </div>
    );
  }
}

export default fp.compose(
  WithMessage,
  WithConversation,
  WithAnalytics,
  withRouter
)(MessageContainer);
