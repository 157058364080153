import React from "react";
import { Avatar as AntAvatar } from "antd";

const Avatar = ({
  size = "medium",
  imageURL,
  handleClick,
  showIcon = false,
  className = "",
}) =>
  showIcon ? (
    <div>
      <AntAvatar
        className={`${className}`}
        src={imageURL}
        shape="circle"
        style={styles[size]}
        onClick={handleClick}
      />
      <div className="eagle">
        <span role="img" aria-label="emoji" style={styles.founder}>
          🦅
        </span>
      </div>
    </div>
  ) : (
    <AntAvatar
      src={imageURL}
      className={`${className}`}
      shape="circle"
      style={styles[size]}
      onClick={handleClick}
    />
  );

const styles = {
  founder: {
    fontSize: "22px",
    background: "rgba(238,68,35)",
    paddingLeft: "5px",
    paddingRight: "4px",
    borderRadius: "50%",
    position: "absolute",
    top: "85px",
    right: "43%",
  },

  extraSmall: {
    cursor: "pointer",
    width: "35px",
    height: "35px",
    borderRadius: "35px",
  },
  small: {
    cursor: "pointer",
    width: "46px",
    height: "46px",
    borderRadius: "50px",
  },
  medium: {
    cursor: "pointer",
    width: "65px",
    height: "65px",
    borderRadius: "65px",
  },
  large: {
    cursor: "pointer",
    width: "200px",
    height: "200px",
    borderRadius: "200px",
  },
};

export default Avatar;
