import moment from "moment";

const MAX_SUB_TITLE_LENGTH = 42;
const MAX_LINK_LENGTH = 44;
const MAX_TITLE_LENGTH = 22;
const MAX_BODY_LENGTH = 80;

const Utils = {
  getFirstMessage(t) {
    const pipe = (...fns) => x => fns.reduce((a, b) => b(a), x);
    const splitOnCarriage = m => m.split(/\n/);
    const filterMessages = m => m.filter(m => m.length >= 1)[0] || [];

    return pipe(
      splitOnCarriage,
      filterMessages
    )(t);
  },
  getMobileOP() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "IOS";
    }

    return false;
  },
  timeStamp(date) {
    return moment(date).fromNow();
  },
  titleTrimmer(title = "") {
    const length = MAX_TITLE_LENGTH;
    if (title.length > length) return `${title.substring(0, length)}...`;
    return title;
  },
  linkTrimmer(title = "") {
    const length = MAX_LINK_LENGTH;
    if (title.length > length) return `${title.substring(0, length)}...`;
    return title;
  },
  subNavTitleTrimmer(title = "") {
    const length = MAX_SUB_TITLE_LENGTH;
    if (title.length > length) return `${title.substring(0, length)}...`;
    return title;
  },
  bodyTrimmer(body = "", length = MAX_BODY_LENGTH) {
    if (body.length > length) return `${body.substring(0, length)}...`;
    return body;
  },
  capitalCase(text = "") {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
};

export default Utils;
