import { connect } from "react-redux";
import { compose } from "redux";
import withLoader from "./with-loader";
import { getIsOpen } from "../../features/modal/modal-reducer";
import { getIsAuthenticating } from "../../features/user-auth/user-auth-reducer";

const mapStateToProps = (state) => ({
  isOpen: getIsOpen(state),
  isAuthenticating: getIsAuthenticating(state),
});

const connectedComponent = connect(mapStateToProps, null);

const composedLoader = (Component) =>
  compose(connectedComponent, withLoader)(Component);

export default composedLoader;
