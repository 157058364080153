import { useReducer, useEffect } from "react";
import {
  reducer,
  initialState,
  toggleSetEditing,
  toggleSendControl,
  setMessageBody,
  setupMessageEditing,
} from "../reducers/message-editing-reducer";
import PropTypes from "prop-types";
import { getTokenHeader } from "../utils/authMethods";
import { FbFunctionsEndpoint } from "../utils/endpoints";

const FIREBASE_FUNCTIONS_ENDPOINT = FbFunctionsEndpoint;

const UseMessageEditing = (message, user) => {
  // console.log("THE USER ", user);
  const [editingState, dispatch] = useReducer(reducer, initialState);
  const {
    messageId,
    ownerId,
    messageBody,
    hasEdited,
    sendDisabled,
  } = editingState;

  useEffect(() => {
    dispatch(setupMessageEditing({ ...message, ownerId: user.uid }));
  }, [user]);

  const setMessageEditing = () => dispatch(toggleSetEditing());
  const setEdits = (content) =>
    dispatch(setMessageBody({ messageBody: content }));
  const toggleSend = () => Promise.resolve(dispatch(toggleSendControl()));
  const updateMessageInDb = () =>
    toggleSend().then(() => {
      getTokenHeader().then((headers) =>
        fetch(`${FIREBASE_FUNCTIONS_ENDPOINT}/api/updateMessageBody`, {
          method: "POST",
          headers,
          body: JSON.stringify({ messageId, ownerId, messageBody }),
        })
          .catch(() => "")
          .then(() => setMessageEditing())
      );
    });

  return {
    setMessageEditing,
    updateMessageInDb,
    setEdits,
    hasEdited,
    sendDisabled,
    editingState,
  };
};

UseMessageEditing.propTypes = {
  setMessageEditing: PropTypes.func.isRequired,
  setEdits: PropTypes.func.isRequired,
  updateMessageInDb: PropTypes.func.isRequired,
};

export default UseMessageEditing;
