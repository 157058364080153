export function Box(x) {
    return {
        map: f => Box(f(x)),
        fold: f => f(x),
        inspect: () => `Box(${x})`,
    };
}

export function Right(x) {
    return {
        map: f => Right(f(x)),
        fold: (f, g) => g(x),
        inspect: () => `Right(${x})`,
    };
}

export function Left(x) {
    return {
        map: f => Left(x),
        fold: (f, g) => f(x),
        inspect: () => `Left(${x})`,
    };
}

export function fromNullable(x) {
    x != null ? Right(x) : Left(null);
}
