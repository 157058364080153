import React from "react";
import AuthModal from "./AuthModal";

const WithAuthModal = (Component) => (props) => (
  <React.Fragment>
    <AuthModal {...props} />
    <Component {...props} />
  </React.Fragment>
);

export default WithAuthModal;
