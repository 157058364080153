import React from "react";
import * as firebase from "firebase";

const WithActivity = Component =>
  class extends React.Component {
    constructor(props) {
      super(props);
      this.activityRef = (id = "") =>
        firebase.database().ref(`users_activity/${id}`);
    }

    activityListener = (id, callback, limit = 100) => {
      this.activityRef(id)
        .limitToLast(limit)
        .on("value", cs => callback(Object.values(cs.val() || {})));
    };

    render() {
      return (
        <Component {...this.props} activityListener={this.activityListener} />
      );
    }
  };

export default WithActivity;
