import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import { Input, Form } from "antd";
import PropTypes from "prop-types";

const AuthModal = ({
  isOpen,
  toggleModal,
  linkSent,
  signInWithEmailLink,
} = {}) => {
  const [emailInput, setEmailInput] = useState("");
  const [error, setError] = useState("");

  const validate = (values) => {
    if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        values
      )
    ) {
      setError("please enter a valid email address");
    } else {
      setError("");
    }
  };

  useEffect(() => {
    if (emailInput.trim().length > 3) {
      validate(emailInput);
    }
  }, [emailInput]);

  return (
    <React.Fragment>
      <Modal
        title={
          linkSent
            ? "Thank you ⚡️"
            : `Welcome to ${process.env.REACT_APP_NETWORK_NAME} 💫`
        }
        visible={isOpen}
        onCancel={() => toggleModal()}
        footer={
          linkSent
            ? null
            : [
                <Button
                  key="Continue"
                  type="primary"
                  disabled={error || linkSent || emailInput.trim().length < 3}
                  onClick={(e) => {
                    if (!error) {
                      signInWithEmailLink(emailInput);
                    } else {
                      e.preventDefault();
                    }
                  }}
                >
                  Continue
                </Button>,
              ]
        }
      >
        {linkSent ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <p style={{ alignSelf: "center" }}>
              <span>Check your email to finish signing in</span>
            </p>
            <div style={{ alignSelf: "center" }}>
              <span>
                <img
                  src="https://img.icons8.com/office/45/000000/sent.png"
                  alt="sent"
                />
              </span>
            </div>
          </div>
        ) : (
          <Form name="basic" initialValues={{ remember: true }}>
            <div>
              <Form.Item
                label="Enter your email address to sign in"
                name="email"
                validateStatus={error ? "error" : ""}
                help={error || ""}
              >
                <Input
                  size="large"
                  placeholder="email"
                  onChange={(e) => setEmailInput(e.target.value)}
                  value={emailInput}
                />
              </Form.Item>
            </div>
          </Form>
        )}
      </Modal>
    </React.Fragment>
  );
};

AuthModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  linkSent: PropTypes.bool.isRequired,
  signInWithEmailLink: PropTypes.func.isRequired,
};

export default AuthModal;
