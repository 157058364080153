import React from "react";
import { Upload, Button, Icon } from "antd";

const upload = ({ startUploadOfProfileImage }) => {
  const props = {
    name: "file",
    showUploadList: false,
    accept: "image/png, image/jpeg",
    onChange(info) {
      if (info.file.status !== "uploading" && info.file.status !== "removed") {
        startUploadOfProfileImage(info.file);
      }
    },
  };

  return (
    <Upload {...props}>
      <Button>
        <Icon type="upload" /> Upload an image
      </Button>
    </Upload>
  );
};

export default upload;
