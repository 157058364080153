import React from 'react';
import Avatar from './Avatar';
import { withRouter } from 'react-router';

const MessageActivitySummary = ({
    trustedLikes = [],
    branchCount,
    display,
    handleAvatarClick,
    history,
}) => {
    if (display === false) return null;

    handleAvatarClick = (e, user) => {
        e.stopPropagation();
        history.push(`/profile/${user.uid}`);
    };

    const trustedLikeUsers = trustedLikes.map((like, index) => (
        <div
            className="trusted-like-avatar"
            style={{ zIndex: index + 1, left: index * -5, position: 'absolute' }}>
            <Avatar
                key={like.user.uid}
                size="mini"
                imageURL={like.user.photoURL}
                handleClick={e => handleAvatarClick(e, like.user)}
            />
        </div>
    ));

    const likes = () =>
        trustedLikes.length ? <div style={styles.iconContainer}>{trustedLikeUsers}</div> : null;

    return likes();
};

export default withRouter(MessageActivitySummary);

const styles = {
    iconContainer: {
        position: 'relative',
    },
    icon: {
        fontSize: 14,
    },
    count: {
        backgroundColor: 'transparent',
        color: '#aaa',
        fontSize: 13,
        lineHeight: 14,
        marginLeft: 4,
        marginRight: 6,
        marginTop: 6,
    },
};
