import React from "react";
import PropTypes from "prop-types";
import { Card, Col, Row, Button } from "antd";
import WithUser from "../data/WithUser";
import Loading from "../component/Loading";

const SignInForm = ({ match, toggleModal }, handleAuth) => {
  const { path, params } = match;
  const bodyStyles = path.includes("private") ? styles.privateBodyStyles : {};

  const message = `Please log in to view ${
    params.conversationId ? "this  conversation" : "these  conversations"
  }`;

  const loading = false;

  return loading === false ? (
    <Card noHovering bodyStyle={bodyStyles}>
      <Row type="flex" justify="center" align="middle" style={styles.container}>
        <Col
          sm={{ span: 24 }}
          md={{ span: 16 }}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2 style={styles.promptText}>{message}</h2>
          <Button
            style={
              path.includes("private")
                ? styles.privateButton
                : styles.publicButton
            }
            onClick={() => {
              toggleModal();
            }}
            type="primary"
          >
            Sign in via email
          </Button>
        </Col>
      </Row>
    </Card>
  ) : (
    <Loading />
  );
};

const styles = {
  container: {
    height: "300px",
  },
  privateBodyStyles: {
    backgroundColor: "#E2E2E2",
  },
  promptText: {
    textAlign: "center",
    color: "#343434",
  },
  buttonBase: {
    padding: "0 40px",
    color: "#C0223B",
    borderStyle: "solid",
    borderWidth: 1.5,
    borderColor: "#C0223B",
    borderRadius: 20,
  },
  privateButton: {
    padding: "0 40px",
    borderStyle: "solid",
    borderWidth: 1.5,
    borderRadius: 20,
    alignSelf: "center",
    backgroundColor: "#E2E2E2",
    color: "#343434",
    borderColor: "#343434",
  },
  publicButton: {
    padding: "0 40px",
    color: "#C0223B",
    borderStyle: "solid",
    borderWidth: 1.5,
    borderColor: "rgba(238,68,35);",
    borderRadius: 20,
    alignSelf: "center",
    backgroundColor: "inherit",
  },
};

SignInForm.propTypes = {
  match: PropTypes.object.isRequired,
  handleAuth: PropTypes.func.isRequired,
};

export default WithUser(SignInForm);
