import React, { useEffect, useState } from "react";
import { Modal, Button, Input, message, Form } from "antd";
import Uploader from "../../component/uploader";
import Avatar from "../../component/Avatar";
import PropTypes from "prop-types";

const OnboardingModal = ({
  needsOnBoarding,
  toggleOnBoardModal,
  uploadingStatus,
  file,
  error,
  isModalOpen,
  setDisplayName,
  startUploadOfProfileImage,
  uploadProfileImage,
  user,
  onBoardingComplete,
} = {}) => {
  useEffect(() => {
    if (needsOnBoarding) {
      toggleOnBoardModal();
    }
  }, [needsOnBoarding, toggleOnBoardModal]);

  //need file here
  useEffect(() => {
    if (uploadingStatus === "done" && file.name !== undefined && !error) {
      message.success(`${file.name} file uploaded successfully`);
    }
  }, [uploadingStatus, file]);

  useEffect(() => {
    if (error.status === "uploadError") {
      message.error(`${error.message} `);
    }
  }, [error]);

  const [inputError, setInputError] = useState("");
  const [userNameInput, setUserNameInput] = useState("");

  const validate = (userNameInput) => {
    const trimmed = userNameInput.trim();
    if (trimmed.length >= 1 && trimmed.length < 3) {
      setInputError("Your name must be 3 characters or more");
    } else if (trimmed.length >= 32) {
      setInputError("Your name cannot be longer than 32 characters.");
    } else {
      setInputError("");
    }
  };

  useEffect(() => {
    validate(userNameInput);
  }, [userNameInput]);

  return (
    <Modal
      closable={false}
      title={
        onBoardingComplete
          ? "All done! ✨"
          : "Almost done! Create your profile 🌟"
      }
      visible={isModalOpen}
      footer={
        onBoardingComplete
          ? ""
          : [
              <Button
                key="submit"
                type="primary"
                disabled={inputError || userNameInput < 1}
                onClick={(e) => {
                  if (!inputError) {
                    setDisplayName(userNameInput);
                  } else {
                    e.preventDefault();
                  }
                }}
              >
                Submit
              </Button>,
            ]
      }
    >
      {onBoardingComplete === true ? (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>You are all set, {userNameInput}</p>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Form name="basic" initialValues={{ remember: true }}>
            <div>
              <p style={{ textAlign: "center" }}>
                <Avatar imageURL={user.photoURL}></Avatar>
              </p>
              <p style={{ textAlign: "center" }}>
                <Uploader
                  uploadProfileImage={uploadProfileImage}
                  startUploadOfProfileImage={startUploadOfProfileImage}
                  uploadingStatus={uploadingStatus}
                />
              </p>

              <Form.Item
                label="Enter your full name"
                name="displayName"
                validateStatus={inputError ? "error" : ""}
                help={inputError || ""}
                rules={[{ required: true, message: "adsfasdfasdf" }]}
              >
                <Input
                  size="large"
                  placeholder="Name"
                  onChange={(e) => setUserNameInput(e.target.value)}
                  value={userNameInput}
                />
              </Form.Item>
            </div>
          </Form>
        </React.Fragment>
      )}
    </Modal>
  );
};

OnboardingModal.propTypes = {
  needsOnBoarding: PropTypes.bool.isRequired,
  toggleOnBoardModal: PropTypes.func.isRequired,
  uploadingStatus: PropTypes.bool.isRequired,
  file: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  setDisplayName: PropTypes.func.isRequired,
  startUploadOfProfileImage: PropTypes.func.isRequired,
  uploadProfileImage: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  onBoardingComplete: PropTypes.bool.isRequired,
};

export default OnboardingModal;
