import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "lodash/fp";
import ConversationDetail from "../component/ConversationDetail";
import WithUser from "../data/WithUser";
import WithConversation from "../data/WithConversation";
import Loading from "../component/Loading";

class ConversationDetailContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      conversationId: this.props.match.params.conversationId,
      conversation: null
    };
  }

  componentWillMount() {
    const { conversationListener, user } = this.props;
    const { conversationId } = this.state;

    conversationListener(conversationId, conversation => {
      this.setState({ conversation });
    });

    this.props
      .getTrusts(user.uid, "trusted by")
      .then(trusts => this.setState({ trusts }));
  }

  handleFollowConversation = () => {
    const { handleFollowConversation, userIsFollower, user } = this.props;
    const { conversation } = this.state;

    const action = userIsFollower(user.uid)(conversation)
      ? "unfollow"
      : "follow";

    handleFollowConversation(user, conversation, action);
  };

  handleFeatureConversation = () => {
    const { handleFeatureConversation } = this.props;
    const { conversation } = this.state;

    handleFeatureConversation(conversation);
  };

  handleReport = () => {};

  handleClick = type => () => {
    this.props.history.push(
      `detail/${type === "contributors" ? `${type}/edit` : type}`
    );
  };

  handleFeaturedImageSelect = () => this.props.history.push("featuredimage");

  render() {
    const { conversation, trusts } = this.state;
    const { user } = this.props;

    return conversation && user && trusts ? (
      <ConversationDetail
        user={user}
        trusts={trusts}
        conversation={conversation}
        handleFollowConversation={this.handleFollowConversation}
        handleFeatureConversation={this.handleFeatureConversation}
        handleReport={this.handleReport}
        handleClick={this.handleClick}
        handleFeaturedImageSelect={this.handleFeaturedImageSelect}
      />
    ) : (
      <Loading />
    );
  }
}

ConversationDetailContainer.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  conversationListener: PropTypes.func.isRequired,
  handleFollowConversation: PropTypes.func.isRequired,
  handleFeatureConversation: PropTypes.func.isRequired,
  getTrusts: PropTypes.func.isRequired,
  userIsFollower: PropTypes.func.isRequired
};

export default compose(
  WithUser,
  WithConversation
)(ConversationDetailContainer);
