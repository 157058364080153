import { uniq } from "lodash";

export const initialState = {
  convoKeys: [],
  conversations: [],
  conversationsObject: {},
  shouldFetch: true,
  lastKnownKey: null,
  amount: 30,
};

export const setLastKnownKey = (convos) => ({
  type: "setLastKnownKey",
  payload: {
    convos,
  },
});

export const setConversations = (convoKeysLen) => ({
  type: "setConversations",
  payload: {
    convoKeysLen,
  },
});

export const setConvoKeys = ({ convos = {}, screen = {} } = {}) => ({
  type: "setConvoKeys",
  payload: {
    convos,
    screen,
  },
});

export const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case setLastKnownKey().type:
      return {
        ...state,
        lastKnownKey: state.conversations[state.conversations.length - 1].id,
        amount: (state.amount += 5),
      };
    case setConversations().type:
      return {
        ...state,
        //map keys to objects
        conversations: state.convoKeys.map((k) => {
          return {
            id: k,
            ...state.conversationsObject[k],
          };
        }),
        shouldFetch: false,
      };

    case setConvoKeys().type:
      return {
        ...state,
        convoKeys: uniq([
          ...state.convoKeys,
          ...Object.keys(payload.convos || [])
            .sort()
            .reverse()
            .filter((c) => c !== "undefined"),
        ]),
        //get results as object
        conversationsObject: {
          ...state.conversationsObject,
          ...payload.convos,
        },
        shouldFetch: true,
      };
    default:
      return state;
  }
};
