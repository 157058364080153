import React from "react";
import PropTypes from "prop-types";
import { Layout, Row, Col } from "antd";
import NavContainer from "../container/NavContainer";
import WelcomeMatContainer from "../container/WelcomeMatContainer";

const { Content, Footer } = Layout;

const DefaultLayout = (props) => {
  const { Component, location, handleSignOut, handleSignIn, user } = props;

  const renderWelcomeMat = () =>
    location.pathname === "/featured" ? (
      <WelcomeMatContainer {...props} />
    ) : null;

  return (
    <Layout
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <Content>
        <NavContainer
          {...props}
          handleSignOut={handleSignOut}
          handleSignIn={handleSignIn}
        />
        {renderWelcomeMat()}
        <Row type="flex" justify="center">
          <Col sm={24} md={18} lg={16} xs={24}>
            <Component
              {...props}
              user={user}
              handleSignOut={handleSignOut}
              handleSignIn={handleSignIn}
            />
          </Col>
        </Row>
      </Content>
      <Footer style={{ textAlign: "center" }} />
    </Layout>
  );
};

DefaultLayout.propTypes = {
  Component: PropTypes.node.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default DefaultLayout;
