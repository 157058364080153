import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import withOnBoarding from "./with-onboarding";

import {
  getDisplayName,
  getIsAnonymous,
  updateUserProfile,
} from "../../features/user-profile/user-profile-reducer";

import {
  getIsOnBoardModalOpen,
  uploadProfileImage,
  startUploadOfProfileImage,
  toggleOnBoardModal,
  getUploadingStatus,
  getProfileImageFile,
  getOnboardingError,
  setDisplayName,
  getOnboardingComplete,
} from "../../features/onboarding/onboarding-reducer";

const mapStateToProps = (state) => ({
  needsOnBoarding:
    getIsAnonymous(state) === false && getDisplayName(state) === "",
  isModalOpen: getIsOnBoardModalOpen(state),
  uploadingStatus: getUploadingStatus(state),
  file: getProfileImageFile(state),
  error: getOnboardingError(state),
  displayName: getDisplayName(state),
  onBoardingComplete: getOnboardingComplete(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleOnBoardModal,
      uploadProfileImage,
      startUploadOfProfileImage,
      setDisplayName,
      updateUserProfile,
    },
    dispatch
  );

const connectedComponent = connect(mapStateToProps, mapDispatchToProps);

const composedLoader = (Component) =>
  compose(connectedComponent, withOnBoarding)(Component);

export default composedLoader;
