import React, { Component } from "react";
import _ from "lodash";
import { compose } from "lodash/fp";
import { Icon, Row } from "antd";
import ConversationSummary from "../component/ConversationSummary";
import WithConversation from "../data/WithConversation";
import WithUser from "../data/WithUser";
import { withRouter } from "react-router";

class RecentConversationsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conversations: this.props.conversations,
      user: this.props.user,
      userProfile: this.props.userProfile,
      filter: true,
      privateConversations: this.props.privateConversations,
      publicConversations: this.props.publicConversations
    };
  }

  filterdConversations(publicConversations) {
    const { filter, trusted, user, userProfile } = this.state;
    const userId = userProfile ? userProfile.uid : user.uid;
    let isParticipant;

    if (filter) {
      return publicConversations.filter(conversation => {
        // lets get all the conversation participants including the creator
        const participants = Object.values(conversation.participants || {})
          .map(c => c.uid)
          .concat([conversation.creator.uid]);

        if (userProfile) {
          // return only conversations that the profile user has participated in
          isParticipant = participants.filter(p => p === userId).length > 0;
        } else {
          // return only conversations that the user has participated in
          isParticipant =
            _.intersection(
              participants,
              trusted.map(t => t.uid)
            ).length > 0;
        }

        return isParticipant || conversation.creator.uid === userId;
      });
    }
    return publicConversations;
  }

  renderPublicConvos = publicConversations =>
    publicConversations
      .sort((a, b) => a.updated_at < b.updated_at)
      .map(c => (
        <ConversationSummary
          showImage={this.state.route === "featured"}
          conversation={c}
          key={c.uid}
          handleClick={() => this.props.handleConversationClick(c)}
        />
      ));

  render() {
    const {
      publicConversations,
      privateConversations,
      userProfile
    } = this.state;

    return publicConversations && privateConversations ? (
      <div>
        <Row style={styles.headerContainer}>
          <Icon style={styles.recentHeader} type="clock-circle-o" />
          <h3 style={styles.recentHeader}>
            Recent conversations including {userProfile.displayName}
          </h3>
          <hr />
        </Row>
        {this.renderPublicConvos(
          this.filterdConversations(
            publicConversations.sort((a, b) => b.updated_at - a.updated_at)
          )
        )}
      </div>
    ) : null;
  }
}

const styles = {
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "20px 0px"
  },
  recentHeader: {
    color: "#9E9E9E",
    fontWeight: "300",
    padding: "0px 10px 0px 0px",
    margin: "0px"
  }
};

export default compose(
  WithConversation,
  WithUser,
  withRouter
)(RecentConversationsContainer);
