import React from "react";
import PropTypes from "prop-types";
import { Switch, Row, Col } from "antd";

const TrustSwitch = ({ text, checked, handleToggle }) => (
  <Row
    className="trustSwitch"
    type="flex"
    justify="end"
    style={{ marginBottom: "20px" }}
  >
    <Col
      className={"trustText"}
      sm={21}
      style={{ textAlign: "right", marginRight: "10px" }}
    >
      {checked ? text : "📣 Filter off — all public conversations"}
    </Col>
    <Col sm={2}>
      <Switch checked={checked} onChange={handleToggle} />
    </Col>
  </Row>
);

TrustSwitch.propTypes = {
  text: PropTypes.string,
  checked: PropTypes.bool,
  handleToggle: PropTypes.func.isRequired,
};

TrustSwitch.defaultProps = {
  text: "🦉 Filter on — just conversations hosted by members you trust",
  checked: true,
};

export default TrustSwitch;
