import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "lodash/fp";
import { Mention } from "antd";
import "../component/Messages.css";
import MessageInput from "../component/MessageInput";
import WithUser from "../data/WithUser";

const { toString, toContentState, Nav } = Mention;

class MessageInputContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      suggestions: [],
      userNames: [],
      value: toContentState(""),
    };
  }

  componentDidMount() {
    const { conversation, getUsers } = this.props;
    getUsers().then((users) => {
      const contributors = Object.values(
        conversation.contributors || {}
      ).concat(conversation.creator);

      const filteredUsers =
        conversation.status === "private"
          ? users.filter((u) => contributors.map((c) => c.uid).includes(u.uid))
          : users;

      const filterArray = (users) =>
        users.filter((u) => u.displayName !== undefined);

      const userNames = filterArray(filteredUsers).reduce(
        (acc, u) =>
          acc.concat({
            displayName: u.displayName,
            mentionName: u.displayName.replace(/\s/g, ""),
            user: u,
          }),
        []
      );
      this.setState({ userNames });
    });
  }

  onSearchChange = (value) => {
    const searchValue = value.toLowerCase();
    const filtered = this.state.userNames.filter(
      (u) => u.displayName.toLowerCase().indexOf(searchValue) !== -1
    );

    const suggestions = filtered.map((u) => (
      <Nav value={u.mentionName} data={u}>
        <span>
          {u.mentionName} - {u.displayName}
        </span>
      </Nav>
    ));
    this.setState({ suggestions });
  };

  onChange = (editorState) => {
    this.setState({
      value: editorState,
    });

    this.props.handleInputChange(toString(editorState));
  };

  handleSendMessage = () => {
    this.props.handleSendMessage().then(() => {
      this.setState({ value: toContentState("") });
    });
  };

  render() {
    const { suggestions } = this.state;

    return (
      <MessageInput
        {...this.props}
        className="message-input-container"
        handleInputChange={this.handleInputChange}
        handleSendMessage={this.handleSendMessage}
        suggestions={suggestions}
        value={this.state.value}
        onSearchChange={(text) => this.onSearchChange(text)}
        onChange={(text) => this.onChange(text)}
        submitted
      />
    );
  }
}

MessageInputContainer.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  handleSendMessage: PropTypes.func.isRequired,
  conversation: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
};

export default compose(WithUser)(MessageInputContainer);
