export const initialState = {
  isEditing: false,
  messageBody: "",
  messageId: "",
  ownerId: "",
  hasEdited: false,
  sendDisabled: false,
};

export const setupMessageEditing = ({ uid = "", body = "", ownerId } = {}) => ({
  type: "setupMessageEditing",
  payload: {
    messageId: uid,
    ownerId,
    messageBody: body,
  },
});

export const toggleSetEditing = ({
  isEditing = true,
  hasEdited = false,
} = {}) => ({
  type: "toggleSetEditing",
  payload: {
    isEditing,
    hasEdited,
  },
});

export const setMessageBody = ({ messageBody = "" } = {}) => ({
  type: "setMessageBody",
  payload: {
    messageBody,
  },
});

export const toggleSendControl = ({ sendDisabled = true } = {}) => ({
  type: "toggleSendControl",
  payload: {
    sendDisabled,
  },
});

export const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case setupMessageEditing().type:
      return { ...state, ...payload };
    case setMessageBody().type:
      return {
        ...state,
        messageBody: payload.messageBody,
        hasEdited: true,
        sendDisabled: payload.messageBody.length === 0,
      };
    case toggleSetEditing().type:
      return {
        ...state,
        isEditing: !state.isEditing,
        hasEdited: payload.hasEdited,
      };
    case toggleSendControl().type:
      return { ...state, sendDisabled: !state.sendDisabled };
    default:
      return state;
  }
};
