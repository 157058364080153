import React from "react";
import * as firebase from "firebase";

const WithMessage = Component =>
  class extends React.Component {
    constructor(props) {
      super(props);
      this.messageRef = (id = "") => firebase.database().ref(`messages/${id}`);
      this.messageActivityRef = (id = "") =>
        firebase.database().ref(`messages_activity/${id}`);
    }

    getImageMessages = conversationId =>
      this.messageRef()
        .orderByChild("conversation")
        .equalTo(conversationId)
        .once("value")
        .then(ms => Object.values(ms.val() || {}))
        .then(messages => messages.filter(m => m.type === "image_message"));

    messageListener = (id, callback) => {
      this.messageRef(id).on("value", cs => callback(cs.val()));
    };

    activityListener = (id, callback) => {
      this.messageActivityRef(id).on("value", cs => callback(cs.val()));
    };

    messagesListener = (conversationId, callback) => {
      this.messageRef()
        .orderByChild("conversation")
        .equalTo(conversationId)
        .on("value", messageSnapshot =>
          callback(Object.values(messageSnapshot.val() || {}))
        );
    };
    // payload = { body, conversationId, tagged }
    handleSendMessage = (payload, user = "system") => {
      const messageRef = this.messageRef("").push();

      const message = {
        ...payload,
        timestamp: firebase.database.ServerValue.TIMESTAMP,
        user,
        uid: messageRef.key,
        type: payload.type || null
      };

      return messageRef.set(message);
    };

    handleDeleteMessage = messageId => {
      const updates = {};

      updates[`/messages/${messageId}/type`] = "deleted_message";
      updates[`/messages/${messageId}/user`] = "system";

      return firebase
        .database()
        .ref()
        .update(updates);
    };

    handleHighlight = (messageId, user, highlighted) => {
      const updates = {};

      const like = {
        type: "like",
        user,
        timestamp: firebase.database.ServerValue.TIMESTAMP,
        message: messageId
      };

      updates[`/messages/${messageId}/likes/${user.uid}`] = highlighted
        ? null
        : { ...like, user: user.uid };

      updates[`/messages_activity/${messageId}/${user.uid}`] = highlighted
        ? null
        : like;

      return firebase
        .database()
        .ref()
        .update(updates);
    };

    render() {
      return (
        <Component
          {...this.state}
          {...this.props}
          getImageMessages={this.getImageMessages}
          messagesListener={this.messagesListener}
          handleSendMessage={this.handleSendMessage}
          handleDeleteMessage={this.handleDeleteMessage}
          handleHighlight={this.handleHighlight}
          activityListener={this.activityListener}
          messageListener={this.messageListener}
        />
      );
    }
  };

export default WithMessage;
