import React from "react";
import { Button } from "antd";
import Avatar from "./Avatar";

const UserProfile = ({
  user,
  trusts,
  trustees,
  handleClick,
  trusted,
  userProfile,
  handleTrust,
  handleTrustsNav,
}) => {
  const userActions =
    user.uid !== userProfile.uid ? (
      <Button disabled={user.isAnonymous} type="primary" onClick={handleTrust}>
        {trusted ? "Trusted" : "Trust"}
      </Button>
    ) : null;

  return (
    <div style={{ textAlign: "center" }}>
      <p>
        <Avatar
          className={"avatar-profile"}
          imageURL={userProfile.photoURL}
          size="large"
          showIcon={userProfile.foundingMember}
        />
      </p>
      <h1 style={styles.userName}>{userProfile.displayName}</h1>
      <p>{userActions}</p>
      <span
        style={{ cursor: "pointer" }}
        onClick={() => handleTrustsNav("trusts")}
      >
        Trusts {trusts.length}
      </span>{" "}
      <span
        style={{ cursor: "pointer" }}
        onClick={() => handleTrustsNav("trustedby")}
      >
        • Trusted by {trustees.length}
      </span>
    </div>
  );
};

const styles = {
  foundingMember: {
    fontSize: "20px",
    display: "flex",
    alignSelf: "flex-start",
  },
  button: {
    backgroundColor: "#C0223B",
    borderColor: "#C0223B",
    marginTop: "10px",
    marginBottom: "1px",
    width: "160px",
  },
  userName: {
    fontFamily: "Roboto",
    fontWeight: 600,
    color: "#757575",
    fontStyle: "bold",
  },
};

export default UserProfile;
