import firebase from "firebase/app";
import { getTokenHeader } from "../../utils/authMethods";
import { baseURL, FbFunctionsEndpoint } from "../../utils/endpoints";

export const signinAnonFB = () => {
  return firebase
    .auth()
    .signInAnonymously()
    .then((res) => console.log("signed in anon ", res))
    .catch(function (error) {
      // Handle Errors here.
      console.log(error);
    });
};

export const createNewUserInDB = async (userRecord) => {
  const { metadata } = userRecord;
  return new Promise((resolve) => {
    getTokenHeader().then((headers) =>
      fetch(`${FbFunctionsEndpoint}/api/createUser`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          user: userRecord,
          metadata: metadata,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log("new user created");
          resolve(data);
        })
        .catch((err) => {
          console.log("couldn't create new user", err);
        })
    );
  });
};

export const signoutFB = () => {
  return new Promise((resolve) => {
    return firebase
      .auth()
      .signOut()
      .then(() => resolve());
  });
};

export const lookupUserFromDB = async (uid) => {
  return new Promise((resolve) => {
    getTokenHeader().then((headers) =>
      fetch(`${FbFunctionsEndpoint}/api/getUserProfile`, {
        method: "POST",
        headers,
        body: JSON.stringify({ uid: uid }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          resolve(data);
        })
    );
  });
};

export const signinWithEmail = ({ email, location }) => {
  return new Promise((resolve, reject) => {
    return firebase
      .auth()
      .signInWithEmailLink(email, location)
      .then((user) => {
        resolve(user);
      })
      .catch((error) => {
        console.log("OOPS! signin with email failed", error);
        reject(error);
      });
  });
};

// sendSigninEmail
export const emailLinkSignin = (email) => {
  return new Promise((resolve, reject) => {
    // console.log("the api endpoint ", FbFunctionsEndpoint);
    getTokenHeader().then((headers) =>
      fetch(`${FbFunctionsEndpoint}/api/sendSigninEmail`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          email: email,
          host: baseURL,
        }),
      })
        .then((response) => {
          if (response.status === 500) {
            reject();
          }
          window.localStorage.setItem("emailForSignIn", email);
          return response.json();
        })
        .then((data) => {
          resolve(data);
        })
    );
  });
};
