import autodux from "autodux";
// import { formatUserData } from "./helpers";
const initialState = {
  isModalOpen: false,
  profileImageFile: {},
  profileDlUrl: "",
  displayName: "",
  uploadingProfileImage: false,
  isComplete: false,
  error: {
    status: false,
    message: "",
  },
};

export const {
  reducer,
  initial,
  slice,
  actions: {
    toggleOnBoardModal,
    uploadProfileImage,
    reportUploadProfileImageSuccess,
    startUploadOfProfileImage,
    setProfileDownloadUrl,
    reportUploadProfileImageError,
    setDisplayName,
    reportSetDisplayNameSuccess,
    reportOnboardingComplete,
  },
  selectors: {
    getIsOnBoardModalOpen,
    getProfileImageFile,
    getProfileImageUploading,
    getUploadingStatus,
    getOnboardingError,
    getDisplayName,
    getOnboardingComplete,
  },
} = autodux({
  slice: "onBoarding",
  initial: initialState,
  actions: {
    toggleOnBoardModal: (state) => ({
      ...state,
      isModalOpen: !state.isModalOpen,
    }),
    startUploadOfProfileImage: (state) => ({
      ...state,
      uploadingProfileImage: true,
    }),
    uploadProfileImage: (state, payload) => ({
      ...state,
      profileImageFile: payload,
    }),

    setDisplayName: (state, payload) => ({
      ...state,
      displayName: payload,
    }),

    setProfileDownloadUrl: (state, profileDlUrl) => ({
      ...state,
      profileDlUrl: profileDlUrl,
    }),

    reportUploadProfileImageSuccess: (state) => ({
      ...state,
      uploadingProfileImage: "done",
    }),
    reportUploadProfileImageError: (state, error) => ({
      ...state,
      error: {
        message: error.message,
        status: "uploadError",
      },
    }),
    reportSetDisplayNameSuccess: (state) => ({
      ...state,
    }),
    reportOnboardingComplete: (state, payload) => ({
      ...state,
      isComplete: payload,
    }),
  },

  selectors: {
    getProfileImageUploading: ({ uploadProfileImage }) => uploadProfileImage,
    getIsOnBoardModalOpen: ({ isModalOpen }) => isModalOpen,
    getProfileImageFile: ({ profileImageFile }) => profileImageFile,
    getUploadingStatus: ({ uploadingProfileImage }) => uploadingProfileImage,
    getOnboardingError: ({ error }) => error,
    getOnboardingComplete: ({ isComplete }) => isComplete,
    getDisplayName: ({ displayName }) => displayName,
  },
});
