import React from 'react';
import amplitude from 'amplitude-js';
import moment from 'moment';

const WithAnalytics = Component =>
  class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        initialized: false,
      };
    }

    initAnalytics = () => {
      const apiKey =
        process.env.NODE_ENV === 'development'
          ? process.env.REACT_APP_AMPLITUDE_DEV_KEY
          : process.env.REACT_APP_AMPLITUDE_KEY;

      amplitude.init(apiKey, null, null, () =>
        this.setState({ initialized: true }));
    };

    registerUser = user => {
      amplitude.setUserId(user.uid);
      amplitude.setUserProperties(user);
    };

    launchApp = () => {
      const event = { loggedIn: true };

      amplitude.logEvent('launchApp', event);
    };

    viewFeatured = (tab, user, conversations) => {
      const now = moment(new Date());
      const conversationAge = moment(conversations[0].timestamp);
      const event = {
        tab,
        conversationsDisplayed: conversations.length,
        ageOfTopConversation: Math.round(moment.duration(now.diff(conversationAge)).asHours()),
        newConversations: conversations.filter(conversation => !(conversation.views || {})[user.uid]).length,
      };
      amplitude.logEvent('viewFeatured', event);
    };

    viewRecent = (tab, user, conversations) => {
      const event = {
        tab,
        conversationsDisplayed: conversations.length,
        newConversations: conversations.filter(conversation => !(conversation.views || {})[user.uid]).length,
      };

      amplitude.logEvent('viewRecent', event);
    };

    viewPrivate = (tab, user, conversations) => {
      const event = {
        tab,
        conversationsDisplayed: conversations.length,
        newConversations: conversations.filter(conversation => !(conversation.views || {})[user.uid]).length,
      };

      amplitude.logEvent('viewPrivate', event);
    };

    viewConversation = conversation => {
      const {
        status,
        branch,
        participants,
        followers,
        contributors,
        title,
      } = conversation;

      const event = {
        conversationTitle: title,
        conversationType: branch ? 'branch' : 'new',
        conversationStatus: status,
        participantMembers: Object.keys(participants || {}).length,
        followingMembers: Object.keys(followers || {}).length,
        contributorMembers: Object.keys(contributors || {}).length,
      };

      amplitude.logEvent('viewConversation', event);
    };

    viewActivity = () => {
      amplitude.logEvent('viewActivity');
    };

    viewCompose = () => {
      amplitude.logEvent('viewCompose');
    };

    sendMessage = (conversation, message) => {
      const {
        status,
        branch,
        participants,
        followers,
        contributors,
        title,
      } = conversation;

      const event = {
        // hasLink: TODO
        conversationTitle: title || null,
        conversationType: branch ? 'branch' : 'new',
        conversationStatus: status,
        participantMembers: Object.keys(participants || {}).length,
        followingMembers: Object.keys(followers || {}).length,
        contributorMembers: Object.keys(contributors || {}).length,
        messageType: message.imageString ? 'image' : 'text',
        wordsContained: message.body ? message.body.split(' ').length : null,
        taggedMembers: Object.keys(message.tagged || {}).length,
      };

      amplitude.logEvent('sendMessage', event);
    };

    likeMessage = message => {
      const { body, user } = message;
      const now = moment(new Date());
      const messageAge = moment(message.timestamp);

      const event = {
        messageBody: body,
        messageCreatorName: user ? user.displayName : 'system',
        messageAge: Math.round(moment.duration(now.diff(messageAge)).asHours()),
      };

      amplitude.logEvent('likeMessage', event);
    };

    trustMember = (trusted, screenName, member) => {
      const { displayName, uid } = member;
      // TODO twitter friend

      const event = {
        newStatus: !trusted,
        appLocation: screenName,
        featuredMember: true || false,
        memberName: displayName,
        memberUid: uid,
      };

      amplitude.logEvent('trustMember', event);
    };

    render = () => (
      <Component
        {...this.state}
        {...this.props}
        initAnalytics={this.initAnalytics}
        registerUser={this.registerUser}
        launchApp={this.launchApp}
        viewConversation={this.viewConversation}
        viewFeatured={this.viewFeatured}
        viewRecent={this.viewRecent}
        viewActivity={this.viewActivity}
        viewPrivate={this.viewPrivate}
        viewCompose={this.viewCompose}
        sendMessage={this.sendMessage}
        likeMessage={this.likeMessage}
        trustMember={this.trustMember}
      />
    );
  };

export default WithAnalytics;
