import React from 'react';
import { Card } from 'antd';

const UnAuthorized = () => (
    <Card bodyStyle={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <h2>You are not authorized to access this conversation.</h2>
        <p>Please find another conversation to view</p>
    </Card>
);

export default UnAuthorized;
