import { compose } from "lodash/fp";
import withAuth from "./with-auth";
import withAuthModal from "./with-auth-modal";
import withLoader from "./with-loader";
import withOnboarding from "./with-onboarding";

import WithUser from "../data/WithUser";

export default compose(
  WithUser,
  withAuth,
  withAuthModal,
  withOnboarding,
  withLoader
);
