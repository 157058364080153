import React from "react";

const withLoader = (Component) => (props) => {
  return (
    <React.Fragment>
      <Component {...props} isAuthenticating={false}></Component>
    </React.Fragment>
  );
};

export default withLoader;
