import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'antd';
import MessageMetadata from './MessageMetadata';

const ImageMessage = ({
    crop,
    message,
    handleClick,
    handleAvatarClick,
    showMetadata,
    action,
    showTimeSeparator,
}) => (
    <div style={styles.container}>
        <Card bordered={false} bodyStyle={showMetadata ? {} : { padding: '0px' }}>
            <Col sm={24}>
                <div style={showTimeSeparator ? styles.separatorStyle : {}} />
                <MessageMetadata
                    message={message}
                    show={showMetadata}
                    handleAvatarClick={handleAvatarClick}
                    action={action}
                />
            </Col>
        </Card>
        <Row
            style={Object.assign({}, styles.imageContainer, { maxHeight: crop ? '500px' : null })}
            onClick={() => handleClick(message)}>
            <img alt="message" src={message.imageURL} style={styles.imageMessage} />
        </Row>
    </div>
);

ImageMessage.propTypes = {
    crop: PropTypes.bool,
    message: PropTypes.object.isRequired,
    handleClick: PropTypes.func,
    handleAvatarClick: PropTypes.func,
    showMetadata: PropTypes.bool,
    action: PropTypes.func,
    showTimeSeparator: PropTypes.bool,
};

ImageMessage.defaultProps = {
    crop: true,
    showMetadata: true,
    showTimeSeparator: false,
    action: () => {},
    handleClick: () => {},
    handleAvatarClick: () => {},
};

export default ImageMessage;

const styles = {
    container: {
        paddingBottom: '16px',
        backgroundColor: 'white',
    },
    imageContainer: {
        alignItems: 'center',
        display: 'flex',
        overflow: 'hidden',
        width: '100%',
    },
    imageMessage: {
        width: '100%',
    },
    separatorStyle: {
        paddingTop: '7px',
        marginLeft: '11px',
        marginRight: '11px',
        borderTopWidth: '1px',
        paddingBottom: '13px',
    },
    messageImageMetadata: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingTop: '2px',
        marginTop: '5px',
        marginBottom: '5px',
    },
    messageImageMetadataUserName: {
        display: 'flex',
        paddingRight: '10px',
        fontWeight: '600',
        fontSize: '18px',
        justifyContent: 'center',
    },
    messageImageMetadataMessageTime: {
        display: 'flex',
        fontWeight: '400',
        fontSize: '13px',
        lineHeight: '15px',
        justifyContent: 'center',
        marginTop: '2px',
        alignSelf: 'flex-start',
    },
};
