import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { compose, difference, intersection } from 'lodash/fp';
import WithUser from '../data/WithUser';
import WithConversation from '../data/WithConversation';
import Loading from '../component/Loading';
import MemberList from '../component/MemberList';

class MemberDirectoryContainer extends Component {
    constructor(props) {
        super(props);
        this.handleSearch = debounce(this.handleSearch.bind(this), 500);
        this.state = {
            loading: true,
            users: [],
            trustedUsers: [],
            filteredUsers: null,
            filteredTrustedUsers: null,
        };
    }
    componentWillMount() {
        const {
            conversationListener,
            getUsers,
            getTrusts,
            user,
            match: {
                params: {
                    memberType, conversationId, userId, trustType,
                },
                path,
            },
        } = this.props;

        const promises = [getUsers(), getTrusts(user.uid, 'trusts')];

        if (userId) {
            promises.push(getTrusts(userId, trustType === 'trustedby' ? 'trusted by' : 'trusts'));
        }

        Promise.all(promises).then(results => {
            const [allUsers, trusted] = results;
            const userProfileTrusts = userId ? results[2] : null;

            conversationListener(conversationId, conversation => {
                const users =
                    conversation && !path.includes('invite')
                        ? Object.values(conversation[memberType] || {})
                        : userProfileTrusts || allUsers;

                const getIds = a => a.map(i => i.uid);
                const populateFromId = a => ids => a.filter(u => ids.includes(u.uid));
                const allUserIds = getIds(users);
                const getUniqueUsers = compose(
                    populateFromId(users),
                    difference(allUserIds),
                    getIds,
                );
                const getCommonUsers = compose(
                    populateFromId(users),
                    intersection(allUserIds),
                    getIds,
                );

                const trustedUsers = getCommonUsers(trusted);

                this.setState({
                    conversation,
                    users: getUniqueUsers(trustedUsers),
                    trustedUsers,
                    loading: false,
                });
            });
        });
    }
    handleSearch = e => {
        const {
            target: { value },
        } = e;

        const searchText = value.trim().toLowerCase();
        const { users, trustedUsers } = this.state;

        const filterArray = users.filter(u => u.displayName !== undefined);

        const filteredUsers = filterArray.filter(user =>
            user.displayName.toLowerCase().includes(searchText));

        const filteredTrustedUsers = trustedUsers.filter(user =>
            user.displayName.toLowerCase().includes(searchText));

        this.setState({ filteredUsers, filteredTrustedUsers });
    };

    handleAvatarClick = userId => {
        this.props.history.push(`/profile/${userId}`);
    };

    handleInvite = user => {
        const {
            addContributor,
            addHost,
            match: {
                params: { conversationId, action },
            },
        } = this.props;

        action === 'contributors'
            ? addContributor(user, conversationId)
            : addHost(user, conversationId);
    };

    render() {
        const {
            loading,
            users,
            trustedUsers,
            filteredUsers,
            filteredTrustedUsers,
            conversation,
        } = this.state;
        const {
            match: {
                params: { action },
                path,
            },
        } = this.props;

        return loading ? (
            <Loading />
        ) : (
            <MemberList
                hostsRoute={action === 'hosts'}
                handleAvatarClick={this.handleAvatarClick}
                conversation={conversation}
                inviteAction={path.includes('invite') ? this.handleInvite : null}
                users={filteredUsers || users}
                trustedUsers={filteredTrustedUsers || trustedUsers}
                handlePress={this.props.handlePress}
                handleSearch={e => {
                    e.persist();
                    this.handleSearch(e);
                }}
            />
        );
    }
}

MemberDirectoryContainer.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.array.isRequired,
    getUsers: PropTypes.func.isRequired,
    getTrusts: PropTypes.func.isRequired,
    conversationListener: PropTypes.func.isRequired,
    addHost: PropTypes.func.isRequired,
    addContributor: PropTypes.func.isRequired,
    handlePress: PropTypes.func,
    user: PropTypes.object.isRequired,
};

MemberDirectoryContainer.defaultProps = {
    handlePress() {},
};

export default compose(
    WithConversation,
    WithUser,
)(MemberDirectoryContainer);
