import React from "react";
import PropTypes from "prop-types";
import { Col, Row, Card, Switch } from "antd";
import Avatar from "./Avatar";

let styles;

const ConversationDetail = ({
  user,
  conversation,
  trusts,
  handleFollowConversation,
  handleFeatureConversation,
  handleAvatarPress,
  handleReport,
  handleClick,
  handleFeaturedImageSelect,
}) => {
  const contributors = Object.values(conversation.contributors || []);
  const followers = Object.values(conversation.followers || []);
  const requests = Object.values(conversation.requests || []);
  const hostRequests = Object.values(conversation.hostRequests || []);

  const userIsCreator = user.uid === conversation.creator.uid;
  const userIsAdmin = user.role === "admin";

  const userStatus = (group) => {
    const canContribute = {
      open: true,
      trusted: trusts.filter((trustee) => trustee.uid === user.uid).length > 0,
      closed: conversation.contributors
        ? conversation.contributors[user.uid]
        : false,
    };

    if (group === "contributors") {
      return canContribute[conversation.contribution] || canContribute.closed;
    }

    return conversation[group]
      ? conversation[group][user.uid] !== undefined
      : false;
  };

  const trustsCreator = (uid) =>
    trusts.filter((t) => conversation.creator.uid).length > 0;

  const conversationHosts = Object.values(conversation.hosts || {});

  const contributionText = {
    closed: "cohosts and specified contributors",
    trusted: "members the host trusts",
    open: "everyone",
  };

  const creator = (
    <Row className="creator-container" style={styles.creatorContainer}>
      <Col
        className="status-header status-emoji"
        sm={3}
        xs={3}
        style={styles.statusHeader}
      >
        <Avatar
          size="medium"
          imageURL={conversation.creator.photoURL}
          handlePress={handleAvatarPress}
        />
      </Col>
      <Col sm={21} xs={21}>
        <p className="detail" style={styles.hostDetail}>
          {userIsCreator ? "You are" : `${conversation.creator.displayName} is`}{" "}
          the host of this conversation
        </p>
      </Col>
    </Row>
  );

  const conversationStatus = (
    <div style={styles.rowWrapper}>
      <Row style={styles.statusContainer}>
        <Col
          className="status-emoji status-header"
          sm={3}
          xs={3}
          style={styles.statusHeader}
        >
          <span role="img" aria-label="emoji">
            {conversation.status === "public" ? "🍿" : "🔑"}
          </span>
        </Col>
        <Col sm={21} xs={21} style={styles.detailContainer}>
          <p className="detail" style={styles.detail}>
            This is a {conversation.status} conversation
          </p>
        </Col>
      </Row>
    </div>
  );

  const conversationOrigin = (
    <div style={styles.rowWrapper}>
      <Row style={styles.originContainer}>
        <Col
          className="status-emoji status-header"
          sm={3}
          xs={3}
          style={styles.statusHeader}
        >
          {conversation.branchedFrom ? "🌿" : "🌱"}
        </Col>
        <Col sm={21} xs={21} style={styles.detailContainer}>
          <p className="detail" style={styles.detail}>
            This is a {conversation.branchedFrom ? "branch" : "new"}{" "}
            conversation
          </p>
        </Col>
      </Row>
    </div>
  );

  const follow = (
    <div style={styles.rowWrapper}>
      <Row style={styles.contributorContainer}>
        <Row>
          <Col
            className="status-emoji status-header"
            sm={3}
            xs={3}
            style={styles.statusHeader}
          >
            {userStatus("followers") ? "🔔" : "🔕"}
          </Col>
          <Col sm={18} xs={18} style={styles.mainDetailContainer}>
            <p className="detail-subtext detail" style={styles.detail}>
              You are {userStatus("followers") ? "" : "not "}following this
              conversation{" "}
            </p>
          </Col>
          <Col sm={3} style={styles.switchContainer}>
            <Switch
              initialValue={false}
              checked={userStatus("followers")}
              onChange={handleFollowConversation}
              style={styles.switch}
            />
          </Col>
        </Row>
        <Row>
          <Col
            sm={21}
            xs={21}
            push={3}
            style={styles.subDetailContainer}
            onClick={handleClick("followers")}
          >
            <p className="detail-subtext sub-detail" style={styles.subDetail}>
              {followers.length} member
              {(followers.length > 1 && "s are") ||
                (followers.length === 0 && "s are")}{" "}
              following this conversation ›
            </p>
          </Col>
        </Row>
      </Row>
    </div>
  );

  const contributorsDetails = (
    <div style={styles.rowWrapper}>
      <Row>
        <Col
          sm={3}
          xs={3}
          className="status-emoji status-header"
          style={styles.statusHeader}
        >
          {userStatus("contributors") ||
          userIsCreator ||
          userStatus("hosts") ||
          trustsCreator(user.uid)
            ? "📣"
            : "👀"}
        </Col>
        <Col sm={21} xs={21} style={styles.mainDetailContainer}>
          <p className="detail" style={styles.detail}>
            {userIsCreator ? "As host, you can" : "You are invited to"}{" "}
            {userStatus("contributors") ||
            userStatus("hosts") ||
            userIsCreator ||
            trustsCreator(user.uid)
              ? "contribute"
              : "view"}{" "}
            this conversation
          </p>
        </Col>
      </Row>

      <Row>
        <Col
          sm={21}
          push={3}
          style={styles.largeDetailContainer}
          onClick={handleClick("contributors")}
        >
          <p className="sub-detail" style={styles.subDetail}>
            {`This conversation is open to ${
              contributionText[conversation.contribution]
            }`}
          </p>
          <p className="sub-detail" style={styles.subDetail}>
            • {conversationHosts.length} cohost
            {(conversationHosts.length > 1 && "s") ||
              (conversationHosts.length === 0 && "s")}{" "}
            and {hostRequests.length} pending request
            {hostRequests.length > 1 || (hostRequests.length === 0 && "s")}
          </p>
          <p className="sub-detail" style={styles.subDetail}>
            • {contributors.length} contributor
            {(contributors.length > 1 && "s") ||
              (contributors.length === 0 && "s")}{" "}
            and {requests.length} pending request
            {requests.length > 1 || (requests.length === 0 && "s")}
          </p>
          <p className="sub-detail" style={styles.subDetail}>
            View and Edit {"›"}
          </p>
        </Col>
      </Row>
    </div>
  );

  const feature =
    conversation.status === "public" ? (
      <div>
        <Row style={styles.featureContainer}>
          <Col
            sm={3}
            xs={3}
            className="status-emoji status-header"
            style={styles.statusHeader}
          >
            <span
              role="img"
              className="status-emoji status-header"
              aria-label="emoji"
            >
              🏆
            </span>
          </Col>
          <Col sm={18} style={styles.mainDetailContainer}>
            <p className="detail" style={styles.detail}>
              This is {conversation.featured ? "" : "not "}a featured
              conversation
            </p>
          </Col>
          <Col sm={3} style={styles.switchContainer}>
            <Switch
              initialValue={false}
              checked={conversation.featured}
              onChange={handleFeatureConversation}
              disabled={!userIsAdmin}
              style={styles.switch}
            />
          </Col>
        </Row>
        <Row onClick={handleFeaturedImageSelect}>
          <Col sm={22} xs={22} push={3}>
            <p className="sub-detail" style={styles.subDetail}>
              View and select a featured image ›
            </p>
          </Col>
        </Row>
      </div>
    ) : null;

  const destructive = (
    <Row style={styles.destructiveContainer} onClick={handleReport}>
      <Col
        sm={3}
        xs={3}
        className="status-emoji-block
       status-header"
        style={styles.statusHeader}
      >
        <span role="img" className="status-header" aria-label="emoji">
          ❌
        </span>
      </Col>
      <Col sm={21} xs={21} style={styles.mainDetailContainer}>
        <p className="detail-block" style={styles.detail}>
          {conversation.status === "public" ? "Block" : "Leave"} this
          conversation
        </p>
      </Col>
    </Row>
  );

  return (
    <Row className="conversation-detail-container" justify="center">
      <Card>
        {creator}
        {conversationStatus}
        {conversationOrigin}
        {follow}
        {contributorsDetails}
        {feature}
      </Card>
      <Card style={styles.destructive}>{destructive}</Card>
    </Row>
  );
};

ConversationDetail.propTypes = {
  conversation: PropTypes.object.isRequired,
  handleFollowConversation: PropTypes.func.isRequired,
};

const baseContainer = {
  flexDirection: "row",
  display: "flex",
  alignItem: "center",
  width: "100%",
};

styles = {
  rowWrapper: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  statusContainer: {
    ...baseContainer,
    justifyContent: "center",
  },
  originContainer: {
    ...baseContainer,
  },
  followContainer: {
    ...baseContainer,
    margin: "0px",
  },
  featureContainer: {
    ...baseContainer,
    margin: "0px",
    // paddingTop: '5px',
    // paddingBottom: '5px',
  },
  statusHeader: {
    alignItems: "center",
    justifyContent: "center",
    // fontSize: '38px',
    // height: '80px',
  },
  switchContainer: {
    fontFamily: "Roboto",
    fontWeight: 400,

    alignItems: "center",
    justifyContent: "flex-end",
    // height: '80px',
  },

  creatorDetailContainer: {
    fontFamily: "Roboto",
    fontWeight: 400,
    // paddingBottom: '10px',
    alignItems: "center",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "#E9E9E9",
  },
  mainDetailContainer: {
    fontFamily: "Roboto",
    fontWeight: 400,

    // height: '80px',
    alignItems: "center",
  },
  subDetailContainer: {
    fontFamily: "Roboto-Medium",
    fontWeight: 400,
    alignItems: "center",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "#E9E9E9",
  },
  detailContainer: {
    fontFamily: "Roboto",
    fontWeight: 400,

    // height: '80px',
    alignItems: "center",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "#E9E9E9",
  },
  largeDetailContainer: {
    alignItems: "center",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "#E9E9E9",
  },
  hostDetail: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "32px",
    marginBottom: 0,
    paddingBottom: "1em",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "#E9E9E9",
  },
  detail: {
    fontSize: "32px",
    wordWrap: "break-word",
  },
  subDetail: {
    fontSize: "16px",
    marginBottom: "0px",
    color: "#666666",
    cursor: "pointer",
  },
  destructive: {
    marginTop: "40px",
    borderColor: "#c0223b",
  },
  switch: {
    alignSelf: "center",
  },
};

export default ConversationDetail;
