import { intersection } from "lodash/fp";

export const initialState = {
  user: {},
  conversation: {},
  isHost: false,
  isContributor: false,
  trustees: [],
  hasAccess: false,
  conversationHosts: [],
  isCreator: false,
  isContributionOpen: false,
  isContributionTrusted: false,
};

export const setUser = (user) => ({
  type: "setUser",
  payload: {
    user,
  },
});

export const setUserSuccess = ({ success = true }) => ({
  type: "setUserSuccess",
  payload: {
    success,
  },
});

export const setConversation = ({
  user = {},
  conversation = "",
  trustees = [],
} = {}) => ({
  type: "setConversation",
  payload: {
    user,
    conversation,
    trustees,
  },
});

export const setConversationHost = (conversation) => ({
  type: "setConversationHost",
});

export const setAccess = (a) => ({
  type: "setAccess",
});

// User has access to a convo if:
// 1. The user is the creator of a conversation.
// 2. The user is a host (cohost) of a conversation.
// 3. The user is a contributor to the converstion.
// 4. The uset is a trustee of a cohost (a cohost trusts that user)
// 5. The conversations contribution property is set to "open"
// 6. The conversations contribution property is set to "trusted" and the user is trusted by the creator.

export const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case setConversation().type:
      return {
        ...state,
        ...payload,
      };
    case setConversationHost().type:
      return {
        ...state,
        trustees: state.trustees.map((t) => t.uid),
        isCreator: state.conversation.creator.uid === state.user.uid,
        isContributionOpen: state.conversation.contribution === "open",
        isContributionTrusted: state.conversation.contribution === "trusted",

        isHost:
          Object.keys(state.conversation.hosts || []).filter(
            (u) => u === state.user.uid
          ).length === 1,

        isContributor:
          Object.keys(state.conversation.contributors || []).filter(
            (u) => u === state.user.uid
          ).length === 1,

        conversationHosts: [
          ...Object.values(state.conversation.hosts || []),
          state.conversation.creator.uid,
        ]
          .filter(({ contribution }) => contribution === "trusted")
          .map((u) => u.uid),
      };

    case setAccess().type:
      return {
        ...state,
        hasAccess:
          intersection(state.conversationHosts, state.trustees).length === 1 ||
          (state.isHost === true && state.user.isAnonymous === false) ||
          (state.isCreator === true && state.user.isAnonymous === false) ||
          (state.isContributor === true && state.user.isAnonymous === false) ||
          (state.isContributionOpen === true &&
            state.user.isAnonymous === false) ||
          (
            state.isContributionTrusted &&
            state.trustees.filter((t) => t === state.conversation.creator.uid)
          ).length === 1,
      };
    default:
      return state;
  }
};
