import React from "react";
import * as firebase from "firebase";
import AuthContext from "../context/authcontext";

const opts = {
  test: "https://media-test-a09a4.firebaseapp.com/api",
  development: "https://media-test-a09a4.firebaseapp.com/api/api",
  production: "https://us-central1-media-28d4a.cloudfunctions.net/api",
};

const FIREBASE_FUNCTIONS_ENDPOINT = opts[process.env.NODE_ENV];

const WithUser = (Component) =>
  class extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
      super(props);

      this.rootRef = () => firebase.database().ref();
      this.userRef = (id = "") => firebase.database().ref(`users/${id}`);
      this.userTrustsRef = (id = "") =>
        firebase.database().ref(`users_trusts/${id}`);
      this.userTrusteesRef = (id = "") =>
        firebase.database().ref(`users_trustees/${id}`);
      this.infoRef = () => firebase.database().ref(".info/connected");

      this.provider = new firebase.auth.TwitterAuthProvider();
    }

    getTrusts = (userId, type) => {
      const query =
        type === "trusted by"
          ? this.userTrusteesRef(userId).once("value")
          : this.userTrustsRef(userId).once("value");

      return query.then((userSnapshot) =>
        Object.values(userSnapshot.val() || {})
      );
    };

    getFeaturedUsers = () =>
      this.userRef("")
        .orderByChild("featured")
        .equalTo(true)
        .once("value")
        .then((usersSnapshot) => Object.values(usersSnapshot.val() || {}));

    getUsers = () =>
      this.userRef("")
        .once("value")
        .then((usersSnapshot) => Object.values(usersSnapshot.val() || {}));

    getTokenHeader = () =>
      firebase
        .auth()
        .currentUser.getIdToken()
        .then((idToken) => {
          const headers = new Headers();
          headers.append("Authorization", `Token ${idToken}`);
          headers.append("Accept", "application/json");
          headers.append("Content-Type", "application/json");
          return headers;
        });

    saveBio = (userId, payload) =>
      this.userRef(userId).child("bio").set(payload);

    getTwitterFriends = (user) => {
      let query;
      let friends;

      return user.twitterId
        ? firebase
            .database()
            .ref("twitter_auth")
            .child(user.twitterId)
            .once("value")
            .then((twitterRef) => twitterRef.val())
            .then((twitterData) => {
              const {
                oauth_token: oauthToken,
                oauth_token_secret: oauthTokenSecret,
              } = twitterData;
              query = `?access_token_key=${oauthToken}&access_token_secret=${oauthTokenSecret}`;
            })
            .then(this.getTokenHeader)
            .then((headers) =>
              fetch(`${FIREBASE_FUNCTIONS_ENDPOINT}/friends/list${query}`, {
                headers,
              })
            )
            .then((response) => {
              if (!response.ok) return null;
              return response.json();
            })
            .then((response) => {
              friends = response === null ? [] : response.users;
              return this.getUsers();
            })
            .then((users) =>
              friends.reduce((acc, friend) => {
                const included = users.filter(
                  (u) => u.displayName === friend.name
                );
                return acc.concat(included);
              }, [])
            )
        : Promise.resolve([]);
    };

    trust = async (user, trustee, trusted) => {
      const updates = {};

      updates[`users_trusts/${user.uid}/${trustee.uid}`] = trusted
        ? null
        : trustee;
      updates[`users_trustees/${trustee.uid}/${user.uid}`] = trusted
        ? null
        : user;

      return this.rootRef().update(updates);
    };

    userListener = (user, callback) => {
      const { isAnonymous, uid } = user;
      return isAnonymous
        ? callback({ uid, isAnonymous })
        : this.userRef(uid).on("value", (cs) => callback(cs.val()));
    };
    profileListener = (id, callback) => {
      this.userRef(id).on("value", (cs) => callback(cs.val()));
    };

    recordSession = (userId) => {
      const ref = this.userRef(`${userId}/lastSession`);
      ref.set(firebase.database.ServerValue.TIMESTAMP);
    };

    sessionListener = (userId) => {
      this.infoRef().on("value", (infoSnap) => {
        const connected = infoSnap.val() === true;

        if (
          connected &&
          firebase.auth().currentUser !== null &&
          firebase.auth().currentUser.isAnonymous !== false
        ) {
          this.userRef(`${userId}/lastSession`)
            .onDisconnect()
            .set(firebase.database.ServerValue.TIMESTAMP);
        }
      });
    };
    handleErrorResponse = (errorResponse) =>
      errorResponse.text().then((text) => {
        let json;
        try {
          json = JSON.parse(text);
        } catch (e) {
          console.error("Received invalid response", text);
          return text;
        }
        console.error("Received error", json);
        return json;
      });

    getProfileImage = (user) => {
      this.getTokenHeader().then((headers) =>
        fetch(`${FIREBASE_FUNCTIONS_ENDPOINT}/userProfile`, {
          method: "POST",
          body: JSON.stringify({
            twitterId: user.twitterId,
          }),
          headers,
        }).then((response) => {
          if (!response.ok) {
            return this.handleErrorResponse(response);
          }
          return response.json();
        })
      );
    };

    search = (term) =>
      this.getTokenHeader()
        .then((headers) =>
          // Fetch here
          fetch(
            `${FIREBASE_FUNCTIONS_ENDPOINT}/users/search?term=${encodeURIComponent(
              term
            )}`,
            {
              headers,
            }
          )
        )
        .then((response) => {
          if (!response.ok) {
            return this.handleErrorResponse(response);
          }
          return response.json();
        });

    invite = (targetTwitterId) =>
      this.getTokenHeader()
        .then((headers) =>
          fetch(`${FIREBASE_FUNCTIONS_ENDPOINT}/twitter_invite`, {
            method: "POST",
            body: JSON.stringify({
              target_id: targetTwitterId,
            }),
            headers,
          })
        )
        .then((response) => {
          if (!response.ok) {
            return this.handleErrorResponse(response);
          }
          return response.json();
        });

    render() {
      return (
        <Component
          getTwitterFriends={this.getTwitterFriends}
          getFeaturedUsers={this.getFeaturedUsers}
          saveBio={this.saveBio}
          trust={this.trust}
          getTrusts={this.getTrusts}
          getUsers={this.getUsers}
          userListener={this.userListener}
          profileListener={this.profileListener}
          user={this.context.user}
          sessionListener={this.sessionListener}
          recordSession={this.recordSession}
          {...this.state}
          {...this.props}
        />
      );
    }
  };

export default WithUser;
