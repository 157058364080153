import React from 'react';
import PropTypes from 'prop-types';
import { Input, List, Row, Col, Card, Button } from 'antd';
import MemberStamp from './MemberStamp';

const MemberList = ({
    inviteAction,
    users,
    trustedUsers,
    handleSearch,
    conversation,
    handleAvatarClick,
    hostsRoute,
}) => {
    const search = (
        <Input
            size="large"
            placeholder="Search members"
            onChange={handleSearch}
            style={{ display: 'flex', alignSelf: 'flex-end' }}
        />
    );
    const header = title => (
        <div style={styles.sectionHeader}>
            <h2 style={styles.sectionTitle}>{title}</h2>
        </div>
    );

    const isCreator = h => h.uid === conversation.creator.uid;

    const InviteButton = (text, user) => (
        <Button type="primary" size="small" onClick={() => inviteAction(user)}>
            {text}
        </Button>
    );

    const action = u =>
        (conversation.contributors || {})[u.uid] ? (
            <p>contributor</p>
        ) : (conversation.hosts || {})[u.uid] ? (
            <p>cohost</p>
        ) : hostsRoute ? (
            isCreator(u) ? (
                <p>host</p>
            ) : (
                InviteButton('Add cohost', u)
            )
        ) : isCreator(u) ? (
            <p>host</p>
        ) : (
            InviteButton('Add contributor', u)
        );

    const row = i => (
        <List.Item style={styles.memberContainer} actions={[inviteAction ? action(i) : null]}>
            <MemberStamp user={i} handleClick={() => handleAvatarClick(i.uid)} />
        </List.Item>
    );

    return (
        <Card>
            <Row>
                <Col sm={24} style={{ marginBottom: 20 }}>
                    {search}
                </Col>
            </Row>
            <Row>
                <Col sm={24}>
                    <List
                        header={header('Members you trust')}
                        dataSource={trustedUsers}
                        renderItem={row}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={24}>
                    <List header={header('All members')} dataSource={users} renderItem={row} />
                </Col>
            </Row>
        </Card>
    );
};

MemberList.propTypes = {
    users: PropTypes.array.isRequired,
    trustedUsers: PropTypes.array.isRequired,
    handleSearch: PropTypes.func.isRequired,
    inviteAction: PropTypes.func,
    conversation: PropTypes.object,
    handleAvatarClick: PropTypes.func.isRequired,
};

MemberList.defaultProps = {
    inviteAction: null,
    conversation: {},
};

const styles = {
    sectionHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    sectionTitle: {
        fontFamily: 'Roboto-Medium',
    },
    memberContainer: {
        display: 'flex',
        cursor: 'pointer',
    },
    memberName: {
        fontWeight: 'bold',
        fontSize: '18px',
        marginBottom: '0px',
        marginLeft: '20px',
        alignSelf: 'center',
    },
};

export default MemberList;
