import React, { Component } from "react";
import * as firebase from "firebase";
import { compose, last, intersection } from "lodash/fp";
import WithConversation from "../data/WithConversation";
import WithMessage from "../data/WithMessage";
import WithAnalytics from "../data/WithAnalytics";
import Messages from "../component/Messages";
import UnAuthorized from "../component/Unauthorized";
import Loading from "../component/Loading";
import ConversationCompose from "../component/ConversationCompose";
import MessageInputRequestWall from "../component/MessageInputRequestWall";
import MessageInputContainer from "../container/MessageInputContainer";
import SignInForm from "../container/SignInForm";
import WithUser from "../data/WithUser";
import Utils from "../utils/DisplayUtils";
import AuthContext from "../context/authcontext";

class Conversation extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      text: "",
      composeConversation: false,
      route: this.props.location.pathname.split("/")[1],
      isPrivate: false,
      messageId: props.match.params.messageId,
      conversationId: props.match.params.conversationId,
      conversation: {},
      messages: [],
      imageURL: null,
      taggingUser: false,
      taggedUsers: {},
      trusts: [],
      trustees: [],
      trusted: [],
      unauthorized: false,
      scroll: true,
    };
  }

  componentWillMount() {
    const { conversationId } = this.state;
    const { user, viewCompose } = this.props;

    const promises = [
      this.props.getTrusts(user.uid, "trusts"),
      this.props.getTrusts(user.uid, "trusted by"),
    ];

    promises.concat(
      conversationId ? this.initConversation() : this.setConversationState()
    );

    return Promise.all(promises)
      .then((result) => {
        const [trusts, trustees] = result;
        const { viewConversation } = this.props;
        const { conversation } = this.state;

        const trusted =
          trustees.filter((trustee) => trustee.uid === user.uid).length > 0;

        if (conversation) {
          viewConversation(conversation);
        }

        this.setState(
          {
            trusts,
            trustees,
            trusted,
          },
          () => (conversationId ? null : viewCompose())
        );
      })
      .then(() => {
        const { conversation } = this.state;

        return this.setState({
          lastViewed: conversation.views ? conversation.views[user.uid] : null,
        });
      });
  }

  componentWillReceiveProps(newProps) {
    const { conversationListener } = this.props;
    const { conversationId } = this.state;
    const NewConversationId = newProps.match.params.conversationId;
    if (conversationId !== NewConversationId) {
      conversationListener(NewConversationId, (conversation) => {
        this.setState({ conversationId: NewConversationId, conversation });
      });
      this.getConversation(NewConversationId);
    }
  }

  componentWillUnmount() {
    const { recordView, user } = this.props;
    const { conversation } = this.state;

    return conversation.uid ? recordView(conversation, user.uid) : null;
  }

  getConversation(NewConversationId) {
    const { messagesListener } = this.props;
    messagesListener(NewConversationId, (messages) => {
      this.setState({ messages });
    });
  }

  setConversationState = () => {
    const { messageListener } = this.props;
    const { messageId } = this.state;

    this.setState({ composeConversation: true, loading: false });

    if (messageId) {
      messageListener(messageId, (message) => {
        this.setState({ branchedFrom: message });
      });
    }
  };

  getHostsContribution() {
    const { conversation, trustees } = this.state;
    const conversationHosts = Object.values(conversation.hosts || {});

    // get all conversation hosts with contribution === trusted
    const checkTrustsUser = Object.values(
      [...conversationHosts, conversation.creator] || {}
    )
      .filter((h) => h.contribution === "trusted")
      .map((h) => h.uid);

    // check if the currentuser trusts(this.state.trusts) a conversations host with contribution === open
    // finds intersection between hosts and currentuser trusts
    return intersection(
      checkTrustsUser,
      trustees.map((t) => t.uid)
    ).length;
  }

  setContribution = (trusts) => {
    const { user } = this.props;
    const { conversation } = this.state;
    const contributors = Object.values(conversation.contributors || {});

    const checkIncludes = (group) =>
      [...group, conversation.creator].map((u) => u.uid).includes(user.uid);

    const hosts = Object.values(conversation.hosts || {});

    const statusChecks = {
      open: true,
      closed: checkIncludes(contributors) || checkIncludes(hosts),
      trusted: checkIncludes(trusts) || checkIncludes(contributors),
    };

    this.setState({
      conversation,
      showInput:
        statusChecks[conversation.contribution] || this.getHostsContribution(),
      loading: false,
    });
  };

  checkContribution = (conversation) => {
    const { getTrusts, user } = this.props;

    return user.isAnonymous
      ? this.setState({ showInput: false, loading: false, conversation })
      : this.setState({ conversation }, () =>
          getTrusts(conversation.creator.uid, "trusts").then((trusts) => {
            this.setContribution(trusts);
          })
        );
  };

  getMessagePosition = (ref, message) => {
    const { lastViewed, messages, scroll } = this.state;
    const newMessage = lastViewed
      ? messages.find((m) => m.timestamp >= lastViewed)
      : null;

    if (scroll && ref && newMessage && newMessage.uid === message.uid) {
      ref.scrollIntoView();
      this.setState({ scroll: false });
    } else if (scroll && ref && !newMessage && lastViewed) {
      window.scrollBy(0, document.body.scrollHeight);
      this.setState({ scroll: false });
    } else if (scroll && !lastViewed) {
      this.setState({ scroll: false });
    }
  };

  initConversation = () => {
    const {
      user,
      conversationListener,
      messagesListener,
      match: {
        params: { status },
      },
    } = this.props;
    const { conversationId } = this.state;

    conversationListener(conversationId, (conversation) => {
      const userHasAccess =
        conversation.status === "private"
          ? Object.values(conversation.contributors || {})
              .concat(conversation.creator)
              .map((c) => c.uid)
              .includes(this.props.user.uid)
          : true;

      const conversationSatus = conversation.featured
        ? "public"
        : conversation.status;

      if (conversationSatus !== status || !userHasAccess) {
        return this.setState({ loading: false, unauthorized: true });
      }

      this.checkContribution(conversation);

      messagesListener(conversationId, (messages) => {
        this.setState({
          messages,
          conversation,
          lastViewed: conversation.views ? conversation.views[user.uid] : null,
        });
      });
    });
  };

  handleMessageClick = (message, conversationStatus) => {
    const { conversation } = this.state;
    const { route } = this.state;

    route !== "featured"
      ? this.props.history.push(
          `/${this.state.route}/conversations/${conversation.uid}/message/${message.uid}`
        )
      : this.props.history.push(
          `/public/conversations/${conversation.uid}/message/${message.uid}`
        );
  };

  handleBranchClick = (message) =>
    message.branchedFrom
      ? this.props.history.replace(
          `/conversations/${message.branchedFrom.conversation}`
        )
      : this.props.history.replace(`/conversations/${message.conversation}`);

  handleTag = (user) => {
    const taggedUsers = {
      ...this.state.taggedUsers,
      [user.uid]: user,
    };

    this.setState({
      taggedUsers,
      text: `${this.state.text.trim()} @${user.displayName
        .split(" ")
        .join("")}`,
      taggingUser: false,
    });
  };

  handleCreateConversation = ({ user, payload }) => {
    const { createConversation, handleSendMessage } = this.props;
    let conversation;

    return createConversation({ user, payload })
      .then((c) => {
        conversation = c;
        const systemMessage = conversation.branchedFrom
          ? {
              body: `${user.displayName} branched this conversation from ${
                payload.branchedFrom.body || "an image"
              }`,
              type: "branch_message",
              branchedFrom: payload.branchedFrom,
              conversation: conversation.uid,
            }
          : {
              user: "system",
              body: `${user.displayName} started this conversation`,
              type: "conversation_created_by",
              conversation: conversation.uid,
            };

        const messages = payload.title.split("\n");

        return Promise.all([
          handleSendMessage(systemMessage),

          messages.forEach((msg) => {
            if (msg.length >= 1) {
              handleSendMessage(
                {
                  body: msg.trim(),
                  conversation: conversation.uid,
                  imageURL: this.state.imageURL,
                  tagged: this.state.taggedUsers,
                },
                user
              );
            }
          }),
        ]);
      })
      .then(() =>
        this.props.history.push(
          `/${
            conversation.featured ? "public" : conversation.status
          }/conversations/${conversation.uid}`
        )
      );
  };

  handleSendMessage = (payload) => {
    const {
      text,
      conversationId: conversation,
      composeConversation,
      taggedUsers,
      isPrivate,
    } = this.state;
    const { user, handleSendMessage, sendMessage, updatedAt } = this.props;

    const messages = text.split("\n");

    const promise = composeConversation
      ? this.handleCreateConversation({
          user,
          payload: {
            ...payload,
            status: isPrivate ? "private" : "public",
            title: text.trim(),
            branchedFrom: this.state.branchedFrom || null,
          },
        })
      : Promise.all([
          messages.forEach((msg) => {
            if (msg.length >= 1) {
              handleSendMessage(
                {
                  body: msg.trim(),
                  tagged: taggedUsers,
                  conversation,
                  ...payload,
                },
                user
              );
            }
          }),
        ]);

    return promise
      .then(() => {
        if (!composeConversation) {
          messages.forEach((msg) => {
            sendMessage(
              this.state.conversation,

              {
                body: msg.trim(),
                tagged: taggedUsers,
                conversation,
                ...payload,
              },
              user
            );
          });

          // sendMessage(this.state.conversation, {
          //   body: text.trim(),
          //   tagged: taggedUsers,
          //   conversation,
          //   ...payload
          // });
        }

        this.setState({ text: "", imageURL: null });
      })
      .then(() => updatedAt(this.state.conversation));
  };

  handleAvatarClick = (userId) => {
    this.props.history.push(`/profile/${userId}`);
  };

  handleRequest = (user) => {
    const { conversation } = this.state;
    const { requestContributor } = this.props;

    requestContributor(user, conversation);
  };

  renderMessages = (messages) =>
    messages.map((m, i) => (
      <Messages
        disableActions={this.props.user.isAnonymous}
        previousMessage={messages[i - 1] || {}}
        user={this.props.user}
        message={m}
        trusts={this.state.trusts}
        handleBranchClick={this.handleBranchClick}
        handleClick={(message, conversationStatus) =>
          this.handleMessageClick(message, conversationStatus)
        }
        handleHighlight={this.props.handleHighlight}
        handleAvatarClick={this.handleAvatarClick}
        getMessagePosition={this.getMessagePosition}
      />
    ));

  renderCompose = () => {
    const { isPrivate, branchedFrom } = this.state;
    const handleToggle = (v) => this.setState({ isPrivate: !v });

    return (
      <ConversationCompose
        handleBranchClick={(m) => this.handleBranchClick(m)}
        isPrivate={isPrivate}
        handleToggle={handleToggle}
        branchedFrom={branchedFrom}
      />
    );
  };

  renderComposeInput = () => {
    const { text, inputDisabled, conversation } = this.state;
    const { user } = this.props;

    return (
      <MessageInputContainer
        className="input-container-message"
        firstMessageLen={Utils.getFirstMessage(text).length}
        isCompose
        conversation={conversation}
        showAvatar
        user={user}
        inputDisabled={
          text.length < 1 ||
          inputDisabled ||
          Utils.getFirstMessage(text).length > 200 ||
          Utils.getFirstMessage(text).length === 0
        }
        inputValue={text}
        handleTag={this.handleTag}
        handleSendMessage={this.handleSendMessage}
        handleInputChange={(t) => this.setState({ text: t })}
      />
    );
  };

  renderInput = () => {
    const {
      messages,
      text,
      inputDisabled,
      conversation,
      composeConversation,
      showInput,
    } = this.state;
    const { user, handleSendMessage } = this.props;

    const showAvatar =
      messages.length && user ? last(messages).user.uid !== user.uid : true;
    const hasRequested =
      conversation.requests && user ? conversation.requests[user.uid] : false;

    const uploadProps = {
      name: "file",
      showUploadList: false,
      accept: "image/*",
      onRemove: () => this.setState({ imageURL: null }),
      beforeUpload: () => this.setState({ inputDisabled: true }),
      customRequest: (data) => {
        const { file } = data;
        const name = `${Date.now()}_${user.uid}`;

        const uploadTask = firebase
          .storage()
          .ref()
          .child(`user_uploads/images/${user.uid}/${name}`)
          .put(file, { contentType: "image/jpeg" });

        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, {
          complete: () => {
            uploadTask.snapshot.ref.getDownloadURL().then((imageURL) => {
              handleSendMessage(
                {
                  imageURL,
                  type: "image_message",
                  conversation: conversation.uid,
                },
                user
              );
              this.setState({ inputDisabled: false });
            });
          },
        });
      },
    };

    return (
      <MessageInputRequestWall
        conversation={conversation}
        trustees={this.state.trustees}
        showAvatar={showAvatar}
        hasRequested={hasRequested}
        user={user}
        inputDisabled={text.length < 1 || inputDisabled}
        inputValue={text}
        handleTag={this.handleTag}
        handleRequest={(user) => this.handleRequest(user)}
        handleSendMessage={this.handleSendMessage}
        handleInputChange={(t) => this.setState({ text: t })}
        handleUpload={this.handleUpload}
        uploadProps={uploadProps}
        showIcons={!composeConversation}
        handleAvatarClick={this.handleAvatarClick}
      />
    );
  };

  render() {
    const {
      messages,
      conversation,
      composeConversation,
      loading,
      unauthorized,
    } = this.state;
    const { user, match, handleAuth } = this.props;
    const AnonPublicRoute = conversation.featured === false && user.isAnonymous;
    const content = composeConversation
      ? this.renderCompose()
      : this.renderMessages(messages);
    const input = composeConversation
      ? this.renderComposeInput()
      : this.renderInput();
    const loadingContent =
      !messages.length && !conversation && !composeConversation;

    if (AnonPublicRoute) {
      return <SignInForm handleAuth={handleAuth} match={match} />;
    }

    if (unauthorized) {
      return <UnAuthorized />;
    }

    return loadingContent || loading ? (
      <Loading />
    ) : (
      <div>
        {content}
        <div
          ref={(r) => {
            this.inputRef = r;
          }}
        >
          {input}
        </div>
      </div>
    );
  }
}

export default compose(
  WithConversation,
  WithMessage,
  WithUser,
  WithAnalytics
)(Conversation);
