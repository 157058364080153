import React from 'react';
import SystemMessage from './SystemMessage';
import ImageMessage from './ImageMessage';
import MessageContainer from '../container/MessageContainer';
import { withRouter } from 'react-router-dom';

import './Messages.css';

const Messages = ({
  message,
  handleClick,
  user,
  handleAvatarClick,
  showMetadata,
  handleBranchClick,
  disableActions,
  trusts,
  getMessagePosition,
  previousMessage,
}) => {
  const types = {
    branch_message: (
      <SystemMessage
        handleClick={() => handleBranchClick(message)}
        message={message}
      />
    ),
    conversation_created_by: <SystemMessage message={message} />,
    invite_contributor: <SystemMessage message={message} />,
    invite_host: <SystemMessage message={message} />,
    revoke_contributor: <SystemMessage message={message} />,
    deleted_message: <SystemMessage message={message} />,
    image_message: (
      <ImageMessage
        message={message}
        showMetadata={showMetadata}
        handleClick={handleClick}
        handleAvatarClick={handleAvatarClick}
        trusts={trusts}
      />
    ),
  };

  if (types[message.type] === null) return null;

  return types[message.type] ? (
    types[message.type]
  ) : (
    <div ref={r => getMessagePosition(r, message)}>
      <MessageContainer
        disableActions={disableActions}
        previousMessage={previousMessage}
        showMetadata={showMetadata}
        user={user}
        message={message}
        handleClick={handleClick}
        handleAvatarClick={handleAvatarClick}
        trusts={trusts}
      />
    </div>
  );
};

export default withRouter(Messages);
