import { combineReducers } from "redux";
import { slice as userAuthSlice } from "../../features/user-auth/user-auth-reducer";
import { slice as userProfileSlice } from "../../features/user-profile/user-profile-reducer";
import { slice as authModalSlice } from "../../features/modal/modal-reducer";
import { slice as onBoardingSlice } from "../../features/onboarding/onboarding-reducer";

import { reducer as userAuthReducer } from "../../features/user-auth/user-auth-reducer";
import { reducer as userProfileReducer } from "../../features/user-profile/user-profile-reducer";
import { reducer as authModalReducer } from "../../features/modal/modal-reducer";
import { reducer as onBoardingReducer } from "../../features/onboarding/onboarding-reducer";

export const reducer = combineReducers({
  [userAuthSlice]: userAuthReducer,
  [userProfileSlice]: userProfileReducer,
  [authModalSlice]: authModalReducer,
  [onBoardingSlice]: onBoardingReducer,
});

export default reducer;
