import React from "react";
import { Card, Row, Col } from "antd";
import { Avatar } from "antd";
import DisplayUtils from "../utils/DisplayUtils";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "./Conversation.css";

const statusBadge = {
  open: "📣",
  closed: "👀",
  trusted: "🦉",
};

const ConversationSummary = ({ conversation, handleClick, showImage }) => {
  const coverImage =
    showImage && conversation.featuredImage ? (
      <LazyLoadImage
        src={conversation.featuredImage} // use normal <img> attributes as props
      />
    ) : null;

  return (
    <Card
      cover={coverImage}
      className={
        conversation.status === "public"
          ? " conversation-summary public-card"
          : "conversation-summary private-card"
      }
      onClick={() => handleClick(conversation)}
    >
      <div className="featured-body">
        <h1
          className={
            conversation.status === "public"
              ? "public-card-header"
              : "private-card-header"
          }
        >
          {" "}
          <span className="conversation-title">
            {conversation.title.length > 200
              ? conversation.title.substring(0, 200) + " ..."
              : conversation.title}
          </span>
        </h1>
        <Row type="flex" align="top">
          <Col xs={5} sm={3} md={3} lg={2}>
            <Avatar
              src={conversation.creator.photoURL}
              shape="circle"
              style={{
                cursor: "pointer",
                width: "50px",
                height: "50px",
                borderRadius: "50px",
                float: "left",
              }}
            />
          </Col>

          <Col xs={16} sm={19} md={19} lg={20}>
            <div>
              <span className="conversation-title-sm">
                {conversation.title.length > 200
                  ? conversation.title.substring(0, 200) + " ..."
                  : conversation.title}
              </span>
              <span className="display-name">
                {conversation.creator.displayName} hosting{" "}
              </span>
              <span className="display-name-mobile">
                {conversation.creator.displayName} hosting{" "}
                {DisplayUtils.timeStamp(conversation.updated_at)}
              </span>
              <div>
                <span className="display-time">
                  Updated {DisplayUtils.timeStamp(conversation.updated_at)}
                </span>
              </div>
            </div>
          </Col>
          <Col xs={3} sm={2}>
            <div className="status-badge">
              {conversation.status === "public"
                ? statusBadge[conversation.contribution]
                : null}
            </div>{" "}
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default ConversationSummary;
