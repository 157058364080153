const initialState = {
  color: "grey",
  isShowing: false,
  showCount: false,
  isOverLimit: false
};

export const changeColor = ({ color = "orange", showCount = true } = {}) => ({
  type: "charCounter/changeColor",
  payload: { color, showCount }
});

export const updateCharCount = ({
  charCount,
  showCount = true,
  isShowing = true,
  color = "grey"
} = {}) => ({
  type: "charCounter/updateCharCount",
  payload: { charCount, showCount, isShowing, color }
});

export const reducer = (state = initialState, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case updateCharCount().type:
      return {
        ...state,
        charCount: payload.charCount > 200 ? "> 200" : payload.charCount,
        isShowing: payload.charCount > 0,
        showCount: payload.charCount >= 160,
        color: "grey"
      };

    case changeColor().type:
      return {
        ...state,
        color: payload.color
      };

    default:
      return state;
  }
};
