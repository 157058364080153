import React, { useEffect } from "react";
import firebase from "firebase/app";
import { Modal, Button, Space, Icon } from "antd";
import DisplayUtils from "../../utils/DisplayUtils";

const withAuth = (Component) => (props) => {
  const { initializeAuth } = props;
  //initialize the authentication reducer
  useEffect(() => {
    initializeAuth();
  }, [initializeAuth]);

  useEffect(() => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      var email = window.localStorage.getItem("emailForSignIn");

      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:

        if (DisplayUtils.getMobileOP()) {
          Modal.warning({
            title: "",
            content:
              "You seem to be on a smartphone! If you're trying to log in, you should probably go back to your computer.",
          });
        } else {
          email = window.prompt("Please provide your email for confirmation");
        }
      }

      props.signInWithEmailLinkVerified({
        email: email,
        location: window.location.href,
      });
    }
  }, []);

  useEffect(() => {
    if (props.authError.code === "auth/invalid-action-code") {
      const message =
        "You clicked a link in an email. It wasn't the right one. This can happen if it's expired or has already been used. You can check your inbox for a more recent email or start over.";
      warning(message);
    }
  }, [props.authError]);

  useEffect(() => {
    if (props.authError.code === "auth/invalid-email") {
      warning(props.authError.message);
    }
  }, [props.authError]);

  function warning(message) {
    Modal.warning({
      icon: <Icon type="question-circle" />,
      title: "Something didn't work! 🐞",
      content: <div>{message}</div>,
      onOk() {},
    });
  }

  return <Component {...props} />;
};

export default withAuth;
