/* eslint-disable */

import { useReducer, useEffect, useState } from "react";
import * as firebase from "firebase";

import {
  reducer,
  initialState,
  setConvoKeys,
  setConversations,
  setLastKnownKey,
} from "../reducers/convo-queries-reducer";

const UseConvoQueries = () => {
  const [convoQueryState, dispatch] = useReducer(reducer, initialState);

  const {
    conversations,
    convoKeys,
    shouldFetch,
    lastKnownKey,
    conversationsObject,
    amount,
  } = convoQueryState;

  const setConversationsReducer = () => dispatch(setConversations());
  const setLastKnownKeyReducer = (conversations) =>
    dispatch(setLastKnownKey(conversations));

  // const setScreenReducer = (s) => dispatch(setScreen({ screen: s }));

  const setConvoKeysReducer = (conversations, s) =>
    dispatch(setConvoKeys({ convos: conversations, screen: s }));

  useEffect(() => {
    if (convoKeys.length > 0) {
      setConversationsReducer(convoKeys.length);
    }
  }, [convoKeys]);

  useEffect(() => {
    if (shouldFetch === false) {
      setLastKnownKeyReducer(conversations);
    }
  }, [shouldFetch]);

  const conversationRef = (id = "") =>
    firebase.database().ref(`conversations/${id}`);

  const screenIsPrivate = (s) => s === "private";

  const queryConvos = (
    key = null,
    screen = { index: "featured", value: true }
  ) => {
    const getQuery = (key) =>
      key === null
        ? conversationRef()
            .orderByChild(screen.index)
            .equalTo(screen.value)
            .limitToLast(10)
        : conversationRef()
            .orderByChild(screen.index)
            .equalTo(screen.value)
            .limitToLast(amount);

    let query = screenIsPrivate(screen.value)
      ? conversationRef().orderByChild(screen.index).equalTo(screen.value)
      : getQuery(key);

    query.on("value", (convosnapshot) => {
      setConvoKeysReducer(convosnapshot.val(), screen);
    });
  };

  return {
    conversations,
    convoQueryState,
    queryConvos,
    lastKnownKey,
    convoKeys,
    conversationsObject,
    shouldFetch,
  };
};

UseConvoQueries.propTypes = {
  //   setMessageEditing: PropTypes.func.isRequired,
  //   setEdits: PropTypes.func.isRequired,
  //   updateMessageInDb: PropTypes.func.isRequired,
};

export default UseConvoQueries;
