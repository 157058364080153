import "antd/dist/antd.css";
import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Conversations from "./container/Conversations";
import Conversation from "./container/Conversation";
import ConversationDetailContainer from "./container/ConversationDetailContainer";
import SignInForm from "./container/SignInForm";
import DefaultLayout from "./component/DefaultLayout";
import ScrollToTop from "./utils/ScrollToTop";
import MessageDetailContainer from "./container/MessageDetailContainer";
import MemberDirectoryContainer from "./container/MemberDirectoryContainer";
import UserProfileContainer from "./container/UserProfileContainer";
import ConversationContributorsContainer from "./container/ConversationContributorsContainer";
import FeaturedImagesContainer from "./container/FeaturedImageContainer";

const routes = ({
  user,
  handleSignIn,
  handleSignOut,
  toggleModal,
  signOut,
  isSignedIn,
}) => {
  const renderRoute = (component) => (props) => (
    <DefaultLayout
      Component={component}
      {...props}
      route={props}
      toggleModal={() => toggleModal()}
      signOut={() => signOut()}
      user={user}
      isSignedIn={isSignedIn}
      handleSignOut={handleSignOut}
      handleSignIn={handleSignIn}
    />
  );

  const protectedRoute = (predicate) => (component) =>
    predicate ? renderRoute(SignInForm) : renderRoute(component);

  const authenticatedRoute = protectedRoute(user.isAnonymous);

  const adminRoute = protectedRoute(user.role !== "admin");

  return (
    <Router>
      <Switch>
        <ScrollToTop>
          <div>
            <Route
              name="home"
              exact
              path="/"
              render={({ history }) => {
                history.replace("/featured");
                return null;
              }}
            />
            <Route
              name="conversations.public"
              exact
              path="/public"
              render={authenticatedRoute(Conversations)}
            />
            <Route
              name="conversations.private"
              exact
              path="/private"
              render={authenticatedRoute(Conversations)}
            />
            <Route
              name="conversations.featured"
              exact
              path="/featured"
              render={renderRoute(Conversations)}
            />
            <Route
              name="conversations.compose"
              exact
              path="/compose"
              render={authenticatedRoute(Conversation)}
            />
            <Route
              name="conversation.status"
              exact
              path="/:status/conversations/:conversationId"
              render={renderRoute(Conversation)}
            />
            <Route
              name="conversations.compose.branch"
              exact
              path="/compose/branchedFrom/:messageId"
              render={renderRoute(Conversation)}
            />
            <Route
              name="conversation"
              exact
              path="/conversations/:conversationId"
              render={renderRoute(Conversation)}
            />
            <Route
              name="UserProfile"
              exact
              path="/profile/:userId"
              render={renderRoute(UserProfileContainer)}
            />
            <Route
              name="conversation.detail"
              exact
              path="/:status/conversations/:conversationId/detail"
              render={authenticatedRoute(ConversationDetailContainer)}
            />
            <Route
              name="conversation.detail.members"
              exact
              path="/:status/conversations/:conversationId/detail/:memberType"
              render={authenticatedRoute(MemberDirectoryContainer)}
            />
            <Route
              name="conversation.detail.members.edit"
              exact
              path="/:status/conversations/:conversationId/detail/:action/edit"
              render={authenticatedRoute(ConversationContributorsContainer)}
            />
            <Route
              name="conversation.detail.members.invite"
              exact
              path="/:status/conversations/:conversationId/detail/:action/invite"
              render={authenticatedRoute(MemberDirectoryContainer)}
            />
            <Route
              name="conversation.detail.featuredimage"
              exact
              path="/:status/conversations/:conversationId/featuredimage"
              render={adminRoute(FeaturedImagesContainer)}
            />

            <Route
              name="conversation.message.detail"
              exact
              path="/:status/conversations/:conversationId/message/:messageId"
              render={renderRoute(MessageDetailContainer)}
            />
            <Route name="signin" exact path="/signin" component={SignInForm} />
            <Route
              name="members"
              exact
              path="/members"
              render={authenticatedRoute(MemberDirectoryContainer)}
            />
            <Route
              name="user.trusts"
              exact
              path="/profile/:userId/:trustType"
              render={authenticatedRoute(MemberDirectoryContainer)}
            />
          </div>
        </ScrollToTop>
      </Switch>
    </Router>
  );
};

routes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default routes;
