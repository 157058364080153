import React from 'react';
import PropTypes from 'prop-types';
import MemberStamp from './MemberStamp';
import { Right, Left } from '../utils/Monads';
import { prop, map, compose, includes, isEqual } from 'lodash/fp';
import { Card, List, Switch, Row, Col, Button } from 'antd';
import Avatar from './Avatar';

const ConversationContributors = ({
  user,
  userIsCreator,
  conversation,
  handleClick,
  handleUpdateStatus,
  handleUpdateUserStatus,
  handleRequest,
  handleAcceptRequest,
  handleRevoke,
  handleInvite,
}) => {
  const isCreator = compose(isEqual(user.uid), prop('uid'), prop('creator'));

  const requestedHost = compose(
    includes(user.uid),
    map(prop('uid')),
    prop('hostRequests'),
  );

  const requestedContributor = compose(
    includes(user.uid),
    map(prop('uid')),
    prop('requests'),
  );

  const isContributor = compose(
    includes(user.uid),
    map(prop('uid')),
    prop('contributors'),
  );

  const isHost = compose(includes(user.uid), map(prop('uid')), prop('hosts'));

  const cohostButtonDisabled = c => isHost(c) || requestedHost(c);
  const contributorButtonDisabled = c =>
    requestedContributor(c) || (isContributor(c) && !isHost(c));
  const canInvite = c => isCreator(c) || isHost(c);

  const invitationText = {
    open: 'Open to everyone',
    trusted: `Open to members ${
      userIsCreator ? 'you trust' : 'the host trusts'
    }`,
  };

  const actions = (text, action) =>
    isCreator(conversation) || isHost(conversation) ? (
      <Button type="primary" size="small" onClick={action}>
        {text}
      </Button>
    ) : null;

  const statusChange = type => (
    <Row style={styles.statusContainer}>
      <Col>
        <p style={styles.statusMargin}>{invitationText[type]}</p>
      </Col>
      <Col sm={2} style={styles.switch}>
        <Switch
          size="small"
          disabled={conversation.status === 'private' || !userIsCreator}
          checked={conversation.contribution === type}
          onChange={v => handleUpdateStatus(type, v)}
        />
      </Col>
    </Row>
  );

  const roleText = {
    cohost: 'is a cohost',
    hostRequest: 'has requested to be a cohost',
    contributor: 'is a contributor',
    contribRequest: 'has requested to be a contributor',
  };

  const allHosts = Object.values(conversation.hosts || {});

  const creatorText = convo =>
    (isCreator(convo)
      ? Right('You are the host')
      : Left(`${convo.creator.displayName} is the host`)
    ).fold(
      left => `${left}`,
      right => `${right}`,
    );

  return (
    <div>
      <Card title="Host">
        <div
          style={styles.memberContainer}
          onClick={() => handleClick(conversation.creator)}
        >
          <Avatar imageURL={conversation.creator.photoURL} size="small" />
          <p style={styles.memberName}>{creatorText(conversation)}</p>
        </div>
        {['open', 'trusted'].map(statusChange)}
      </Card>
      <Card
        title="Cohosts"
        style={styles.container}
        extra={
          <Button
            id={canInvite(conversation) ? 'cohost' : 'host'}
            type="primary"
            size="small"
            disabled={cohostButtonDisabled(conversation)}
            onClick={() =>
              canInvite(conversation)
                ? handleInvite('host')
                : handleRequest('host')
            }
          >
            {canInvite(conversation)
              ? 'Add a cohost'
              : 'Request to be a cohost'}
          </Button>
        }
      >
        <List
          style={{ fontFamily: 'Roboto', fontWeight: 400 }}
          dataSource={allHosts}
          renderItem={c => (
            <List.Item
              actions={
                isCreator(conversation)
                  ? [
                      <Button
                        type="primary"
                        size="small"
                        onClick={() => handleRevoke(c, 'host')}
                      >
                        Revoke
                      </Button>,
                    ]
                  : isHost(conversation) && c.uid === user.uid
                  ? [
                      <Button
                        type="primary"
                        size="small"
                        onClick={() => handleRevoke(c, 'host')}
                      >
                        Quit
                      </Button>,
                    ]
                  : null
              }
            >
              <List.Item.Meta
                title={
                  <MemberStamp
                    showDisplayName={user.uid !== c.uid}
                    roleText={
                      user.uid === c.uid ? 'are a cohost' : roleText.cohost
                    }
                    isCurrentUser={user.uid === c.uid}
                    user={c}
                    handleClick={handleClick}
                  />
                }
                description={
                  <Row style={styles.statusContainer}>
                    <Col>
                      {
                        <p style={styles.statusMargin}>
                          Open to members{' '}
                          {user.uid === c.uid
                            ? 'you trust'
                            : 'this cohost trusts'}
                        </p>
                      }
                    </Col>
                    <Col sm={2} style={styles.switch}>
                      <Switch
                        size="small"
                        disabled={!userIsCreator}
                        checked={
                          conversation.hosts[c.uid].contribution === 'trusted'
                        }
                        onChange={v =>
                          handleUpdateUserStatus(c.uid, 'closed', v)
                        }
                      />
                    </Col>
                  </Row>
                }
              />
            </List.Item>
          )}
          locale={{ emptyText: 'No cohosts' }}
        />
        <hr />
        <List
          style={{ fontFamily: 'Roboto', fontWeight: 400 }}
          dataSource={Object.values(conversation.hostRequests || {})}
          renderItem={c => (
            <List.Item
              actions={[
                actions('Approve', () => handleAcceptRequest(c, 'host')),
              ]}
            >
              <MemberStamp
                showDisplayName={user.uid !== c.uid}
                roleText={
                  user.uid === c.uid
                    ? 'have requested to be a cohost'
                    : roleText.hostRequest
                }
                user={c}
                handleClick={handleClick}
              />
            </List.Item>
          )}
          locale={{ emptyText: 'No requests' }}
        />
      </Card>

      <Card
        title="Other contributors"
        style={styles.container}
        extra={
          <Button
            className={canInvite(conversation) ? 'cohost' : 'contributor'}
            type="primary"
            size="small"
            disabled={contributorButtonDisabled(conversation)}
            onClick={() =>
              canInvite(conversation)
                ? handleInvite('contributors')
                : handleRequest()
            }
          >
            {canInvite(conversation)
              ? 'Add a contributor'
              : 'Request to be a contributor'}
          </Button>
        }
      >
        <List
          style={{ fontFamily: 'Roboto', fontWeight: 400 }}
          dataSource={Object.values(conversation.contributors || {})}
          renderItem={c => (
            <List.Item actions={[actions('Revoke', () => handleRevoke(c))]}>
              <MemberStamp
                showDisplayName={user.uid !== c.uid}
                roleText={
                  user.uid === c.uid
                    ? 'are a contributor'
                    : roleText.contributor
                }
                PersonalInvitations
                user={c}
                handleClick={handleClick}
              />
            </List.Item>
          )}
          locale={{ emptyText: 'No contributors' }}
        />
        <hr />
        <List
          style={{ fontFamily: 'Roboto', fontWeight: 400 }}
          dataSource={Object.values(conversation.requests || {})}
          renderItem={c => (
            <List.Item
              actions={
                (conversation.hosts || {})[user.uid]
                  ? [
                      <Button
                        type="primary"
                        size="small"
                        onClick={() => handleAcceptRequest(c, '')}
                      >
                        Approve
                      </Button>,
                    ]
                  : [actions('Approve', () => handleAcceptRequest(c, ''))]
              }
            >
              <MemberStamp
                showDisplayName={user.uid !== c.uid}
                roleText={
                  user.uid === c.uid
                    ? 'have requested to be a contributor'
                    : roleText.contribRequest
                }
                user={c}
                handleClick={handleClick}
              />
            </List.Item>
          )}
          locale={{ emptyText: 'No requests' }}
        />
      </Card>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    marginTop: '20px',
  },
  statusContainer: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    display: 'flex',
    flexDirection: 'row',
  },
  switch: {
    justifyContent: 'flex-end',
    marginLeft: '10px',
  },
  memberContainer: {
    display: 'flex',
  },
  memberName: {
    fontWeight: 'bold',
    fontSize: '18px',
    marginBottom: '0px',
    marginLeft: '20px',
    alignSelf: 'center',
  },
  statusMargin: {
    marginLeft: '70px',
  },
};

ConversationContributors.propTypes = {
  user: PropTypes.object.isRequired,
  userIsCreator: PropTypes.bool,
  conversation: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleUpdateUserStatus: PropTypes.func.isRequired,
  handleUpdateStatus: PropTypes.func.isRequired,
  handleRequest: PropTypes.func.isRequired,
  handleAcceptRequest: PropTypes.func.isRequired,
  handleRevoke: PropTypes.func.isRequired,
  handleInvite: PropTypes.func.isRequired,
};

ConversationContributors.defaultProps = {
  userIsCreator: false,
};

export default ConversationContributors;
