import React from "react";
import { Row, Col } from "antd";
import DisplayUtils from "../utils/DisplayUtils";
import { withRouter } from "react-router";
import Avatar from "./Avatar";

const MessageActivityItem = ({
  type,
  status,
  user,
  timestamp,
  history,
  handleAvatarClick,
}) => {
  switch (type) {
    case "like":
      return (
        <Row
          style={{
            backgroundColor: "white",
            marginTop: 10,
            padding: 10,
            display: "flex",
            alignItems: "center",
          }}
          justify="end"
        >
          <Col xs={3} md={1}>
            <Avatar
              imageURL={user.photoURL}
              size="extrasmall"
              handleClick={() => handleAvatarClick(user.uid)}
            />
          </Col>
          <Col xs={21} md={23}>
            <span className="highlight-message">
              {user.displayName} highlighted this message{"  "}
              {DisplayUtils.timeStamp(timestamp)}
            </span>
          </Col>
        </Row>
      );
      /* eslint-disable no-unreachable */
      break;
    /* eslint-enable */

    case "branch":
      if (status === "private") return null;

      return (
        <Row
          style={{
            backgroundColor: "white",
            marginTop: 10,
            padding: 10,
            display: "flex",
          }}
        >
          <Col xs={3} md={1}>
            <Avatar
              imageURL={user.photoURL}
              size="extrasmall"
              handleClick={() => handleAvatarClick(user.uid)}
            />
          </Col>
          <Col xs={21} md={23}>
            <span className="highlight-message">
              {user.displayName} Branched this{"  "}
              {DisplayUtils.timeStamp(timestamp)}
            </span>
          </Col>
        </Row>
      );
      /* eslint-disable no-unreachable */
      break;
    /* eslint-enable */

    default:
      return null;
      /* eslint-disable no-unreachable */
      break;
    /* eslint-enable */
  }
};

export default withRouter(MessageActivityItem);
