import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "lodash/fp";
import { withRouter } from "react-router";

import UnAuthorized from "../component/Unauthorized";
import WithMessage from "../data/WithMessage";
import WithConversation from "../data/WithConversation";
import MessageActivityItem from "../component/MessageActivityItem";
import MessageDetail from "../component/MessageDetail";
import Loading from "../component/Loading";

class MessageDetailContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      messageId: props.match.params.messageId,
      conversationId: props.match.params.conversationId,
      message: null,
      conversation: null,
      activity: [],
      unauthorized: false,
    };
  }

  componentWillMount() {
    const {
      messageListener,
      conversationListener,
      activityListener,
      user,
      match: {
        params: { status },
      },
    } = this.props;
    const { messageId, conversationId } = this.state;

    const userHasAccess = (conversation) =>
      conversation.status === "private"
        ? Object.values(conversation.contributors || {})
            .concat(conversation.creator)
            .map((c) => c.uid)
            .includes(user.uid)
        : true;

    conversationListener(conversationId, (conversation) => {
      if (
        (conversation.status === "private" && status !== "private") ||
        !userHasAccess(conversation)
      ) {
        return this.setState({ unauthorized: true });
      }

      return this.setState({ conversation });
    });

    messageListener(messageId, (message) => {
      this.setState({ message });
    });

    activityListener(messageId, (activity) => {
      this.setState({ activity: Object.values(activity || {}) });
    });
  }

  systemMessage = (conversation, message) => ({
    icon: `${conversation.status === "public" ? "🍿" : "🔑"}`,
    body: `${message.user.displayName} sent this to`,
    conversationTitle: ` ${conversation.title}`,
    type: "conversation",
  });

  handleLinkClick = () => navigator.clipboard.writeText(document.location.href);

  handleSystemMessageClick = () => {
    const { conversation } = this.state;
    this.props.history.push(
      `/${
        conversation.featured ? "public" : conversation.status
      }/conversations/${conversation.uid}`
    );
  };

  handleAvatarClick = (userId) => {
    this.props.history.push(`/profile/${userId}`);
  };

  handleDeleteMessage = () => {
    const { messageId, message } = this.state;
    this.props.handleDeleteMessage(messageId, message);
  };

  handleHighlightClick = () => {
    const { message, messageId } = this.state;

    const highlighted = message.likes
      ? message.likes.hasOwnProperty(this.props.user.uid)
      : false;

    this.props.handleHighlight(messageId, this.props.user, highlighted);
  };

  handleBranchClick = () => {
    this.props.history.replace(
      `/compose/branchedFrom/${this.state.message.uid}`,
      {
        branchedFrom:
          this.state.message.type === "image_message"
            ? { ...this.state.message, body: "an image" }
            : this.state.message,
      }
    );
  };

  renderActivityItem = (activity) =>
    activity.map((a) => (
      <MessageActivityItem
        type={a.type}
        status={a.status}
        user={a.user}
        timestamp={a.timestamp}
        handleAvatarClick={(userId) => this.handleAvatarClick(userId)}
      />
    ));

  render() {
    const { message, conversation, activity, user, unauthorized } = this.state;

    if (unauthorized) {
      return <UnAuthorized />;
    }
    const canDeleteMessage = (user) =>
      user.uid === message.user.uid || user.role === "admin";

    return message && conversation ? (
      <div>
        <MessageDetail
          user={user}
          message={message}
          conversation={conversation}
          handleAvatarClick={this.handleAvatarClick}
          handleSystemMessageClick={this.handleSystemMessageClick}
          disabled={user.isAnonymous || message.type === "deleted_message"}
          canDelete={canDeleteMessage(user)}
          handleDeleteClick={this.handleDeleteMessage}
          handleHighlightClick={this.handleHighlightClick}
        />

        {this.renderActivityItem(activity)}
      </div>
    ) : (
      <Loading />
    );
  }
}

MessageDetailContainer.propTypes = {
  user: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  handleHighlight: PropTypes.func.isRequired,
  handleDeleteMessage: PropTypes.func.isRequired,
  messageListener: PropTypes.func.isRequired,
  activityListener: PropTypes.func.isRequired,
  conversationListener: PropTypes.func.isRequired,
};

export default compose(
  WithConversation,
  WithMessage,
  withRouter
)(MessageDetailContainer);
