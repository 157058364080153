import React, { Component } from 'react';
import { compose } from 'lodash/fp';
import '../component/Messages.css';
import WithUser from '../data/WithUser';
import UserBio from '../component/UserBio';

class UserBioContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            editing: false,
        };
    }

    handleEdit = () => {
        this.setState({ editing: true });
    };

    onChange = e => {
        this.setState({ text: e.target.value });
    };

    handleSave = () => {
        const { saveBio } = this.props;
        saveBio(this.props.user.uid, this.state.text || '').then(() =>
            this.setState({ editing: false, text: '' }));
    };

    render() {
        return (
            <UserBio
                {...this.props}
                value={this.state.text}
                handleSave={this.handleSave}
                handleEdit={this.handleEdit}
                editing={this.state.editing}
                onChange={e => {
                    this.onChange(e);
                }}
            />
        );
    }
}

export default compose(WithUser)(UserBioContainer);
