import React, { Component } from "react";
import PropTypes from "prop-types";
import WithUser from "../data/WithUser";
import WelcomeMat from "../component/WelcomeMat";

class WelcomeMatContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      featured: [],
      friends: [],
    };
  }
  componentWillMount() {
    const { getFeaturedUsers } = this.props;
    // getTwitterFriends(user)
    return Promise.all([getFeaturedUsers()]).then(([featured, friends]) =>
      this.setState({ featured, friends, loading: false })
    );
  }
  handleClick = (user) => {
    const { history } = this.props;
    return user
      ? history.push(`/profile/${user.uid}`)
      : history.push("/members");
  };
  handleAllMembers = () => this.props.history.push("/members");
  render() {
    const { loading, featured, friends } = this.state;
    const { isSignedIn } = this.props;

    return (
      <WelcomeMat
        isSignedIn={isSignedIn}
        loading={loading}
        featured={featured}
        friends={friends}
        handleClick={this.handleClick}
        handleAllMembers={this.handleAllMembers}
      />
    );
  }
}

WelcomeMatContainer.propTypes = {
  user: PropTypes.object.isRequired,
  getFeaturedUsers: PropTypes.func.isRequired,
  getTwitterFriends: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default WithUser(WelcomeMatContainer);
