import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import withAuth from "./with-auth";
import {
  initializeAuth,
  getIsSignedIn,
  getEmailLinkSent,
  signinAnon,
  signInWithEmailLinkVerified,
  signOut,
  getAuthError,
  getIsLoading,
} from "../../features/user-auth/user-auth-reducer";
import {
  getUser,
  initializeUserProfile,
} from "../../features/user-profile/user-profile-reducer";

const mapStateToProps = (state) => ({
  isSignedIn: getIsSignedIn(state),
  user: getUser(state),
  linkSent: getEmailLinkSent(state),
  authError: getAuthError(state),
  isLoading: getIsLoading(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      initializeAuth,
      signinAnon,
      signInWithEmailLinkVerified,
      signOut,
      initializeUserProfile,
    },
    dispatch
  );

const connectedComponent = connect(mapStateToProps, mapDispatchToProps);

const composedAuthComponent = (Component) =>
  compose(connectedComponent, withAuth)(Component);

export default composedAuthComponent;
