import React, { useState, useRef, useCallback } from "react";
export function useThunkReducer(reducer, initialArg, init = (a) => a) {
  const [hookState, setHookState] = useState(init(initialArg));

  // State management.
  const state = useRef(hookState);
  const getState = useCallback(() => state.current, [state]);
  const setState = useCallback(
    (newState) => {
      state.current = newState;
      setHookState(newState);
      return Promise.resolve(newState);
    },
    [state, setHookState]
  );

  // Reducer.
  const reduce = useCallback(
    (action) => {
      return reducer(getState(), action);
    },
    [reducer, getState]
  );

  // Augmented dispatcher.
  const dispatch = useCallback(
    (action) => {
      return typeof action === "function"
        ? action(dispatch, getState)
        : setState(reduce(action));
    },
    [getState, setState, reduce]
  );

  return [hookState, dispatch];
}
