import React from "react";
import OnboardingModal from "./OnboardingModal";

// eslint-disable-next-line react/display-name
const WithOnBoarding = (Component) => (props) => {
  return (
    <>
      <OnboardingModal {...props} />
      <Component {...props} />
    </>
  );
};

export default WithOnBoarding;
