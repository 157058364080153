import { includes, keys, compose, get } from "lodash/fp";
import React from "react";
import * as firebase from "firebase";

//CONVERT THIS TO FUNCTION COMPONENT
const WithConversation = (Component) => (props) => {
  // super(props);
  const messagesActivityRef = (id = "") =>
    firebase.database().ref(`messages_activity/${id}`);
  // const messageRef = (id = "") => firebase.database().ref(`messages/${id}`);
  const conversationRef = (id = "") =>
    firebase.database().ref(`conversations/${id}`);
  const rootRef = () => firebase.database().ref();

  const getConversation = (id = "") =>
    conversationRef(id)
      .once("value")
      .then((cs) => cs.val());

  // ---- LISTENER IS NEEDED FOR USEPROFILE SCREEN --- //
  const checkPermission = (user) => (conversationsSnapshot) =>
    Object.values(conversationsSnapshot.val() || {}).filter((c) => {
      const { creator, contributors, status } = c;

      return status === "private"
        ? Object.keys(contributors || {}).indexOf(user.uid) >= 0 ||
            creator.uid === user.uid
        : true;
    });

  const conversationListener = (id, callback) =>
    id
      ? conversationRef(id).on("value", (cs) => callback(cs.val()))
      : callback(null);

  const conversationsListener = (user, filter, callback) => {
    const { index, value } = filter;
    const checkUserPermissions = checkPermission(user);

    conversationRef()
      .orderByChild(index)
      .equalTo(value)
      .on("value", (cs) => callback(checkUserPermissions(cs)));
  };

  // ---- LISTENER IS NEEDED FOR USEPROFILE SCREEN --- //

  const updatePaths = (conversation, path) => {
    const urls = [
      `/conversations/${conversation.uid}/${path}`,
      `/users_conversations/${conversation.creator.uid}/${conversation.uid}/${path}`,
    ];

    Object.values(conversation.contributors || {}).forEach((user) => {
      urls.push(`/users_conversations/${user.uid}/${conversation.uid}/${path}`);
    });

    return urls;
  };

  const userIsFollower = (userId) =>
    compose(includes(userId), keys, get("followers"));

  // handle feature

  const handleFollowConversation = (user, conversation, action) => {
    const payload = action === "follow" ? user : null;
    const updates = {};

    updatePaths(conversation, `followers/${user.uid}`).forEach((path) => {
      updates[path] = payload;
    });

    return firebase.database().ref().update(updates);
  };

  const handleFeatureConversation = (conversation) => {
    const payload = conversation.featured ? null : true;
    const updates = {};

    // TODO: handle fetch latest image
    // updates[`/conversations/${conversation.uid}/featuredImage`] = latestImageMessage;

    updates[`/conversations/${conversation.uid}/featured`] = payload;

    updatePaths(conversation, "featured").forEach((path) => {
      updates[path] = payload;
    });

    // TODO handle system message
    return firebase.database().ref().update(updates);
  };

  const createSystemMessage = (payload) => {
    const messageRef = firebase.database().ref("messages").push();
    const message = {
      ...payload,
      timestamp: firebase.database.ServerValue.TIMESTAMP,
      user: "system",
      uid: messageRef.key,
      type: payload.type || null,
    };
    return messageRef.set(message);
  };

  const createConversation = ({ user, payload }) => {
    // set the conversation ref
    const newconversationsRef = conversationRef().push();
    const uid = newconversationsRef.key;
    const followers = {};
    followers[user.uid] = user;
    // construct the conversation payload
    //
    const messages = payload.title.split("\n");
    const conversation = {
      ...payload,
      title:
        messages.length > 1
          ? messages.shift()
          : payload.title || "New Conversation",
      status: payload.status || "private",
      branch: payload.branchedFrom ? true : null,
      featured: payload.featured || false,
      timestamp: firebase.database.ServerValue.TIMESTAMP,
      creator: user,
      followers,
      updated_at: firebase.database.ServerValue.TIMESTAMP,
      contribution: payload.status === "private" ? "closed" : "trusted",
      uid,
    };

    const activityRef = messagesActivityRef().push();
    const activityId = activityRef.key;

    const updates = {};
    updates[`/conversations/${uid}`] = conversation;
    updates[`users_conversations/${user.uid}/${uid}`] = conversation;
    updates[`users_conversations_following/${user.uid}/${uid}`] = conversation;
    if (payload.branchedFrom !== null) {
      updates[
        `/messages/${payload.branchedFrom.uid}/branches/${uid}`
      ] = payload.branchedFrom ? conversation : null;
      updates[`/messages_activity/${payload.branchedFrom.uid}/${activityId}`] =
        payload.status === "public"
          ? {
              type: "branch",
              user,
              conversation,
              timestamp: firebase.database.ServerValue.TIMESTAMP,
              message: payload.branchedFrom.uid,
              uid: activityId,
            }
          : null;
    }

    return rootRef()
      .update(updates)
      .then(() => conversation);
  };

  const removeHost = (user, conversationId) =>
    getConversation(conversationId).then((conversation) => {
      const updates = {};
      const systemMessage = {
        body: `${conversation.creator.displayName} revoked the invitation for ${user.displayName} to cohost`,
        conversation: conversation.uid,
        type: "revoke_contributor",
      };

      updates[`/conversations/${conversation.uid}/hosts/${user.uid}`] = null;
      updates[
        `/conversations/${conversation.uid}/hostRequests/${user.uid}`
      ] = null;

      return rootRef()
        .update(updates)
        .then(() => createSystemMessage(systemMessage));
    });

  const removeContributor = (user, conversationId) =>
    getConversation(conversationId).then((conversation) => {
      const updates = {};
      const systemMessage = {
        body: `${conversation.creator.displayName} revoked the invitation for ${user.displayName} to contribute`,
        conversation: conversation.uid,
        type: "revoke_contributor",
      };

      updates[
        `/conversations/${conversation.uid}/contributors/${user.uid}`
      ] = null;
      updates[`/conversations/${conversation.uid}/requests/${user.uid}`] = null;

      return rootRef()
        .update(updates)
        .then(() => createSystemMessage(systemMessage));
    });

  const requestContributor = (user, conversation) => {
    const updates = {};

    updates[`/conversations/${conversation.uid}/requests/${user.uid}`] = user;

    return rootRef().update(updates);
  };

  const requestHost = (user, conversation) => {
    const updates = {};

    updates[
      `/conversations/${conversation.uid}/hostRequests/${user.uid}`
    ] = user;

    return rootRef().update(updates);
  };

  const addHost = (user, conversationId) =>
    getConversation(conversationId).then((conversation) => {
      const updates = {};

      const systemMessage = {
        body: `${conversation.creator.displayName} invited ${user.displayName} to cohost`,
        conversation: conversation.uid,
        type: "invite_host",
      };

      const hostData = {
        uid: user.uid,
        displayName: user.displayName,
        contribution: "trusted",
      };

      updates[
        `/conversations/${conversation.uid}/hosts/${user.uid}`
      ] = hostData;
      updates[
        `/conversations/${conversation.uid}/followers/${user.uid}`
      ] = user;
      updates[
        `/conversations/${conversation.uid}/hostRequests/${user.uid}`
      ] = null;

      return rootRef()
        .update(updates)
        .then(() => createSystemMessage(systemMessage));
    });

  const addContributor = (user, conversationId) =>
    getConversation(conversationId).then((conversation) => {
      const updates = {};
      const systemMessage = {
        body: `${conversation.creator.displayName} invited ${user.displayName} to contribute`,
        conversation: conversation.uid,
        type: "invite_contributor",
      };

      updates[
        `/conversations/${conversation.uid}/contributors/${user.uid}`
      ] = user;
      updates[
        `/conversations/${conversation.uid}/followers/${user.uid}`
      ] = user;
      updates[`/conversations/${conversation.uid}/requests/${user.uid}`] = null;

      return rootRef()
        .update(updates)
        .then(() => createSystemMessage(systemMessage));
    });

  const updateConversation = (conversation, path, value) => {
    const updates = {};

    updates[`/conversations/${conversation.uid}/${path}`] = value;

    return rootRef()
      .update(updates)
      .then(() => conversation);
  };

  const updatedAt = (conversation) =>
    updateConversation(
      conversation,
      "updated_at",
      firebase.database.ServerValue.TIMESTAMP
    );

  const featureImage = ({ conversation, imageURL }) => {
    const updates = {};

    updates[`/conversations/${conversation.uid}/featuredImage`] = imageURL;

    return rootRef()
      .update(updates)
      .then(() => conversation);
  };

  const recordView = (conversation, userId) => {
    const updates = {};

    updatePaths(conversation, `views/${userId}`).forEach((path) => {
      updates[path] = firebase.database.ServerValue.TIMESTAMP;
    });

    return rootRef().update(updates);
  };

  return (
    <div id="conversations">
      <Component
        {...props}
        recordView={recordView}
        updatedAt={updatedAt}
        requestContributor={requestContributor}
        requestHost={requestHost}
        featureImage={featureImage}
        updateConversation={updateConversation}
        removeContributor={removeContributor}
        removeHost={removeHost}
        addHost={addHost}
        addContributor={addContributor}
        getConversation={getConversation}
        createConversation={createConversation}
        userIsFollower={userIsFollower}
        conversationListener={conversationListener}
        conversationsListener={conversationsListener}
        // conversations={conversations}
        handleFollowConversation={handleFollowConversation}
        handleFeatureConversation={handleFeatureConversation}
      />
    </div>
  );
};

export default WithConversation;
