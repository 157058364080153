import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import withAuthModal from "./with-auth-modal";
import {
  getIsOpen,
  toggleModal,
  updateEmailInput,
} from "../../features/modal/modal-reducer";

import {
  signInWithEmailLink,
  getEmailLinkSent,
  getEmail,
} from "../../features/user-auth/user-auth-reducer";

const mapStateToProps = (state) => ({
  isOpen: getIsOpen(state),
  linkSent: getEmailLinkSent(state),
  email: getEmail(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { toggleModal, updateEmailInput, signInWithEmailLink },
    dispatch
  );

const connectedComponent = connect(mapStateToProps, mapDispatchToProps);

const composedAuthComponent = (Component) =>
  compose(connectedComponent, withAuthModal)(Component);

export default composedAuthComponent;
