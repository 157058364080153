import * as firebase from "firebase";

const Initialize = {
  initApp: () =>
    firebase.initializeApp({
      apiKey: process.env.REACT_APP_FB_API_KEY,
      authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FB_DB_URL,
      projectId: process.env.REACT_APP_FB_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FB_MESSAGE_SENDER_ID,
    }),
};

export default Initialize;
