import {
  all,
  call,
  put,
  take,
  fork,
  takeEvery,
  select,
} from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import {
  signinAnonFB,
  emailLinkSignin,
  lookupUserFromDB,
  createNewUserInDB,
  signinWithEmail,
  signoutFB,
} from "./user-auth-methods";
import { toggleModal, getIsOpen } from "../modal/modal-reducer";
import firebase from "firebase/app";

import {
  // signIn,
  initializeAuth,
  reportSigninSuccess,
  signinAnon,
  reportAuthError,
  signInWithEmailLink,
  signInWithEmailLinkVerified,
  getSigninData,
  signOut,
  reportSignoutSuccess,
  toggleEmailLinkSent,
  setIsLoading,
} from "./user-auth-reducer";
import {
  setUser,
  setAnonUser,
  initializeUserProfile,
} from "../user-profile/user-profile-reducer";

export const delay = (time) =>
  new Promise((resolve) => setTimeout(resolve, time));

const redirect = (location) =>
  console.log("THE REDIRECT LOCATION ", location) ||
  Promise.resolve((window.location.href = location));

const baseDomain = process.env.REACT_APP_BASE_DOMAIN
  ? process.env.REACT_APP_BASE_DOMAIN + "/featured"
  : "/featured";

export function createAuthChannel() {
  return eventChannel((emit) => {
    return firebase.auth().onAuthStateChanged((user) => {
      emit({ user });
    });
  });
}

export function* handleAuthStatusChange(user) {
  try {
    yield put(setIsLoading(true));
    if (user && user.isAnonymous === true) {
      yield put(setAnonUser());
      yield put(setIsLoading(false));
    }

    if (user === null) {
      yield call(signinAnonFB);
      yield put(signinAnon());
      yield put(setIsLoading(false));
    } else {
      //should kick off another process.
      yield put(reportSigninSuccess(user));
    }
  } catch (error) {
    console.log("unknown error", error);
  }
}

export function* handleCompleteSigninWithEmailLink() {
  try {
    const signInData = yield select(getSigninData);
    yield call(signinWithEmail, signInData);
    yield console.log("THE BASEDOMAIN ", baseDomain);
    yield call(redirect, baseDomain);
  } catch (error) {
    const e = { error };
    yield put(reportAuthError(e.error));
  }
}

export function* handleSignOut() {
  try {
    yield call(signoutFB);
    yield put(reportSignoutSuccess());
    yield put(setAnonUser());
  } catch (err) {
    const e = { err };
    console.log(e);
  }
}

export function* handleSigninSuccess({ payload }) {
  try {
    //set a loading state here...

    let { user } = yield call(lookupUserFromDB, payload.uid);

    //We don't have to deal with anonymous users...
    if (!user && payload.isAnonymous === false) {
      const { user } = yield call(createNewUserInDB, payload);
      yield put(setUser(user));
      yield put(setIsLoading(false));
      //watch user for updates from fb
      yield put(initializeUserProfile());
    }
    if (user && payload.isAnonymous === false) {
      yield put(setUser(user));
      yield put(setIsLoading(false));
      //watch user for updates from fb
      yield put(initializeUserProfile());
    }
  } catch (err) {
    console.log(err);
  }
}

export function* handleWatchAuthState() {
  const channel = yield call(createAuthChannel);
  while (true) {
    const { user } = yield take(channel);
    yield call(handleAuthStatusChange, user);
  }
}

export function* handleSigninWithEmailLink({ payload }) {
  try {
    const email = payload;
    yield call(emailLinkSignin, email);
    yield put(toggleEmailLinkSent());
    yield call(delay, 60000);

    const modalOpen = yield select(getIsOpen);
    if (modalOpen) {
      yield put(toggleModal());
    }

    yield put(toggleEmailLinkSent());
    //only close if modal is currently open
  } catch (error) {
    alert("error");
    const e = { error };
    yield put(reportAuthError(e.error.message));
  }
}

export function* watchSignout() {
  yield takeEvery(signOut().type, handleSignOut);
}

export function* watchSigninWithEmailLinkVerified() {
  yield takeEvery(
    signInWithEmailLinkVerified().type,
    handleCompleteSigninWithEmailLink
  );
}

export function* watchSigninWithEmailLink() {
  yield takeEvery(signInWithEmailLink().type, handleSigninWithEmailLink);
}

export function* watchInitSignin() {
  yield takeEvery(initializeAuth().type, handleWatchAuthState);
}

export function* watchSigninSuccess() {
  yield takeEvery(reportSigninSuccess().type, handleSigninSuccess);
}

export default function* userAuth() {
  yield all([
    fork(watchInitSignin),
    fork(watchSigninSuccess),
    fork(watchSigninWithEmailLink),
    fork(watchSigninWithEmailLinkVerified),
    fork(watchSignout),
  ]);
}
