import React, { Component } from "react";
import { compose, intersection } from "lodash/fp";
import WithConversation from "../data/WithConversation";
import WithConvoQueries from "../data/WithConvoQueries";
import WithUser from "../data/WithUser";
import WithAnalytics from "../data/WithAnalytics";

import ConversationSummary from "../component/ConversationSummary";
import TrustSwitch from "../component/TrustSwitch";
// import ReactDOM from "react-dom";
import Loading from "../component/Loading";
import AuthContext from "./App.js";
// import UseMessageEditing from "../hooks/UseMessageEditing";

class Conversations extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.inputField = React.createRef();

    this.state = {
      route: this.props.location.pathname.split("/")[1],
      loading: true,
    };
  }

  componentWillMount() {
    const { user, getTrusts } = this.props;

    getTrusts(user.uid, "trusts").then((trusts) =>
      this.setState({ trusts, loading: false })
    );

    this.setState({ filterTrusts: this.state.route === "public" });
  }

  handleClick = (c) => {
    const { route } = this.state;
    route !== "featured"
      ? this.props.history.push(`/${this.state.route}/conversations/${c.uid}`)
      : this.props.history.push(`/public/conversations/${c.uid}`);
  };

  handleTrustToggle = (filterTrusts) =>
    this.setState({ filterTrusts, filter: true });

  renderConversations = (conversations) => {
    const { filterTrusts, trusts } = this.state;
    const { user, shouldFetch } = this.props;

    const allContributorIds = (c) => [c.creator.uid]; // Object.values(c.participants || []).map(u => u.uid);
    const trustedIds = trusts.concat(user).map((u) => u.uid);
    const trustedContributors = (c) =>
      intersection(allContributorIds(c))(trustedIds);

    // Get all the conversation hosts
    const conversationHosts = (c) => Object.values(c.hosts || {});
    // see if the user trusts a cohost of the conversation
    const checkTrustsCohost = (c) =>
      Object.values([...conversationHosts(c), c.creator] || {}).map(
        (h) => h.uid
      );
    // see if the user trusts a cohost of the conversation
    const cohostContributors = (c) =>
      intersection(checkTrustsCohost(c))(trustedIds);

    return conversations
      .sort((a, b) => b.updated_at - a.updated_at)
      .filter((c) =>
        filterTrusts
          ? trustedContributors(c).length || cohostContributors(c).length
          : c
      )
      .map((c) => (
        <ConversationSummary
          shouldFetch={shouldFetch}
          conversationId={c.uid}
          lastKnownKey={this.props.lastKnownKey}
          showImage={this.state.route === "featured"}
          conversation={c}
          key={c.uid}
          handleClick={this.handleClick}
        />
      ));
  };

  render() {
    const { loading, filterTrusts, route } = this.state;
    const { conversations, lastKnownKey, shouldFetch } = this.props;

    return loading ? (
      <h1 ref={this.inputField}>
        <Loading />
      </h1>
    ) : (
      <div>
        {route === "public" ? (
          <TrustSwitch
            checked={filterTrusts}
            handleToggle={this.handleTrustToggle}
          />
        ) : null}
        {/* sort((a, b) => b.updated_at - a.updated_at */}
        {this.renderConversations(conversations)}
        {this.props.location.pathname.split("/")[1] !== "private" ? (
          <div id={lastKnownKey}></div>
        ) : null}
        {shouldFetch === true && filterTrusts === false ? (
          <h1>Loading...</h1>
        ) : null}
      </div>
    );
  }
}

export default compose(
  WithAnalytics,
  WithUser,
  WithConvoQueries,
  WithConversation
)(Conversations);
