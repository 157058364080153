import React from "react";
import Avatar from "./Avatar";

const MemberStamp = ({
  user,
  handleClick,
  roleText,
  showDisplayName = true,
}) => (
  <div style={styles.memberContainer} onClick={() => handleClick(user)}>
    {user.photoURL ? <Avatar imageURL={user.photoURL} size="small" /> : ""}
    <p style={styles.memberName}>
      {showDisplayName ? user.displayName : "You"} {roleText}
    </p>
  </div>
);

const styles = {
  memberContainer: {
    display: "flex",
  },
  memberName: {
    fontWeight: "bold",
    fontSize: "18px",
    marginBottom: "0px",
    marginLeft: "20px",
    alignSelf: "center",
  },
};

export default MemberStamp;
