import autodux from "autodux";
// import { formatUserData } from "./helpers";
const initialState = {
  isInitialized: false,
  isSignedIn: false,
  isAuthenticating: false,
  emailLinkSent: false,
  isLoading: false,
  error: {
    status: false,
    message: "",
  },
  signInData: {
    email: "",
    location: "",
  },
};

export const {
  reducer,
  initial,
  slice,
  actions: {
    initializeAuth,
    reportSigninSuccess,
    reportSignoutSuccess,
    reportSigninError,
    reportAuthError,
    toggleEmailLinkSent,
    signinAnon,
    signInWithEmailLink,
    signInWithEmailLinkVerified,
    setAnonUser,
    setEmail,
    signOut,
    setIsLoading,
  },
  selectors: {
    getIsSignedIn,
    getEmail,
    getIsAuthenticating,
    getSigninData,
    getEmailLinkSent,
    getAuthError,
    getIsLoading,
  },
} = autodux({
  slice: "userAuth",
  initial: initialState,
  actions: {
    initializeAuth: (state) => ({ ...state, isInitialized: true }),
    signinAnon: (state) => ({ ...state, isAuthenticating: true }),
    setEmail: (state, payload) => ({ ...state, email: payload }),
    signOut: (state, payload) => ({ ...state, ...payload }),
    signInWithEmailLinkVerified: (state, payload) => ({
      ...state,
      signInData: {
        ...state.signInData,
        ...payload,
      },
    }),
    signInWithEmailLink: (state) => ({
      ...state,
      isAuthenticating: true,
    }),
    reportSigninSuccess: (state) => ({
      ...state,
      isSignedIn: true,
      isAuthenticating: false,
    }),
    reportSignoutSuccess: (state) => ({
      ...state,
      isSignedIn: false,
    }),
    toggleEmailLinkSent: (state) => ({
      ...state,
      emailLinkSent: !state.emailLinkSent,
    }),
    reportSigninError: (state, payload) => ({
      ...state,
      error: payload,
      isSignedIn: false,
      isAuthenticating: false,
    }),
    setIsLoading: (state, loading) => ({
      ...state,
      isLoading: loading,
    }),
    reportAuthError: (state, payload) => ({
      ...state,
      isInitialized: false,
      error: {
        ...state.error,
        ...payload,
      },
    }),
  },

  selectors: {
    getIsSignedIn: ({ isSignedIn }) => isSignedIn,
    getIsLoading: ({ isLoading }) => isLoading,
    getEmail: ({ signInData: { email } }) => email,
    getIsAuthenticating: ({ isAuthenticating }) => isAuthenticating,
    getSigninData: ({ signInData }) => signInData,
    getEmailLinkSent: ({ emailLinkSent }) => emailLinkSent,
    getAuthError: ({ error }) => error,
  },
});
