import React from "react";
import { Row, Col, message as antMessage } from "antd";
import Env from "../utils/Env";

const MessageActionBar = ({
  handleHighlightClick,
  conversation,
  message,
  handleBranchClick,
  disabled,
  canDelete,
  handleDeleteClick,
  handleSetMessageEditing,
  hasEdited,
  handleUpdateMessage,
  handleCancelEditing,
  isEditing,
  sendDisabled,
  user,
}) => {
  //keep empty object for now.

  const copyLink = () => {
    try {
      const textField = document.createElement("textarea");

      textField.value = `${Env.baseUrl()}/${
        conversation.status
      }/conversations/${conversation.uid}/message/${message.uid}`;

      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
      antMessage.success("Link Copied");
    } catch (err) {
      antMessage.error("There was an error");
    }
  };

  return disabled ? null : (
    <React.Fragment>
      <hr />
      <Row
        type="flex"
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Col
          style={{
            textAlign: "center",
            fontSize: 38,
            verticalAlign: "top",
            justifyContent: "center",
            cursor: "pointer",
          }}
          span={6}
          onClick={handleHighlightClick}
        >
          {isEditing ? (
            ""
          ) : (
            <React.Fragment>
              {" "}
              <p style={styles.icon}>
                <span role="img" aria-label="emoji">
                  🔦
                </span>
              </p>
              <p style={styles.p}>
                <span style={styles.labelText}>Highlight</span>
              </p>
            </React.Fragment>
          )}
        </Col>
        <Col
          style={{
            textAlign: "center",
            fontSize: 38,
            verticalAlign: "center",
            cursor:
              conversation.status === "private" ? "not-allowed" : "pointer",
            opacity: conversation.status === "private" ? 0.3 : 1,
          }}
          span={6}
          onClick={(e) =>
            conversation.status === "private" ? null : copyLink(e)
          }
        >
          {isEditing ? (
            ""
          ) : (
            <React.Fragment>
              <p style={styles.icon}>
                <span role="img" aria-label="emoji">
                  🔗
                </span>
              </p>
              <p style={styles.p}>
                <span style={styles.labelText}>Copy Link</span>
              </p>{" "}
            </React.Fragment>
          )}
        </Col>
        <Col
          style={{
            textAlign: "center",
            fontSize: 38,
            verticalAlign: "center",
            cursor: "pointer",
          }}
          span={6}
          onClick={handleBranchClick}
        >
          {isEditing ? (
            <Col
              onClick={hasEdited && !sendDisabled ? handleUpdateMessage : null}
              disabled={sendDisabled}
              style={Object.assign(
                {},
                hasEdited && !sendDisabled ? styles.button : styles.iconDisabled
              )}
            >
              <span role="img" aria-label="emoji">
                <span role="img" aria-label="emoji">
                  💾
                </span>
              </span>
              <p style={styles.p}>
                <span style={styles.labelText}>Save</span>
              </p>
            </Col>
          ) : (
            <p
              onClick={
                message.user.uid === user.uid ? handleSetMessageEditing : {}
              }
              style={Object.assign(
                { ...styles.icon },
                message.user.uid === user.uid ? "" : styles.disabled
              )}
            >
              <span role="img" aria-label="emoji">
                🛠
              </span>
              <p style={styles.p}>
                <span style={styles.labelText}>Edit</span>
              </p>
            </p>
          )}
        </Col>
        {canDelete ? (
          <Col
            style={{
              textAlign: "center",
              fontSize: 38,
              cursor: "pointer",
            }}
            span={6}
          >
            {isEditing ? (
              <React.Fragment>
                <span
                  onClick={handleCancelEditing}
                  disabled={!hasEdited}
                  style={Object.assign({}, styles.button, styles.icon)}
                >
                  <span role="img" aria-label="emoji">
                    <span role="img" aria-label="emoji">
                      ❌
                    </span>
                  </span>
                  <p style={styles.p}>
                    <span style={styles.labelText}>Cancel</span>
                  </p>
                </span>
              </React.Fragment>
            ) : (
              <Col
                onClick={handleDeleteClick}
                disabled={!canDelete}
                style={Object.assign({}, styles.button, styles.icon)}
              >
                <span role="img" aria-label="emoji">
                  <span role="img" aria-label="emoji">
                    🗑
                  </span>
                </span>
                <p style={styles.p}>
                  <span role="img" aria-label="emoji">
                    Delete
                  </span>
                </p>
              </Col>
            )}
          </Col>
        ) : (
          <Col
            style={{
              textAlign: "center",
              fontSize: 38,
              cursor: "pointer",
            }}
            span={6}
          >
            <React.Fragment>
              <p style={styles.icon}>
                <span role="img" aria-label="emoji">
                  ⚠️
                </span>
              </p>
              <p style={styles.p}>
                <span style={styles.labelText}> Report</span>
              </p>
            </React.Fragment>
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
};

const styles = {
  disabled: {
    opacity: 0.2,
  },
  button: {
    background: "none",
    border: "none",
    cursor: "pointer",
    focus: { outline: 0 },
  },
  iconDisabled: {
    opacity: 0.2,
    background: "none",
    border: "none",
    cursor: "pointer",
    focus: { outline: 0 },
  },
  p: {
    fontSize: 14,
    margin: 0,
    textAlign: "center",
  },
  icon: { marginBottom: 0 },
  labelText: { fontSize: 14 },
};

export default MessageActionBar;
