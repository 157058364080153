/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import fp from "lodash/fp";
import { withRouter } from "react-router-dom";
import WithConversation from "../data/WithConversation";
import WithUser from "../data/WithUser";
import WithAnalytics from "../data/WithAnalytics";
import Nav from "../component/Nav";
import WithActivity from "../data/WithActivity";
import Utils from "../utils/DisplayUtils";
import Loading from "../component/Loading";

class NavContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      conversationId: this.props.match.params.conversationId,
      messageId: this.props.match.params.messageId,
      conversation: null,
      activity: [],
      privateConversation: (this.conversation || {}).status === "private",
      privateConversations:
        this.props.location.pathname.split("/")[1] === "private",
      loading: true,
    };
  }

  componentWillMount() {
    const {
      conversationListener,
      activityListener,
      user,
      match: {
        params: { status },
      },
    } = this.props;
    const { conversationId } = this.state;

    activityListener(user.uid, (activity) => {
      conversationListener(conversationId, (conversation) => {
        const unauthorized =
          conversation &&
          conversation.status === "private" &&
          status !== "private";

        const userHasAccess =
          conversation && conversation.status === "private"
            ? Object.values(conversation.contributors || {})
                .concat(conversation.creator)
                .map((c) => c.uid)
                .includes(user.uid)
            : true;

        this.setState({
          activity: activity.sort((a, b) => b.timestamp - a.timestamp),
          loading: false,
          conversation:
            unauthorized || !userHasAccess ? {} : conversation || {},
        });
      });
    });
  }

  componentWillReceiveProps = (nextprops) => {
    const { user } = nextprops;
    this.props.activityListener(user.uid, (activity) => {
      this.setState({
        activity: activity.sort((a, b) => b.timestamp - a.timestamp),
      });
    }),
      nextprops.conversationListener(
        nextprops.match.params.conversationId,
        (conversation) => {
          this.setState({
            conversation: conversation || {},
            privateConversation: nextprops.match.params.status === "private",
            privateConversations:
              nextprops.location.pathname.split("/")[1] === "private",
          });
        }
      );
  };

  handleTitleClick = () => {
    const { conversation } = this.state;

    if (!conversation.title) return null;

    return this.props.location.pathname.includes("detail")
      ? this.props.history.replace(
          `/${
            conversation.featured ? "public" : conversation.status
          }/conversations/${conversation.uid}`
        )
      : this.props.history.push(
          `/${
            conversation.featured ? "public" : conversation.status
          }/conversations/${conversation.uid}/detail`
        );
  };
  handleActivityMenuClick = () => {
    const { recordSession, viewActivity, user } = this.props;

    viewActivity();
    recordSession(user.uid);
  };
  renderTitle() {
    const {
      match: { path, params },
    } = this.props;
    const { conversation } = this.state;

    let text = null;
    let type = "location";

    switch (path) {
      case "/members":
        text = "Member Directory";
        type = "location";
        break;

      case "/:status/conversations/:conversationId":
        text = conversation.title;
        type = "title";
        break;

      case "/:status/conversations/:conversationId/detail":
        text = conversation.title;
        type = "title";
        break;

      case "/:status/conversations/:conversationId/message/:messageId":
        text = "Message";
        type = "location";
        break;

      case "/:status/conversations/:conversationId/detail/:action/edit":
        text = "Contributors";
        type = "location";
        break;
      case "/:status/conversations/:conversationId/detail/:action/invite":
        params.action === "hosts"
          ? (text = "Cohosts")
          : (text = "Contributors");

        type = "location";
        break;

      case "/:status/conversations/:conversationId/detail/:memberType":
        text = Utils.capitalCase(params.memberType);
        type = "location";
        break;

      case "/:status/conversations/:conversationId/detail/contributors/edit":
        text = "Contributors";
        type = "location";
        break;

      case "/:status/conversations/:conversationId/featuredimage":
        text = "Featured Image";
        type = "location";
        break;
      case "/profile/:userId":
        text = "Profile";
        type = "location";
        break;
      case "/profile/:userId/:trustType":
        text =
          params.trustType === "trusts"
            ? Utils.capitalCase(params.trustType)
            : "Trusted By";
        type = "location";
        break;
      default:
        break;
    }

    return { text, type };
  }
  render() {
    const { conversation, activity, loading } = this.state;
    const {
      location,
      history,
      user,
      handleSignOut,
      handleSignIn,
      toggleModal,
      isSignedIn,
    } = this.props;

    if (loading) {
      return null;
    }
    return loading ? null : (
      <Nav
        {...this.props}
        activity={activity}
        conversation={conversation}
        title={this.renderTitle()}
        user={user}
        toggleModal={() => toggleModal()}
        pathname={location.pathname}
        history={history}
        isPrivate={
          this.state.privateConversation || this.state.privateConversations
        }
        handleSignOut={handleSignOut}
        handleSignIn={handleSignIn}
        handleTitleClick={this.handleTitleClick}
        handleActivityMenuClick={this.handleActivityMenuClick}
      />
    );
  }
}

NavContainer.propTypes = {
  conversationListener: PropTypes.func.isRequired,
  activityListener: PropTypes.func.isRequired,
  recordSession: PropTypes.func.isRequired,
  handleSignOut: PropTypes.func.isRequired,
  handleAuth: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default fp.compose(
  WithActivity,
  WithUser,
  withRouter,
  WithConversation,
  WithAnalytics
)(NavContainer);
