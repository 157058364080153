import React, { Component } from "react";
import { compose } from "lodash/fp";
import { Card } from "antd";
import UserProfile from "../component/UserProfile";
import RecentConversationsContainer from "../container/RecentConversationsContainer";
import Loading from "../component/Loading";
import UserBioContainer from "../container/UserBioContainer";

import WithConversation from "../data/WithConversation";
import WithUser from "../data/WithUser";
import WithAnalytics from "../data/WithAnalytics";

class UserProfileContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: "",
      user: this.props.user,
      userId: this.props.match.params.userId,
      messages: [],
      trusts: [],
      trustees: [],
      loading: true,
    };
  }

  componentDidMount() {
    const { conversationsListener, profileListener } = this.props;
    const { user, userId } = this.state;

    profileListener(userId, (userProfile) => {
      this.setState({ userProfile });
      this.getTrusts(userId);
    });

    const conversations = {
      private: { index: "status", value: "private" },
      public: { index: "status", value: "public" },
    };

    conversationsListener(user, conversations.public, (publicConversations) => {
      this.setState({ publicConversations });
    });

    conversationsListener(
      user,
      conversations.private,
      (privateConversations) => {
        this.setState({ privateConversations });
      }
    );
  }

  componentWillReceiveProps(newProps) {
    const uid = newProps.match.params.userId;
    this.getProfile(uid, (userProfile) => {
      this.setState({ userId: uid, userProfile });
    });

    this.getTrusts(uid);
  }

  getProfile(uid) {
    const { profileListener } = this.props;
    profileListener(uid, (userProfile) => {
      this.setState({ userProfile, loading: false });
    });
  }

  getTrusts(userId) {
    const { getTrusts, user } = this.props;

    Promise.all([
      getTrusts(userId, "trusts"),
      getTrusts(userId, "trusted by"),
    ]).then(([trusts, trustees]) => {
      const trusted = trustees.map((t) => t.uid).includes(user.uid);

      this.setState({
        trusts,
        trustees,
        trusted,
        loading: false,
      });
    });
  }

  handleConversationClick = (c) => {
    this.props.history.push(`/public/conversations/${c.uid}`);
  };

  handleAvatarClick = (uid) => {
    this.props.history.push(`/profile/${uid}`);
  };

  handleTrustsNav = (type) => {
    const { userId, user } = this.state;

    this.props.history.push(`/profile/${userId || user.uid}/${type}`);
  };

  handleTrust = () => {
    const { user, userProfile, trustees, trusted } = this.state;
    const { trustMember, trust } = this.props;

    trustMember(trusted, "profile", userProfile);

    return trust(user, userProfile, trusted).then(() => {
      const newTrustees = trusted
        ? trustees.slice(1, trustees.length)
        : trustees.concat([user]);

      this.setState({
        trustees: newTrustees,
        trusted: !trusted,
      });
    });
  };

  render() {
    const {
      loading,
      trustees,
      trusts,
      trusted,
      userProfile,
      publicConversations,
      privateConversations,
    } = this.state;

    if (loading) return <Loading />;
    if (!userProfile) return <h1>We're sorry, this user doesn't exist</h1>;

    return publicConversations && privateConversations ? (
      <div style={{ textAlign: "center" }}>
        <Card style={{ paddingTop: "57px" }}>
          <UserProfile
            user={this.props.user}
            userProfile={userProfile}
            trusts={trusts}
            trusted={trusted}
            trustees={trustees}
            handleTrustsNav={this.handleTrustsNav}
            handleTrust={this.handleTrust}
          />
          <UserBioContainer {...this.state} />
        </Card>

        <RecentConversationsContainer
          publicConversations={publicConversations}
          privateConversations={privateConversations}
          userProfile={userProfile}
          user={this.props.user}
          handleConversationClick={(c) => this.handleConversationClick(c)}
          handleAvatarClick={(uid, e) => this.handleAvatarClick(uid, e)}
        />
      </div>
    ) : (
      <Loading />
    );
  }
}

export default compose(
  WithConversation,
  WithUser,
  WithAnalytics
)(UserProfileContainer);
