/* eslint-disable */

import React from "react";
import { Row, Col, Button, Icon, Card, Upload } from "antd";
import MessageMetadata from "./MessageMetadata";
import CharacterCounter from "./characterCounter/CharacterCounter";
import { Mention, Input } from "antd";
import "./Messages.css";

const { TextArea } = Input;

const MessageInput = ({
  showIcons,
  showAvatar,
  user,
  inputDisabled,
  inputValue,
  handleInputChange,
  handleSendMessage,
  handleInputSelect,
  sendDisabled,
  uploadProps,
  handleAvatarClick,
  handleSetEdits,
  handleTag,
  onChange,
  suggestions,
  value,
  onSearchChange,
  charCount,
  firstMessageLen,
  isCompose,
  isEditing,
  handleUpdateMessage,
  message,
  hasEdited,
  handleCancelEditing,
  isMessageDetail,
}) => {
  const icons =
    showIcons || !isEditing ? (
      <Upload {...uploadProps}>
        <Icon style={styles.icon} type="camera" />
      </Upload>
    ) : null;

  return isEditing ? (
    <div>
      <Col>
        <TextArea
          autoFocus={true}
          onFocus={function (e) {
            var val = e.target.value;
            e.target.value = "";
            e.target.value = val;
          }}
          onClick={(e) => e.stopPropagation()}
          style={styles.textArea}
          value={inputValue}
          onChange={handleSetEdits}
          autosize={{ minRows: 2, maxRows: 900 }}
        />
      </Col>
      {!isMessageDetail ? (
        <Row
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
          }}
        >
          <Col sm={2} md={2} lg={2}>
            <Button
              className="send-button"
              data-testid="send-button"
              style={Object.assign({}, styles.button, {
                color:
                  !inputValue.length || inputDisabled || !hasEdited
                    ? "gray"
                    : "red",
              })}
              disabled={
                !inputValue.length ||
                inputDisabled ||
                !hasEdited ||
                sendDisabled
              }
              onClick={(e) => {
                e.stopPropagation(), handleUpdateMessage();
              }}
            >
              Save
            </Button>
          </Col>
          <Col sm={1} md={2} lg={2}>
            <Button
              className="system-emoji"
              style={{
                display: hasEdited ? "block" : "none",
                border: "none",
              }}
              role="img"
              aria-label="emoji"
              onClick={(e) => {
                e.stopPropagation(), handleCancelEditing();
              }}
            >
              <span style={{ fontSize: "20px" }}>❌</span>
            </Button>
          </Col>
        </Row>
      ) : null}
    </div>
  ) : (
    <div
      className={inputValue ? "message-input-wrapper-selected" : null}
      style={styles.container}
    >
      <Card
        bordered={false}
        bodyStyle={{ opacity: inputValue.length > 0 ? "1" : "0.5" }}
      >
        <MessageMetadata
          message={{ user }}
          isInput
          className="message-input-avatar"
          handleAvatarClick={handleAvatarClick}
        />
        <Row>
          <Col
            xs={{ span: 19, offset: 3 }}
            sm={{ span: 19, offset: 3 }}
            md={{ span: 19, offset: 2 }}
            lg={{ span: 19, offset: 2 }}
          >
            <Mention
              placeholder="Compose message"
              style={styles.textArea}
              onSelect={(s, d) => {
                handleTag(d.user);
              }}
              notFoundContent="user not found"
              value={value}
              onChange={(v) => onChange(v)}
              suggestions={suggestions}
              onSearchChange={(text) => onSearchChange(text)}
              multiLines
            />
          </Col>

          {isCompose ? (
            <Col
              xs={{ span: 2 }}
              sm={{ span: 1 }}
              style={styles.circleContainer}
            >
              <CharacterCounter charCount={firstMessageLen} />
            </Col>
          ) : null}

          <Col
            xs={{ span: 2 }}
            sm={{ span: 2 }}
            md={{ span: 3 }}
            lg={{ span: 3 }}
            style={styles.iconContainer}
          >
            {icons}
          </Col>
        </Row>
        <Row>
          <Col sm={{ span: 6, offset: 18 }}>
            <Button
              className="send-button"
              data-testid="send-button"
              style={Object.assign({}, styles.button, {
                color: !inputValue.length || inputDisabled ? "gray" : "red",
              })}
              disabled={!inputValue.length || inputDisabled}
              onClick={() => handleSendMessage()}
            >
              Send
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

const styles = {
  container: {
    borderRadius: 2,
    backgroundColor: "#ffffff",
    position: "relative",
    width: "100%",
  },
  circleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginLeft: "5px",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  icon: {
    fontSize: 24,
    margin: 5,
    color: "#BCBCBC",
  },
  button: {
    alignItems: "flex-end",
    backgroundColor: "white",
    float: "right",
    border: "none",
    fontSize: "20px",
    padding: "0",
  },
  userName: {
    fontFamily: "Roboto-Medium",
    fontSize: "24pt",
    color: "rgba(68, 68, 68, 0.85)",
    marginLeft: "16pt",
  },
  textArea: {
    border: "none",
    zIndex: 2,
    fontFamily: "Crimson-Text",
    fontSize: "18px",
    color: "rgba(0, 0, 0, 0.85)",
    padding: "0px",
    resize: "none",
    boxShadow: "none",
    height: "100%",
  },
};

export default MessageInput;
