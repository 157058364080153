/* eslint-disable */

import React, { useEffect } from "react";
import UseConvoQueries from "../hooks/UseConvoQueries";
const WithConvoQueries = (Component) => (props) => {
  const {
    queryConvos,
    conversations,
    lastKnownKey,
    shouldFetch,
  } = UseConvoQueries();

  const { user } = props;
  const screen = {
    featured: { index: "featured", value: true },
    public: { index: "status", value: "public" },
    private: { index: "status", value: "private" },
  };

  const view = screen[props.location.pathname.split("/")[1]];

  let sentinel = null;
  let observer = null;

  const startObserver = async (sentinel) => {
    let options = {
      root: null, // relative to document viewport
      rootMargin: "90px", // margin around root. Values are similar to css property. Unitless values not allowed
      threshold: 1.0, // visible amount of item shown in relation to root
    };

    function onChange(changes, observer) {
      changes.forEach((change) => {
        if (change.intersectionRatio > 0) {
          queryConvos(lastKnownKey, view, user);
        }
      });
    }

    observer = new IntersectionObserver(onChange, options);

    observer.observe(sentinel);
  };

  useEffect(() => {
    const { viewFeatured, viewRecent, viewPrivate, user } = props;
    const analyticsEvents = {
      featured: viewFeatured,
      public: viewRecent,
      private: viewPrivate,
    };

    const analyticsEvent = analyticsEvents[view];

    analyticsEvent && conversations.length
      ? analyticsEvent(user, "featured", conversations)
      : null;
  }, [conversations]);

  useEffect(() => {
    if (shouldFetch === false && observer !== null) {
      observer.disconnect();
    }
  }, [shouldFetch, observer]);

  useEffect(() => {
    queryConvos(null, view, user);
  }, []);

  const checkForSentinel = async () => {
    while (!document.querySelector(`#${lastKnownKey}`)) {
      await new Promise((r) => setTimeout(r, 500));
    }
  };

  useEffect(() => {
    if (lastKnownKey !== null) {
      checkForSentinel().then(() => {
        sentinel = document.querySelector(`#${lastKnownKey}`);
        startObserver(sentinel);
      });
    }
  }, [lastKnownKey, sentinel]);

  const checkAccess = (user, convosnapshot) =>
    Object.values(convosnapshot || {}).filter((c) => {
      const { creator, contributors, status } = c;

      return status === "private"
        ? Object.keys(contributors || {}).indexOf(user.uid) >= 0 ||
            creator.uid === user.uid
        : true;
    });

  return (
    <div id="convoQueries">
      <Component
        {...props}
        conversations={
          view.value === "private"
            ? checkAccess(user, conversations)
            : conversations
        }
        lastKnownKey={lastKnownKey}
        shouldFetch={shouldFetch}
      />
    </div>
  );
};

export default WithConvoQueries;
