import { Alert } from "antd";
import React from "react";
import DisplayUtils from "../utils/DisplayUtils";

const Banner = () => {
  if (DisplayUtils.getMobileOP() === false) return null;

  return null;
};

export default Banner;
