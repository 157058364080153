import React from "react";
import PropTypes from "prop-types";
import { Row } from "antd";
import Avatar from "./Avatar";
import Loading from "./Loading";

const WelcomeMat = ({
  loading,
  isSignedIn,
  friends = [],
  featured = [],
  handleClick,
  handleAllMembers,
}) => {
  const data = featured.concat(friends);

  const user = (u) => (
    <div
      onClick={() => handleClick(u)}
      style={styles.friendContainer}
      key={u.uid}
    >
      <div style={styles.friend}>
        <Avatar
          imageURL={u.photoURL}
          size="medium"
          className="welcome-mat-circle"
        />
        {u.displayName.split(" ").map((n) => (
          <p style={styles.friendName}>{n}</p>
        ))}
      </div>
    </div>
  );

  const allMembers = (
    <div
      onClick={handleAllMembers}
      style={styles.allMembersContainer}
      key="allmembers"
    >
      <div style={styles.friend}>
        <span role="img" aria-label="emoji" style={{ fontSize: "44px" }}>
          👩‍👩‍👦‍👦
        </span>
        <p style={styles.friendName}>All Members</p>
      </div>
    </div>
  );

  return (
    <div
      className={"welcomeMat"}
      style={{ display: "flex", justifyContent: "center" }}
    >
      <Row style={{ overflowY: "hidden" }}>
        <div style={styles.container}>
          {loading ? <Loading /> : data.map(user).concat(allMembers)}
        </div>
      </Row>
    </div>
  );
};

WelcomeMat.propTypes = {
  friends: PropTypes.array.isRequired,
  featured: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default WelcomeMat;

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    height: "100%",
  },
  friendContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    margin: "0px 10px 0px 0px",
    maxWidth: "80px",
  },
  allMembersContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    margin: "0px 10px 0px 5px",
    paddingLeft: "5px",
    maxWidth: "80px",
    borderLeftColor: "rgba(158, 158, 158, 0.4)",
    borderLeftStyle: "solid",
    borderLeftWidth: "1px",
    height: "100%",
  },
  header: {
    backgroundColor: "#FFF",
    textAlign: "center",
  },
  friend: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 80,
  },
  friendName: {
    margin: 0,
    textAlign: "center",
  },
  lastFeatured: {
    paddingRight: 10,
    marginRight: 10,
  },
};
