import { applyMiddleware, createStore, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas/index.js";

import reducer from "./reducers";

const composeEnhancers =
  (process.browser && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

// const resetEnhancer = (reducer) => (state, action) => {
//   if (action.type === "RESET") {
//     return reducer(undefined, reducer);
//   }
//   return reducer(state, action);
// };

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const store = createStore(
  //Reset the whole store, Nice!
  reducer,
  composeEnhancers(applyMiddleware(...middlewares))
);
store.sagaTask = sagaMiddleware.run(rootSaga);

export default store;
