import React from "react";
import { Spin, Icon } from "antd";

const styles = {
  aligner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
};

const Loading = () => (
  <div className="aligner" style={styles.aligner}>
    <Spin
      size="large"
      indicator={
        <Icon
          type="loading"
          spin
          style={{ fontSize: "32px", color: "#c0223b" }}
        />
      }
    />
  </div>
);

export default Loading;
