import React, { useEffect } from "react";
import { compose } from "lodash/fp";
import WithUser from "../data/WithUser";
import WithAnalytics from "../data/WithAnalytics";
import Routes from "../routes";
import "./App.css";
import Loading from "../component/Loading";
import authWrapper from "../HOC/authWrapper";
import { Spin, Icon } from "antd";

const App = (props) => {
  const {
    launchApp,
    registerUser,
    initAnalytics,
    user,
    isAuthenticating,
    isSignedIn,
    sessionListener,
    isLoading,
  } = props;
  const handleSignIn = () => {};
  const handleSignOut = () => {};

  useEffect(() => {
    initAnalytics();
  }, [initAnalytics]);

  useEffect(() => {
    launchApp();
    registerUser(user);
    sessionListener(user.uid);
  }, [user]);

  if (isAuthenticating) {
    return (
      <Spin
        size="large"
        indicator={
          <Icon
            type="loading"
            spin
            style={{ fontSize: "32px", color: "blue" }}
          />
        }
      />
    );
  }

  //require an anonymous user to be signed in
  return isSignedIn && !isLoading ? (
    <Routes
      {...props}
      handleSignOut={handleSignOut}
      handleSignIn={handleSignIn}
      user={user}
    />
  ) : (
    <Loading></Loading>
  );
};

export default compose(WithAnalytics, WithUser, authWrapper)(App);
