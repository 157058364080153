import autodux from "autodux";

// import { formatUserData } from "./helpers";
const initialState = {
  isOpen: false,
};

export const {
  reducer,
  initial,
  slice,
  actions: { toggleModal, updateEmailInput },
  selectors: { getIsOpen, getEmail },
} = autodux({
  slice: "authModal",
  initial: initialState,
  actions: {
    toggleModal: (state) => ({ ...state, isOpen: !state.isOpen }),
  },

  selectors: {
    getIsOpen: ({ isOpen }) => isOpen,
  },
});
