import firebase from "firebase/app";

export const updateUserInDB = async (user) => {
  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref("users/" + user.uid)
      .set(
        {
          ...user,
        },
        function (error) {
          if (error) {
            reject();
            // The write failed...
          } else {
            resolve();
            // Data saved successfully!
          }
        }
      );
  });
};
