import React from "react";
import { Row, Col, Card, Button } from "antd";
import "./Messages.css";
import UseMessageInputCheck from "../hooks/UseMessageInputCheck";
import MessageInputContainer from "../container/MessageInputContainer";

const MessageInputRequestWall = ({
  handleRequest,
  hasRequested,
  conversation,
  showAvatar,
  user,
  inputDisabled,
  inputValue,
  handleTag,
  handleSendMessage,
  text,
  handleInputChange,
  handleUpload,
  uploadProps,
  trustees,
  showIcons,
  handleAvatarClick,
}) => {
  const { InputRequestWallState, isHost, hasAccess } = UseMessageInputCheck(
    user,
    conversation,
    trustees
  );

  if (hasAccess === true) {
    return (
      <React.Fragment>
        <MessageInputContainer
          conversation={conversation}
          showAvatar={showAvatar}
          user={user}
          inputDisabled={inputDisabled}
          inputValue={inputValue}
          handleTag={handleTag}
          handleSendMessage={handleSendMessage}
          handleInputChange={handleInputChange}
          handleUpload={handleUpload}
          uploadProps={uploadProps}
          showIcons={showIcons}
          handleAvatarClick={handleAvatarClick}
        />
      </React.Fragment>
    );
  }

  const requested = (
    <Col sm={{ span: 16, offset: 4 }} style={styles.textContainer}>
      <p style={styles.text}>You have requested an invite!</p>
    </Col>
  );
  const request = (
    <Col sm={{ span: 16, offset: 4 }} style={styles.textContainer}>
      <p style={styles.text}>
        <span style={styles.icon} role="img" aria-label="emoji">
          👀
        </span>{" "}
        You are invited to view this conversation
      </p>
      <Button onClick={() => handleRequest(user)} type="primary">
        Tap to request an invite to contribute
      </Button>
    </Col>
  );
  const anon = (
    <Col sm={{ span: 16, offset: 4 }} style={styles.textContainer}>
      <p className="desktop-participate" style={styles.text}>
        Please log in to participate
      </p>
    </Col>
  );
  return (
    <React.Fragment>
      <Card bordered={false} bodyStyle={styles.container}>
        <Row>
          {user.isAnonymous ? anon : hasRequested ? requested : request}
        </Row>
      </Card>
    </React.Fragment>
  );
};

const styles = {
  container: {
    borderRadius: "2px",
    backgroundColor: "#eee",
    position: "relative",
    width: "100%",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontSize: "18px",
  },
  icon: {
    fontSize: "18px",
  },
};

export default MessageInputRequestWall;
