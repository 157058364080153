import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'lodash/fp';
import * as firebase from 'firebase';
import Loading from '../component/Loading';
import WithMessage from '../data/WithMessage';
import WithConversation from '../data/WithConversation';
import FeaturedImages from '../component/FeaturedImages';

class FeaturedImagesContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            messages: [],
            loading: true,
        };
    }
    componentWillMount() {
        const {
            conversationListener,
            getImageMessages,
            match: { params: { conversationId } },
        } = this.props;

        conversationListener(conversationId, conversation => {
            this.setState({ conversation });
            getImageMessages(conversationId).then(messages =>
                this.setState({ messages, loading: false }));
        });
    }
    handleToggle = imageURL => {
        const { featureImage } = this.props;
        const { conversation } = this.state;

        return imageURL ? featureImage({ conversation, imageURL }) : null;
    };
    render() {
        const { conversation, messages, loading } = this.state;
        const { user, featureImage } = this.props;

        const uploadProps = {
            name: 'file',
            showUploadList: false,
            accept: 'image/*',
            customRequest: data => {
                const { file } = data;
                const name = `${Date.now()}_${user.uid}`;

                const uploadTask = firebase
                    .storage()
                    .ref()
                    .child(`user_uploads/images/${user.uid}/${name}`)
                    .put(file, { contentType: 'image/jpeg' });

                uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, {
                    complete: () => {
                        uploadTask.snapshot.ref.getDownloadURL().then(imageURL => {
                            featureImage({ conversation, imageURL });
                        });
                    },
                });
            },
        };
        return loading ? (
            <Loading />
        ) : (
            <FeaturedImages
                uploadProps={uploadProps}
                handleToggle={this.handleToggle}
                handleAvatarClick={this.handleAvatarClick}
                handleClick={this.handleClick}
                messages={messages}
                featuredImage={conversation.featuredImage}
            />
        );
    }
}

FeaturedImagesContainer.propTypes = {
    user: PropTypes.object.isRequired,
    featureImage: PropTypes.func.isRequired,
    conversationListener: PropTypes.func.isRequired,
    getImageMessages: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
};

export default compose(WithConversation, WithMessage)(FeaturedImagesContainer);
