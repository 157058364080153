import React from "react";
import { Row, Card, Col } from "antd";
import DisplayUtils from "../utils/DisplayUtils";
import "./Messages.css";

const SystemMessage = ({ message, icon, handleClick, isDetail }) => {
  const types = {
    branch: (
      <div style={styles.container}>
        <Card
          bordered={false}
          bodyStyle={styles.systemMessageContainer}
          onClick={handleClick}
        >
          <Row style={styles.systemMessage}>
            <Col sm={1} push={2} style={styles.systemMessageIcon}>
              <span
                className="system-emoji"
                style={styles.icon}
                role="img"
                aria-label="emoji"
              >
                🌿
              </span>{" "}
            </Col>
            <Col sm={19} push={2} className="systemMessageText">
              <span>Branched from:</span>{" "}
              {DisplayUtils.bodyTrimmer(message.body)}
            </Col>
          </Row>
        </Card>
      </div>
    ),
    branch_message: (
      <div style={styles.container}>
        <Card
          bordered={false}
          bodyStyle={styles.systemMessageContainer}
          onClick={handleClick}
        >
          <Row style={styles.systemMessage}>
            <Col sm={1} push={2} style={styles.systemMessageIcon}>
              <span
                className="system-emoji"
                style={styles.icon}
                role="img"
                aria-label="emoji"
              >
                🌿
              </span>{" "}
            </Col>
            <Col sm={19} push={2} className="systemMessageText">
              {DisplayUtils.bodyTrimmer(message.body)}
            </Col>
          </Row>
        </Card>
      </div>
    ),
    deleted_message: (
      <div style={styles.container}>
        <Card
          bordered={false}
          bodyStyle={styles.systemMessageContainer}
          onClick={handleClick}
        >
          <Row style={styles.systemMessage}>
            <Col push={2} sm={1} style={styles.systemMessageIcon}>
              <span
                className="system-emoji"
                style={styles.icon}
                role="img"
                aria-label="emoji"
              >
                🗑
              </span>{" "}
            </Col>
            <Col sm={19} push={2} className="systemMessageText">
              <span />{" "}
              {DisplayUtils.bodyTrimmer("This message has been deleted.")}
            </Col>
          </Row>
        </Card>
      </div>
    ),
    conversation_created_by: (
      <div style={styles.container}>
        <Card
          bordered={false}
          bodyStyle={styles.systemMessageContainer}
          onClick={handleClick}
        >
          <Row style={styles.systemMessage}>
            <Col sm={1} push={2} style={styles.systemMessageIcon}>
              <span
                className="system-emoji"
                style={styles.icon}
                role="img"
                aria-label="emoji"
              >
                🌱
              </span>{" "}
            </Col>
            <Col sm={19} push={2} className="systemMessageText">
              {DisplayUtils.bodyTrimmer(message.body)}
            </Col>
          </Row>
        </Card>
      </div>
    ),
    conversation: (
      <div style={styles.container}>
        <Card
          bordered={false}
          bodyStyle={styles.systemMessageContainer}
          onClick={handleClick}
        >
          <Row style={styles.systemMessage}>
            <Col sm={1} push={2} style={styles.systemMessageIcon}>
              <span
                className="system-emoji"
                style={styles.icon}
                role="img"
                aria-label="emoji"
              >
                {message.icon}
              </span>
            </Col>
            <Col sm={19} push={2} className="systemMessageText">
              {DisplayUtils.bodyTrimmer(message.body)}
              <span
                style={{
                  fontFamily: "Roboto",
                  fontWeight: 900,
                  lineHeight: 1.8,
                  marginLeft: "-3px",
                }}
              >
                &nbsp;{" "}
                {DisplayUtils.titleTrimmer(`${message.conversationTitle}`)}
              </span>
            </Col>
          </Row>
        </Card>
      </div>
    ),
    invite_host: (
      <div style={styles.container}>
        <Card
          bordered={false}
          bodyStyle={styles.systemMessageContainer}
          onClick={handleClick}
        >
          <Row style={styles.systemMessage}>
            <Col sm={1} push={2} style={styles.systemMessageIcon}>
              <span
                className="system-emoji"
                style={styles.icon}
                role="img"
                aria-label="emoji"
              >
                📨
              </span>
            </Col>
            <Col sm={19} push={2} className="systemMessageText">
              {DisplayUtils.bodyTrimmer(message.body)}
            </Col>
          </Row>
        </Card>
      </div>
    ),
    invite_contributor: (
      <div style={styles.container}>
        <Card
          bordered={false}
          bodyStyle={styles.systemMessageContainer}
          onClick={handleClick}
        >
          <Row style={styles.systemMessage}>
            <Col sm={1} push={2} style={styles.systemMessageIcon}>
              <span
                className="system-emoji"
                style={styles.icon}
                role="img"
                aria-label="emoji"
              >
                📨
              </span>
            </Col>
            <Col sm={19} push={2} className="systemMessageText">
              {DisplayUtils.bodyTrimmer(message.body)}
            </Col>
          </Row>
        </Card>
      </div>
    ),
    revoke_contributor: (
      <div style={styles.container}>
        <Card
          bordered={false}
          bodyStyle={styles.systemMessageContainer}
          onClick={handleClick}
        >
          <Row style={styles.systemMessage}>
            <Col sm={1} push={2} style={styles.systemMessageIcon}>
              <span
                className="system-emoji"
                style={styles.icon}
                role="img"
                aria-label="emoji"
              >
                📨
              </span>
              {message.icon}
            </Col>
            <Col sm={19} push={2} className="systemMessageText">
              {DisplayUtils.bodyTrimmer(message.body)}
            </Col>
          </Row>
        </Card>
      </div>
    ),
  };

  return types[message.type] || null;
};

const styles = {
  container: {
    backgroundColor: "white",
  },
  systemMessageContainer: {
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "#eeeeee",
    opacity: "0.65",
    color: "rgba(193, 193, 193, 0.85)",
    cursor: "pointer",
  },
  systemMessage: {
    display: "flex",
    alignItems: "center",
  },
  systemMessageIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  icon: {
    width: "50px",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
};

export default SystemMessage;
