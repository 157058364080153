import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WithConversation from '../data/WithConversation';
import ConversationContributors from '../component/ConversationContributors';
import Loading from '../component/Loading';

class ConversationContributorsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            conversationId: this.props.match.params.conversationId,
            loading: true,
            user: this.props.user,
        };
    }
    componentWillMount() {
        const { conversationListener } = this.props;
        const { conversationId } = this.state;

        conversationListener(conversationId, conversation => {
            this.setState({ conversation, loading: false });
        });
    }
    handleClick = user => {
        this.props.history.push(`/profile/${user.uid}`);
    };
    handleRequest = type => {
        const { updateConversation, user } = this.props;
        const { conversation } = this.state;

        type === 'host'
            ? updateConversation(conversation, `hostRequests/${user.uid}`, user)
            : updateConversation(conversation, `requests/${user.uid}`, user);
    };
    handleUpdateUserStatus = (user, type, status) => {
        console.log(user, type, status);
        const { updateConversation } = this.props;
        const { conversation } = this.state;

        return status === true
            ? updateConversation(conversation, `hosts/${user}/contribution`, 'trusted')
            : updateConversation(conversation, `hosts/${user}/contribution`, type);
    };
    handleUpdateStatus = (type, status) => {
        const { updateConversation } = this.props;
        const { conversation } = this.state;

        return status === false
            ? updateConversation(conversation, 'contribution', 'closed')
            : updateConversation(conversation, 'contribution', type);
    };
    handleAcceptRequest = (user, type) =>
        type === 'host'
            ? this.props.addHost(user, this.state.conversation.uid)
            : this.props.addContributor(user, this.state.conversation.uid);
    handleRevoke = (user, type) =>
        type === 'host'
            ? this.props.removeHost(user, this.state.conversation.uid)
            : this.props.removeContributor(user, this.state.conversation.uid);

    handleInvite = t =>
        t === 'contributors'
            ? this.props.history.push('invite')
            : this.props.history.push(`/public/conversations/${this.state.conversation.uid}/detail/hosts/invite`);

    render() {
        const { loading, conversation, user } = this.state;

        if (loading) {
            return <Loading />;
        }
        return (
            <ConversationContributors
                userIsCreator={conversation.creator.uid === user.uid}
                conversation={conversation}
                user={user}
                {...this}
            />
        );
    }
}

ConversationContributorsContainer.propTypes = {
    updateConversation: PropTypes.func.isRequired,
    conversationListener: PropTypes.func.isRequired,
    addHost: PropTypes.func.isRequired,
    addContributor: PropTypes.func.isRequired,
    removeContributor: PropTypes.func.isRequired,
    removeHost: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

export default WithConversation(ConversationContributorsContainer);
