import React from 'react';
import { Card, Switch, Row, Col, Icon } from 'antd';
import SystemMessage from './SystemMessage';

const ConversationCompose = ({
    isPrivate, handleToggle, branchedFrom, handleBranchClick,
}) => {
    const statusView = (emoji, title, text) => (
        <Row style={styles.privacyDetail}>
            <span role="img" aria-label="emoji" style={styles.icon}>
                {emoji}
            </span>
            <Col sm={22}>
                <h1 style={styles.privacyDetailHeading}>{title}</h1>
            </Col>
            <Col sm={14}>
                <h3 style={styles.privacyDetailText}>{text}</h3>
            </Col>
        </Row>
    );

    const message = { ...branchedFrom, type: 'branch' };

    const branchMessage = branchedFrom ? (
        <SystemMessage
            message={{ ...branchedFrom, type: 'branch' }}
            handleClick={() => handleBranchClick(message)}
        />
    ) : null;

    const info = isPrivate
        ? statusView(
            <Icon type="key" />,
            'This will show up among your Private conversations',
            'At first, only you will be able to see and contribute. You will be able to edit that by clicking the conversation title.',
        )
        : statusView(
            <Icon type="clock-circle-o" />,
            'This will show up among your Recent conversations',
            'At first, everyone you trust will be able to contribute. You will be able to edit that by clicking the conversation title.',
        );

    return (
        <div>
            {branchMessage}
            <Card
                bordered={false}
                hoverable={false}
                bodyStyle={styles.conversationContainer}
                title="Do you want everyone to see your conversation?"
                extra={
                    <Switch
                        checked={!isPrivate}
                        onChange={handleToggle}
                        style={{ width: '50px' }}
                    />
                }>
                {info}
            </Card>
        </div>
    );
};

const styles = {
    conversationContainer: {
        display: 'flex',
        minHeight: '500px',
        alignItems: 'center',
        justifyContent: 'center',
        fontDamily: 'Roboto-Medium',
    },
    optionsContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    inputContainer: {
        flex: 0,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 0,
        paddingRight: 20,
    },
    icon: {
        fontSize: '175px',
        color: '#aaa',
    },
    switchTitle: {
        fontSize: 18,
        fontWeight: '500',
        paddingBottom: 5,
    },
    switchSubtitle: {
        fontSize: 16,
        padding: '0px 20px',
    },

    privacyDetailHeading: {
        fontWeight: '500',
        fontSize: '32px',
        marginTop: '10px',
        fontStyle: 'italic',
        textAlign: 'center',
        color: '#aaa',
    },
    privacyDetail: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    privacyDetailText: {
        padding: '10px',
        textAlign: 'center',
        color: '#aaa',
        fontStyle: 'italic',
        fontWeight: '300',
    },
};

export default ConversationCompose;
