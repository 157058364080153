import React, { useReducer, useEffect } from "react";
import { reducer, changeColor, updateCharCount } from "./reducer";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { buildStyles } from "react-circular-progressbar";

const CharacterCounter = ({ charCount }) => {
  const [state, dispatch] = useReducer(reducer, "");

  useEffect(
    () => {
      dispatch(updateCharCount({ charCount: charCount }));

      if (charCount <= 200 && charCount >= 160) {
        dispatch(changeColor({ color: "orange" }));
      }

      if (charCount > 200) {
        dispatch(changeColor({ color: "red" }));
      }
    },

    [charCount]
  );

  return state.isShowing ? (
    <div
      style={{
        width: 20,
        height: 20,

        zIndex: 3,
        position: "static"
      }}
    >
      <CircularProgressbarWithChildren
        value={charCount}
        maxValue={200}
        styles={buildStyles({
          pathColor: state.color,

          widht: 50,
          height: 50
        })}
      >
        <div
          style={{
            alignSelf: "flex-end",
            width: 20,
            height: 20
          }}
        >
          <span
            style={{
              color: "grey",
              position: "absolute",
              left: "26px",
              fontSize: "10px",
              width: 30
            }}
          >
            {state.showCount ? state.charCount : null}
          </span>
        </div>
      </CircularProgressbarWithChildren>
    </div>
  ) : (
    <div
      style={{
        width: 25,
        height: 25,
        position: "relative",
        bottom: "6px",
        zIndex: 3
      }}
    />
  );
};

export default CharacterCounter;
