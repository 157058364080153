import autodux from "autodux";
const initialState = {
  user: { isAnonymous: true, uid: 1 },
  isInitialized: false,
};

export const {
  reducer,
  initial,
  slice,
  actions: { setUser, setAnonUser, updateUserProfile, initializeUserProfile },
  selectors: { getUser, getDisplayName, getIsAnonymous },
} = autodux({
  slice: "userProfile",
  initial: initialState,
  actions: {
    setAnonUser: (state) => ({
      ...state,
      user: {
        uid: 1,
        isAnonymous: true,
      },
    }),
    updateUserProfile: (state, payload) => ({
      ...state,
      user: { ...state.user, ...payload },
    }),
    setUser: (state, user) => ({
      ...state,
      user: {
        ...state.user,
        ...user,
        isAnonymous: false,
      },
    }),
    initializeUserProfile: (state, payload) => ({
      ...state,
      isInitialized: true,
    }),
  },

  selectors: {
    getUser: ({ user }) => user,
    getIsAnonymous: ({ user }) => user.isAnonymous,
    getDisplayName: ({ user }) =>
      user.isAnonymous === false ? user.displayName : "",
  },
});
