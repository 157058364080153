import firebase from "firebase/app";

export const startUploadProfileImage = (userId, originFileObj) => {
  const blob = new Blob([originFileObj], { type: originFileObj.type });

  return new Promise((resolve, reject) => {
    const storageRef = firebase.storage().ref();
    const uploadTask = storageRef
      .child(
        `user_uploads/images/${userId}/profile_image/${originFileObj.name} `
      )
      .put(blob);

    uploadTask.on(
      "state_changed",
      function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            // console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            // console.log("Upload is running");
            break;
          default:
        }
      },
      function (error) {
        reject(error);
        // Handle unsuccessful uploads
      },
      function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          resolve(downloadURL);
        });
      }
    );
  });
};

export const getRandomColor = () => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
  return randomColor;
};

export const getRandomAvatarImage = (color, displayName) => {
  return `https://ui-avatars.com/api/?background=${color}&color=fff&name=${displayName}`;
};
