import React from 'react';
import { Switch, Upload, Button, Card } from 'antd';
import ImageMessage from '../component/ImageMessage';

const FeaturedImages = ({
    featuredImage, messages, handleToggle, uploadProps,
}) => {
    const toggle = (m, v) => (v ? handleToggle(m.imageURL) : handleToggle(null));
    const uploadedImage = !messages.map(m => m.imageURL).find(i => i === featuredImage);
    const messageImages = messages.map(m => (
        <ImageMessage
            action={<Switch onChange={v => toggle(m, v)} checked={featuredImage === m.imageURL} />}
            message={m}
            showMetadata
        />
    ));

    const images = (
        <div>
            <Card
                bordered={false}
                title="Custom image"
                bodyStyle={{ padding: '0px' }}
                extra={
                    <Upload {...uploadProps}>
                        <Button type="primary" style={styles.uploadButton}>
                            Upload custom image
                        </Button>
                    </Upload>
                }>
                {uploadedImage ? (
                    <img alt="message" src={featuredImage} style={styles.imageMessage} />
                ) : null}
            </Card>
            {messageImages}
        </div>
    );

    return images;
};

const styles = {
    uploadRow: {
        margin: '30px 0px',
    },
    uploadButton: {
        width: '100%',
    },
    imageMessage: {
        width: '100%',
    },
    icon: {
        fontSize: 24,
        margin: 5,
        color: '#BCBCBC',
    },
};

export default FeaturedImages;
