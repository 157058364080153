export const setMetaData = ({
  message = {},
  previousMessage = {},
  isMessageDetail = false,
  isInput = false,
} = {}) => ({
  type: 'setMetaData',
  payload: {
    isImageMessage: message.imageURL,
    isMoreThan10:
      message.timestamp - previousMessage.timestamp > 1000 * 60 * 10,
    previousIsSystem: previousMessage.user === 'system',
    isMessageDetail,
    isNotPreviousUser: previousMessage.user
      ? message.user.uid !== previousMessage.user.uid
      : false,
    isInput,
  },
});

export const updateMetadata = () => ({
  type: 'updateMetadata',
});

export const reducer = (state, { type, payload } = {}) => {
  switch (type) {
    case setMetaData().type:
      return {
        ...state,
        shouldDisplay:
          payload.isMoreThan10 ||
          payload.isInput ||
          payload.isMessageDetail ||
          payload.previousIsSystem ||
          payload.isNotPreviousUser ||
          payload.isImageMessage,
      };

    default:
      return state;
  }
};
