import React from "react";
import { Row, Col } from "antd";
import Utils from "../utils/DisplayUtils";

const ActivityItem = ({ handlePress, activity }) => {
  if (activity.type === "like") {
    const title =
      activity.message.type === "image_message"
        ? "your image message"
        : `${Utils.titleTrimmer(activity.message.body)}`;

    return (
      <Row
        onClick={() => handlePress("MessageDetail", activity)}
        style={styles.itemContainer}
      >
        <div style={styles.itemDetailsContainer}>
          <Col xs={4} sm={4} style={{ textAlign: "center" }}>
            <span role="img" aria-label="emoji" style={styles.itemIcon}>
              🔦
            </span>
          </Col>
          <Col xs={18} sm={20} style={styles.itemDetails}>
            <p style={styles.itemp}>
              {activity.user.displayName}
              {activity.others
                ? ` and ${activity.others.length} other${
                    activity.others.length > 1 && "s"
                  }`
                : null}{" "}
              highlighted
              <span style={styles.itemTitle}>
                {" "}
                {title}
                <span style={styles.itemTimestamp}>
                  {" "}
                  {Utils.timeStamp(activity.timestamp)}
                </span>
              </span>
            </p>
          </Col>
        </div>
      </Row>
    );
  }

  if (activity.type === "branch") {
    return (
      <Row
        onClick={() => handlePress("MessageDetail", activity)}
        style={styles.itemContainer}
      >
        <div style={styles.itemDetailsContainer}>
          <Col xs={4} sm={4} style={{ textAlign: "center" }}>
            <span role="img" aria-label="emoji" style={styles.itemIcon}>
              🌿
            </span>
          </Col>
          <Col xs={18} sm={20} style={styles.itemDetails}>
            <p style={styles.itemp}>
              {activity.user.displayName}{" "}
              {activity.others
                ? `and ${activity.others.length} other${
                    activity.others.length > 1 && "s"
                  }`
                : null}{" "}
              branched your message
              <span style={styles.itemTitle}>
                {" "}
                {Utils.titleTrimmer(activity.message.body)}
                <span style={styles.itemTimestamp}>
                  {" "}
                  {Utils.timeStamp(activity.timestamp)}
                </span>
              </span>
            </p>
          </Col>
        </div>
      </Row>
    );
  }

  if (activity.type === "new_message") {
    const uniqueUsers = activity.others
      ? activity.others.reduce((userNames, activity) => {
          userNames[activity.user.displayName] = activity;
          return userNames;
        }, {})
      : { [activity.user.displayName]: activity };

    const uniqueCount = Object.values(uniqueUsers || {});

    return (
      <Row
        onClick={() => handlePress("Conversation", activity)}
        style={styles.itemContainer}
      >
        <div style={styles.itemDetailsContainer}>
          <Col xs={4} sm={4} style={{ textAlign: "center" }}>
            <span role="img" aria-label="emoji" style={styles.itemIcon}>
              💬
            </span>
          </Col>
          <Col xs={18} sm={20} style={styles.itemDetails}>
            <p style={styles.itemp}>
              {activity.user.displayName}
              {uniqueCount.length > 1
                ? ` and ${uniqueCount.length - 1} other${
                    uniqueCount.length > 2 ? "s" : ""
                  }`
                : null}{" "}
              sent a message to
              <span style={styles.conversationTitle}>
                {" "}
                {Utils.titleTrimmer(activity.conversation.title)}
                <span style={styles.itemTimestamp}>
                  {" "}
                  {Utils.timeStamp(activity.timestamp)}
                </span>
              </span>
            </p>
          </Col>
        </div>
      </Row>
    );
  }

  if (activity.type === "new_image_message") {
    const uniqueUsers = activity.others
      ? activity.others.reduce((userNames, activity) => {
          userNames[activity.user.displayName] = activity;
          return userNames;
        }, {})
      : { [activity.user.displayName]: activity };

    const uniqueCount = Object.values(uniqueUsers || {});

    return (
      <Row
        onClick={() => handlePress("Conversation", activity)}
        style={styles.itemContainer}
      >
        <div style={styles.itemDetailsContainer}>
          <Col xs={4} sm={4} style={{ textAlign: "center" }}>
            <span role="img" aria-label="emoji" style={styles.itemIcon}>
              📸
            </span>
          </Col>
          <Col xs={18} sm={20} style={styles.itemDetails}>
            <p style={styles.itemp}>
              {activity.user.displayName}
              {uniqueCount.length > 1
                ? ` and ${uniqueCount.length - 1} other${
                    uniqueCount.length > 2 ? "s" : ""
                  }`
                : null}{" "}
              sent an image to
              <span style={styles.conversationTitle}>
                {" "}
                {Utils.titleTrimmer(activity.conversation.title)}
                <span style={styles.itemTimestamp}>
                  {" "}
                  {Utils.timeStamp(activity.timestamp)}
                </span>
              </span>
            </p>
          </Col>
        </div>
      </Row>
    );
  }

  if (activity.type === "new_contributor") {
    return (
      <Row
        onClick={() => handlePress("Conversation", activity)}
        style={styles.itemContainer}
      >
        <div style={styles.itemDetailsContainer}>
          <Col xs={4} sm={4} style={{ textAlign: "center" }}>
            <span role="img" aria-label="emoji" style={styles.itemIcon}>
              📨
            </span>
          </Col>
          <Col xs={18} sm={20} style={styles.itemDetails}>
            <p style={styles.itemp}>
              {activity.conversation.creator.displayName} invited you to
              contribute to
              <span style={styles.conversationTitle}>
                {" "}
                {Utils.titleTrimmer(activity.conversation.title)}
                <span style={styles.itemTimestamp}>
                  {" "}
                  {Utils.timeStamp(activity.timestamp)}
                </span>
              </span>
            </p>
          </Col>
        </div>
      </Row>
    );
  }

  if (activity.type === "new_host") {
    return (
      <Row
        onClick={() => handlePress("Conversation", activity)}
        style={styles.itemContainer}
      >
        <div style={styles.itemDetailsContainer}>
          <Col xs={4} sm={4} style={{ textAlign: "center" }}>
            <span role="img" aria-label="emoji" style={styles.itemIcon}>
              📨
            </span>
          </Col>
          <Col xs={18} sm={20} style={styles.itemDetails}>
            <p style={styles.itemp}>
              {activity.conversation.creator.displayName} added you as a cohost
              of
              <span style={styles.conversationTitle}>
                {" "}
                {Utils.titleTrimmer(activity.conversation.title)}
                <span style={styles.itemTimestamp}>
                  {" "}
                  {Utils.timeStamp(activity.timestamp)}
                </span>
              </span>
            </p>
          </Col>
        </div>
      </Row>
    );
  }

  if (activity.type === "new_follower") {
    return (
      <Row
        onClick={() => handlePress("UserProfile", activity)}
        style={styles.itemContainer}
      >
        <div style={styles.itemDetailsContainer}>
          <Col xs={4} sm={4} style={{ textAlign: "center" }}>
            <span role="img" aria-label="emoji" style={styles.itemIcon}>
              🔔
            </span>
          </Col>
          <Col xs={18} sm={20} style={styles.itemDetails}>
            <p style={styles.itemp}>
              {activity.user.displayName} started following:
              <span style={styles.conversationTitle}>
                {" "}
                {Utils.titleTrimmer(activity.conversation.title)}
                <span style={styles.itemTimestamp}>
                  {" "}
                  {Utils.timeStamp(activity.timestamp)}
                </span>
              </span>
            </p>
          </Col>
        </div>
      </Row>
    );
  }

  if (activity.type === "new_trustee") {
    return (
      <Row
        onClick={() => handlePress("UserProfile", activity)}
        style={styles.itemContainer}
      >
        <div style={styles.itemDetailsContainer}>
          <Col xs={4} sm={4} style={{ textAlign: "center" }}>
            <span role="img" aria-label="emoji" style={styles.itemIcon}>
              🦉
            </span>
          </Col>
          <Col xs={18} sm={20} style={styles.itemDetails}>
            <p style={styles.itemp}>
              {activity.user.displayName} trusted you
              <span style={styles.itemTimestamp}>
                {" "}
                {Utils.timeStamp(activity.timestamp)}
              </span>
            </p>
          </Col>
        </div>
      </Row>
    );
  }

  if (activity.type === "new_request") {
    if (!activity.user) return null;
    return (
      <Row
        onClick={() => handlePress("ConversationDetail", activity)}
        style={styles.itemContainer}
      >
        <div style={styles.itemDetailsContainer}>
          <Col xs={4} sm={4} style={{ textAlign: "center" }}>
            <span role="img" aria-label="emoji" style={styles.itemIcon}>
              📨
            </span>
          </Col>
          <Col xs={18} sm={20} style={styles.itemDetails}>
            <p style={styles.itemp}>
              {activity.user.displayName} requested a personal invitation to
              contribute to
              <span style={styles.conversationTitle}>
                {" "}
                {Utils.titleTrimmer(activity.conversation.title)}
                <span style={styles.itemTimestamp}>
                  {" "}
                  {Utils.timeStamp(activity.timestamp)}
                </span>
              </span>
            </p>
          </Col>
        </div>
      </Row>
    );
  }

  if (activity.type === "new_conversation") {
    if (!activity.user) return null;
    return (
      <Row
        onClick={() => handlePress("Conversation", activity)}
        style={styles.itemContainer}
      >
        <div style={styles.itemDetailsContainer}>
          <Col xs={4} sm={4} style={{ textAlign: "center" }}>
            <span role="img" aria-label="emoji" style={styles.itemIcon}>
              🌱
            </span>
          </Col>
          <Col xs={18} sm={20} style={styles.itemDetails}>
            <p style={styles.itemp}>
              {activity.user.displayName} started a new public conversation
              <span style={styles.conversationTitle}>
                {" "}
                {Utils.titleTrimmer(activity.conversation.title)}
                <span style={styles.itemTimestamp}>
                  {" "}
                  {Utils.timeStamp(activity.timestamp)}
                </span>
              </span>
            </p>
          </Col>
        </div>
      </Row>
    );
  }

  if (activity.type === "new_tag") {
    if (!activity.user) return null;
    return (
      <Row
        onClick={() => handlePress("Conversation", activity)}
        style={styles.itemContainer}
      >
        <div style={styles.itemDetailsContainer}>
          <Col xs={4} sm={4} style={{ textAlign: "center" }}>
            <span role="img" aria-label="emoji" style={styles.itemIcon}>
              🏷
            </span>
          </Col>
          <Col xs={18} sm={20} style={styles.itemDetails}>
            <p style={styles.itemp}>
              {activity.user.displayName} tagged you in
              <span style={styles.itemTitle}>
                {" "}
                {Utils.titleTrimmer(activity.message.body)}
                <span style={styles.itemTimestamp}>
                  {" "}
                  {Utils.timeStamp(activity.timestamp)}
                </span>
              </span>
            </p>
          </Col>
        </div>
      </Row>
    );
  }

  return null;
};

export default ActivityItem;

const styles = {
  itemContainer: {
    cursor: "pointer",
    flex: 1,
    backgroundColor: "#fff",
    paddingTop: "5px",
    paddingBottom: "5px",
    marginLeft: "5px",
    marginRight: "5px",
    borderBottomWidth: "1px",
    borderBottomColor: "#EEE",
    borderBottomStyle: "solid",
  },
  itemDetails: {
    flex: 1,
    flexDirection: "row",
  },
  itemDetailsContainer: {
    flex: 1,
    flexDirection: "row",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
  },
  itemp: {
    flex: 1,
    color: "#000",
    fontSize: "12px",
    lineHeight: "16px",
    margin: "0px",
  },
  itemTitle: {
    fontWeight: "300",
  },
  itemIcon: {
    alignSelf: "flex-start",
    fontSize: "22px",
    paddingRight: "10px",
  },
  conversationTitle: {
    fontFamily: "AlfaSlabOne-Regular",
    color: "rgb(117, 117, 117)",
  },
  itemTimestamp: {
    fontFamily: "Roboto",
    fontWeight: 300,
    fontSize: "10px",
    marginRight: "15px",
    color: "#9b9b9b",
    justifyContent: "center",
  },
};
